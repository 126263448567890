/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorOccured from '../../components/ErrorOccured';
import Loading from '../../components/Loading';
import NoDataFound from '../../components/NoData';
import Table from '../../components/Table';
import { useGetFilters } from '../../hooks';
import { useGetTableHeaderMetajsonQuery } from '../../redux/api/slices/dashboardApiSlice';
import {
	API_DATE_FORMAT,
	exportToExcel,
	prepareExportData,
	reportsObject,
} from '../../util';
import { getFormattedDate } from '../../util/dateUtil';
import { useGetNutrientActivityReportQuery } from '../../redux/api/slices/dashboardApiSlice';

const NutrientActivityReport = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const filters = useGetFilters();
	const [pageSize, setPageSize] = useState(20);
	const navigation = useNavigate();
	const [columns, setColumns] = useState();
	const [selectedFarmer, setSelectedFarmer] = useState();

	const {
		data: nutrientDetailsHeaderData,
		isHeaderLoading,
		isHeaderFetching,
		isHeaderError,
		headerError,
	} = useGetTableHeaderMetajsonQuery(
		selectedCrop?.length && selectedCrop[0] !== ''
			? {
					cropCompanyId: selectedCrop[0],
					tablename: reportsObject.NUTRIENT_ACTIVITY,
					tableHeaders: '',
			  }
			: skipToken
	);
	const {
		data: nutrientActivityReportData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetNutrientActivityReportQuery(
		selectedCrop?.length &&
			ranges?.length &&
			ranges[0]?.startDate &&
			ranges[0]?.endDate
			? {
					farmerCode: selectedFarmer?.id,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety ? selectedCropVariety : [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.startDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
					toDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.endDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setSelectedFarmer(filters.farmer);
		}
	}, [filters]);
	useEffect(() => {
		if (nutrientDetailsHeaderData) {
			const cols = [];
			nutrientDetailsHeaderData?.tableHeader?.forEach((item) => {
				cols.push({
					Header: item.header,
					accessor: item.accessor,
					width: item.width || 200,
				});
			});

			setColumns(cols);
		}
	}, [nutrientDetailsHeaderData]);
	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: nutrientActivityReportData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		const sheetData = exportData.map((chemcData, index) => {
			const cols = {};

			nutrientDetailsHeaderData?.tableHeader?.forEach((item) => {
				const { header, accessor } = item;
				cols[header] = chemcData[accessor] || '';
			});

			return {
				'Sl. No': index + 1,
				...cols,
			};
		});
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `NutrientActivityReport.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{/* {diseaseName ? ( */}
			{/* <button
				type="button"
				onClick={() => {
					navigation(`/cId/${cId}/dashboard`);
				}}
				className="flex items-center w-9 h-9 justify-center bg-white p-2 text-white rounded-lg border border-gray-400 ml-1"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="#4EAA6F"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</button> */}
			{/* ) : null} */}
			{nutrientActivityReportData?.length ? (
				<Table
					data={nutrientActivityReportData}
					columns={columns}
					className="overflow-x-hidden w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-1.5 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title={`Nutrient Activity Report`}
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion
					defaultPageSize={pageSize}
				/>
			) : (
				<>
					<h2>Nutrient Activity Report</h2>
					<NoDataFound />
				</>
			)}
		</div>
	);
};

export default NutrientActivityReport;
