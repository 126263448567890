/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import NoDataFound from '../NoData';

const MapSmallPopup = ({ isActive = false, header = '', data }) => {
	if (isActive) {
		return (
			<div className="w-96 fixed bottom-0 right-0 z-10 bg-white p-2 scroll-smooth overflow-y-auto">
				<section className="flex justify-between items-center">
					<label className="mb-1.5 text-base font-semibold text-black">
						{header}
					</label>
				</section>
				<section className="flex flex-wrap gap-1 justify-between">
					<div className="flex w-44 gap-1 flex-col rounded-md border border-borderColor px-2 py-1.5">
						<label className="text-sm font-bold text-black">{`Crops: `}</label>
						<label className="flex text-sm font-normal text-black">
							{data?.crops?.join(', ') || '-'}
						</label>
					</div>
					<div className="flex w-44 gap-1 flex-col rounded-md border border-borderColor px-2 py-1.5">
						<label className="text-sm font-bold text-black">{`Crop Varieties: `}</label>
						<label className="flex flex-row flex-wrap break-all text-sm font-normal text-black">
							{data?.varieties?.join(', ') || '-'}
						</label>
					</div>
					<div className="flex w-44 gap-1 flex-col rounded-md border border-borderColor px-2 py-1.5">
						<label className="text-sm font-bold text-black">{`Number of farmers: `}</label>
						<label className="flex text-sm font-normal text-black">
							{data?.farmerCount || 0}
						</label>
					</div>
					<div className="flex w-44 gap-1 flex-col rounded-md border border-borderColor px-2 py-1.5">
						<label className="text-sm font-bold text-black">
							{`No of Acres: `}
						</label>
						<label className="flex text-sm font-normal text-black">
							{data?.totalFarmArea || 0}
						</label>
					</div>
				</section>
			</div>
		);
	}

	return (
		<div className="w-96 fixed bottom-0 right-0 z-10 bg-white p-2">
			<section className="flex justify-between items-center">
				<label className="mb-1.5 text-base font-semibold text-black">
					{header}
				</label>
			</section>
			<NoDataFound isFullScreen={false} />
		</div>
	);
};

export default MapSmallPopup;
