import React from 'react';

const ChevronUpIcon = (props) => {
	const { height = 24, width = 24, fill = '#000000', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			height={height}
			width={width}
			fill={fill}
			className={className}
		>
			<path d="M22,16.5c0,0.5-0.2,0.8-0.7,1c-0.4,0.2-0.9,0.1-1.2-0.3c-1.6-1.6-3.1-3.1-4.7-4.7c-1.1-1.1-2.2-2.2-3.2-3.2 c-0.2-0.2-0.2-0.2-0.5,0c-2.6,2.6-5.2,5.2-7.8,7.9c-0.3,0.3-0.6,0.5-1,0.4c-0.8-0.2-1.2-1.2-0.6-1.8c0.2-0.2,0.4-0.4,0.6-0.6 c2.7-2.8,5.5-5.5,8.2-8.3c0.6-0.6,1.2-0.6,1.8,0c1.9,1.9,3.8,3.8,5.7,5.8c1,1,2,2,3,3C21.9,15.9,22,16.1,22,16.5z" />
		</svg>
	);
};
export default ChevronUpIcon;
