import React from 'react';

const ExecutivesIcon = (props) => {
	const { height = 20, width = 20, fill = '#e0e1e8', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${height} ${width}`}
			className={className}
		>
			<defs>
				<clipPath>
					<rect width={20} height={20} />
				</clipPath>
			</defs>
			<g>
				<rect width={20} height={20} fill="none" />
				<path
					d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM9.994,9.019,8.5,15l-1-4.25L8.5,9h-3l1,1.75L5.5,15,4.006,9.019A4.193,4.193,0,0,0,0,13.2v1.3A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V13.2A4.193,4.193,0,0,0,9.994,9.019Z"
					transform="translate(3 2)"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default ExecutivesIcon;
