/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorOccured from '../../components/ErrorOccured';
import Loading from '../../components/Loading';
import NoDataFound from '../../components/NoData';
import Table from '../../components/Table';
import { useGetFilters } from '../../hooks';
import { useGetDiseaseReportDataQuery } from '../../redux/api/slices/dashboardApiSlice';
import { API_DATE_FORMAT, exportToExcel, prepareExportData } from '../../util';
import { getCurrentFormattedDate, getFormattedDate } from '../../util/dateUtil';
import { decodeChemical } from '../../components/DashboardCharts/util';
import { useGetPostHarvestActivityReportQuery } from '../../redux/api/slices/dashboardApiSlice';

const PostHarvestActivityReport = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const filters = useGetFilters();
	const [pageSize, setPageSize] = useState(20);
	const navigation = useNavigate();
	const [selectedFarmer, setSelectedFarmer] = useState();

	const {
		data: PostHarvestActivityReportData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetPostHarvestActivityReportQuery(
		selectedCrop?.length &&
			ranges?.length &&
			ranges[0]?.startDate &&
			ranges[0]?.endDate
			? {
					farmerCode: selectedFarmer?.id,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety ? selectedCropVariety : [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.startDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
					toDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.endDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setSelectedFarmer(filters.farmer);
		}
	}, [filters]);

	const columns = useMemo(
		() => [
			{
				Header: 'Company Name',
				accessor: 'companyName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.companyName || ''}`}</p>
				),
			},
			{
				Header: 'Farmer Code',
				accessor: 'farmercode',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.farmercode || ''}`}</p>
				),
			},
			{
				Header: 'Farmer Name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.firstName || ''}`}</p>
				),
			},
			{
				Header: 'Executive Name',
				accessor: 'executiveName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.executiveName || ''}`}</p>
				),
			},
			{
				Header: 'Crop Name',
				accessor: 'cropName',
				Cell: ({ row }) => <p>{row?.original?.cropName}</p>,
			},
			{
				Header: 'Crop Variety',
				accessor: 'varietiName',
				Cell: ({ row }) => <p>{row?.original?.varietiName}</p>,
			},
			{
				Header: 'District Name',
				accessor: 'district',
				Cell: ({ row }) => <p>{row?.original?.district}</p>,
			},
			{
				Header: 'Village Name',
				accessor: 'village',
				Cell: ({ row }) => <p>{row?.original?.village}</p>,
			},
			{
				Header: 'Harvesting',
				accessor: 'harvesting',
				Cell: ({ row }) => (
					<p>
						{getFormattedDate({
							date: addDays(new Date(row?.original?.harvesting), 1),
							reqFormat: API_DATE_FORMAT,
						})}
					</p>
				),
			},
			{
				Header: 'Methodofdryingforharvest-1',
				accessor: 'methodofdryingforharvest1',
				Cell: ({ row }) => (
					<p>{row?.original?.methodofdryingforharvest1 || ''}</p>
				),
				width: 200,
			},
			{
				Header: 'Deluxe Grade Qty in Kgs',
				accessor: 'deluxeGradeQtyinKgs',
				Cell: ({ row }) => <p>{row?.original?.deluxeGradeQtyinKgs || ''}</p>,
				width: 200,
			},
			{
				Header: 'A gradequantity in kgs',
				accessor: 'agradequantityinkgs',
				Cell: ({ row }) => <p>{row?.original?.agradequantityinkgs || ''}</p>,
				width: 200,
			},
			{
				Header: 'B gradequantity in kgs',
				accessor: 'bgradequantityinkgs',
				Cell: ({ row }) => <p>{row?.original?.bgradequantityinkgs || ''}</p>,
				width: 200,
			},
			{
				Header: 'C GradeQty in Kg',
				accessor: 'cGradeQtyinKg',
				Cell: ({ row }) => <p>{row?.original?.cGradeQtyinKg || ''}</p>,
				width: 200,
			},
			{
				Header: 'D Grade Qty In Kg',
				accessor: 'dGradeQtyInKg',
				Cell: ({ row }) => <p>{row?.original?.dGradeQtyInKg || ''}</p>,
				width: 200,
			},
			{
				Header: 'White/Talugradequantity in kgs',
				accessor: 'whiteOrTalugradequantityinkgs',
				Cell: ({ row }) => (
					<p>{row?.original?.whiteOrTalugradequantityinkgs || ''}</p>
				),
				width: 200,
			},
		],
		[]
	);

	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: PostHarvestActivityReportData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		console.log('exportData => ', exportData);
		const sheetData = exportData.map((chemcData, index) => ({
			'Sl. No': index + 1,
			'Company Name': chemcData?.companyName || '',
			'Farmer Code': chemcData?.farmercode || '',
			'Farmer Name': chemcData?.firstName || '',
			'Executive Name': chemcData?.executiveName || '',
			'Crop Name': chemcData?.cropName || '',
			'Crop Variety': chemcData?.varietiName || '',
			'District Name': chemcData?.district || '',
			Village: chemcData?.village,
			Harvesting: `${chemcData?.harvesting || ''}`,
			'Methodofdryingforharvest-1': chemcData?.methodofdryingforharvest1 || '',
			Durationofdryingindays: chemcData?.durationofdryingindays || '',
			'Deluxe GradeQtyinKgs': chemcData?.deluxeGradeQtyinKgs || '',
			'A gradequantityinkgs': chemcData?.agradequantityinkgs || '',
			'B gradequantityinkgs': chemcData?.bgradequantityinkgs || '',
			'C gradequantityinkgs': chemcData?.cGradeQtyinKg || '',
			'D gradequantityinkgs': chemcData?.dGradeQtyInKg || '',
			'White/Talugradequantityinkgs':
				chemcData?.whiteOrTalugradequantityinkgs || '',
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `PostHarvestActivityReport.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{/* {diseaseName ? ( */}
			{/* <button
				type="button"
				onClick={() => {
					navigation(`/cId/${cId}/dashboard`);
				}}
				className="flex items-center w-9 h-9 justify-center bg-white p-2 text-white rounded-lg border border-gray-400 ml-1"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="#4EAA6F"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</button> */}
			{/* ) : null} */}
			{PostHarvestActivityReportData?.length ? (
				<Table
					data={PostHarvestActivityReportData}
					columns={columns}
					className="overflow-x-hidden w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-1.5 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title={`Post Harvest Activity Report`}
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion
					defaultPageSize={pageSize}
				/>
			) : (
				<>
					<h2>Post Harvest Activity Report</h2>
					<NoDataFound />
				</>
			)}
		</div>
	);
};

export default PostHarvestActivityReport;
