import React from 'react';
import { CloseIcon } from '../icons';

const ErrorOccured = ({
	source,
	msg,
	fullScreenMode = false,
	className = '',
}) => {
	console.info({ source, msg, fullScreenMode });
	return (
		<div
			className={`${
				fullScreenMode
					? 'w-full h-screen flex flex-col justify-center items-center'
					: ''
			} ${className}`}
		>
			<CloseIcon className="h-12 w-12" fill="#FE0000" color="#FE0000" />
			<h3 className="text-lg font-medium">Oops !! Something went wrong...</h3>

			<p className="text-base font-medium">
				Error Message:
				{msg.data || msg.error}
				{msg.originalStatus && <span>(#{msg.originalStatus})</span>}
			</p>
		</div>
	);
};

export default ErrorOccured;
