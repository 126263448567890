/* eslint-disable import/no-relative-packages */
import React, { useState, useEffect } from 'react';
import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
	ZoomableGroup,
} from 'react-simple-maps';
import * as turf from '@turf/turf';
import {
	fetchIndiaMap,
	removeDuplicates,
	statesBlobJsonMap,
} from '../../util/mapUtils';
import MapSmallPopup from './MapSmallPopup';
import NoDataFound from '../NoData';
import Loading from '../Loading';
import { useGetSettingsFromState } from '../../redux/api/hooks/dashboardHooks';

const StateMap = ({
	stateName,
	setCoordinates,
	setIsDistrict,
	stateId = '',
	districtDetails,
	setDistrictDetails,
	totalData = {},
}) => {
	const zoomMap = 1;
	let centerMap = [80, 22];
	let scaleMap = 400;
	if (stateName === 'Andaman & Nicobar Island') {
		scaleMap = 1000;
		centerMap = [93, 10];
	} else if (stateName === 'Andhra Pradesh') {
		scaleMap = 800;
		centerMap = [80, 17];
	} else if (stateName === 'Arunachal Pradesh') {
		scaleMap = 1200;
		centerMap = [94.5, 28];
	} else if (stateName === 'Assam') {
		scaleMap = 1350;
		centerMap = [92.9, 26];
	} else if (stateName === 'Bihar') {
		scaleMap = 1300;
		centerMap = [85.5, 26];
	} else if (stateName === 'Chhattisgarh') {
		scaleMap = 1100;
		centerMap = [82, 21];
	} else if (stateName === 'NCT of Delhi') {
		scaleMap = 11000;
		centerMap = [77.05, 28.6];
	} else if (stateName === 'Goa') {
		scaleMap = 6000;
		centerMap = [74, 15.25];
	} else if (stateName === 'Gujarat') {
		scaleMap = 1000;
		centerMap = [71.5, 22];
	} else if (stateName === 'Haryana') {
		scaleMap = 1700;
		centerMap = [76, 29];
	} else if (stateName === 'Himachal Pradesh') {
		scaleMap = 2000;
		centerMap = [77.4, 31.8];
	} else if (stateName === 'Jammu & Kashmir') {
		scaleMap = 1000;
		centerMap = [76.3, 35];
	} else if (stateName === 'Jharkhand') {
		scaleMap = 1700;
		centerMap = [85.7, 23.6];
	} else if (stateName === 'Karnataka') {
		scaleMap = 1100;
		centerMap = [76.5, 15];
	} else if (stateName === 'Kerala') {
		scaleMap = 1800;
		centerMap = [76, 10.5];
	} else if (stateName === 'Lakshadweep') {
		scaleMap = 2300;
		centerMap = [73, 11];
	} else if (stateName === 'Madhya Pradesh') {
		scaleMap = 900;
		centerMap = [78.5, 24];
	} else if (stateName === 'Maharashtra') {
		scaleMap = 1000;
		centerMap = [76.8, 19.3];
	} else if (stateName === 'Manipur') {
		scaleMap = 3400;
		centerMap = [93.8, 24.7];
	} else if (stateName === 'Meghalaya') {
		scaleMap = 2500;
		centerMap = [91.3, 25.4];
	} else if (stateName === 'Mizoram') {
		scaleMap = 2900;
		centerMap = [92.8, 23.25];
	} else if (stateName === 'Nagaland') {
		scaleMap = 4000;
		centerMap = [94.3, 26.1];
	} else if (stateName === 'Odisha') {
		scaleMap = 1300;
		centerMap = [84.4, 20.25];
	} else if (stateName === 'Punjab') {
		scaleMap = 2300;
		centerMap = [75.35, 31.1];
	} else if (stateName === 'Rajasthan') {
		scaleMap = 900;
		centerMap = [74, 26.3];
	} else if (stateName === 'Sikkim') {
		scaleMap = 6000;
		centerMap = [88.45, 27.6];
	} else if (stateName === 'Tamil Nadu') {
		scaleMap = 1300;
		centerMap = [78.25, 10.8];
	} else if (stateName === 'Telangana') {
		scaleMap = 1800;
		centerMap = [79.5, 17.9];
	} else if (stateName === 'Tripura') {
		scaleMap = 4500;
		centerMap = [91.75, 23.75];
	} else if (stateName === 'Uttarakhand') {
		scaleMap = 2000;
		centerMap = [79.3, 30];
	} else if (stateName === 'Uttar Pradesh') {
		scaleMap = 1000;
		centerMap = [80.8, 27];
	} else if (stateName === 'West Bengal') {
		scaleMap = 1200;
		centerMap = [87.7, 24.2];
	}

	const [markers, setMarkers] = useState([]);
	const [tooltipContent, setTooltipContent] = useState(false);
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const [state, setState] = useState({
		farmerCount: 0,
		totalFarmArea: 0,
		crops: [],
		varieties: [],
	});

	const dashboardSettings = useGetSettingsFromState();
	const token = dashboardSettings?.SASToken || '';

	useEffect(() => {
		if (data?.features?.length && turf.polygon && turf.centroid) {
			const values = data.features.map((item) => {
				let centroids = [0, 0];
				try {
					const polygons =
						item.geometry.type === 'MultiPolygon'
							? turf.polygon(item.geometry.coordinates.flat())
							: turf.polygon(item.geometry.coordinates);
					centroids = turf.centroid(polygons);
					// eslint-disable-next-line no-empty
				} catch (error) {}
				return {
					markerOffset: 3,
					name: item.properties.districtName,
					coordinates: centroids.geometry.coordinates,
				};
			});

			const removeDummy = removeDuplicates(values, 'name');
			setMarkers(removeDummy);
		}
	}, [data]);

	useEffect(() => {
		setIsLoading(true);
		if (stateId && token) {
			const stateNameFromId = statesBlobJsonMap[stateId];
			fetchIndiaMap(`${stateNameFromId}.json`, setData, setIsLoading, token);
		}
	}, [stateId, token]);

	const onDistrictClickHandler = async (geo) => {
		const {
			districtName = '',
			districtId,
			stateId: stateUUId,
		} = geo.properties;
		setTooltipContent(false);
		setDistrictDetails({
			...districtDetails,
			id: districtId,
			isActive: false,
			districtName,
			stateId: stateUUId,
		});
		setIsDistrict(true);
	};

	const getDistrictData = (districtId, districtName) => {
		if (totalData && Object.keys(totalData || {})?.length) {
			const uniqDistrictId = Object.keys(
				totalData[stateId]?.mapDistricts || {}
			).find((value) => value === districtId);
			if (uniqDistrictId) {
				const districtData = totalData[stateId]?.mapDistricts[uniqDistrictId];
				setDistrictDetails({
					...districtDetails,
					isActive: true,
					id: districtId,
					districtName,
					stateId,
				});
				setState({
					farmerCount: districtData?.farmerCount,
					totalFarmArea: districtData?.cultivatedArea,
					crops: districtData?.distinctCrops ? districtData?.distinctCrops : [],
					varieties: districtData?.distinctVarieties
						? districtData?.distinctVarieties
						: [],
				});
				setTooltipContent(true);
				return districtData;
			}
			setDistrictDetails({
				...districtDetails,
				isActive: false,
				id: districtId,
				districtName,
				stateId,
			});
			setTooltipContent(true);
			return false;
		}
		return false;
	};

	return (
		<div className="flex justify-center">
			<section className=" h-screen w-3/6 focus-visible:outline-0 focus-visible:border-0">
				{isLoading ? (
					<div className="flex justify-center items-center">
						<Loading />
					</div>
				) : data ? (
					<div className="relative focus-visible:outline-0 focus-visible:border-0">
						<ComposableMap
							data-tip=""
							projection="geoMercator"
							width={150}
							height={150}
							projectionConfig={{ scale: scaleMap }}
						>
							<ZoomableGroup zoom={zoomMap} center={centerMap} disableZooming>
								<Geographies geography={data}>
									{({ geographies }) =>
										geographies.map((geo) => {
											const { districtName = '', districtId } = geo.properties;
											const uniqDistrictId = Object.keys(
												totalData[stateId]?.mapDistricts || {}
											).find((value) => value === districtId);
											return (
												<Geography
													key={geo.rsmKey}
													geography={geo}
													onMouseEnter={() => {
														getDistrictData(districtId, districtName);
													}}
													onMouseLeave={() => {
														setTooltipContent(false);
														setDistrictDetails({
															...districtDetails,
															id: '',
															isActive: false,
															districtName: '',
															stateId: '',
														});
													}}
													onClick={() => {
														if (uniqDistrictId) {
															const polygons =
																geo.geometry.type === 'MultiPolygon'
																	? turf.polygon(
																			geo.geometry.coordinates.flat()
																	  )
																	: turf.polygon(geo.geometry.coordinates);
															const centroids = turf.centroid(polygons);
															setCoordinates([
																centroids.geometry.coordinates[1],
																centroids.geometry.coordinates[0],
															]);
															onDistrictClickHandler(geo);
														}
													}}
													style={{
														default: {
															fill: uniqDistrictId ? '#2E8B57' : '#D6D6DA',
															outline: 'none',
															stroke: '#737373',
															strokeWidth: 0.1,
														},
														hover: {
															fill: uniqDistrictId ? 'forestgreen' : '#D6D6DA',
															outline: 'none',
															stroke: '#737373',
															strokeWidth: 0.1,
														},
														pressed: {
															fill: uniqDistrictId ? '#2E8B57' : '#D6D6DA',
															outline: 'none',
															stroke: '#737373',
															strokeWidth: 0.1,
														},
													}}
												/>
											);
										})
									}
								</Geographies>
								{markers.map(({ name, coordinates, markerOffset }) => (
									<Marker key={name} coordinates={coordinates}>
										<text
											textAnchor="middle"
											y={markerOffset}
											style={{
												fontFamily: 'Open Sans',
												fill: '#000',
												fontSize: 2,
												fontWeight: '400',
											}}
										>
											{name}
										</text>
									</Marker>
								))}
							</ZoomableGroup>
						</ComposableMap>
						{tooltipContent ? (
							<MapSmallPopup
								districtId={districtDetails.id}
								isActive={districtDetails.isActive}
								type="District"
								stateId={districtDetails.stateId}
								header={districtDetails.districtName}
								data={state}
							/>
						) : null}
					</div>
				) : (
					<NoDataFound />
				)}
			</section>
		</div>
	);
};

export default StateMap;
