/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import {
	PieChart,
	Pie,
	ResponsiveContainer,
	Cell,
	Legend,
	Tooltip,
} from 'recharts';
import exportFromJSON from 'export-from-json';
import {
	useGetCropStagesWithDaysChartDataQuery,
	useGetCropStagesWithDaysExcelDataMutation,
} from '../../redux/api/slices/dashboardApiSlice';
import { renderAcres } from '../../util';
import ErrorOccured from '../ErrorOccured';
import Loading from '../Loading';
import Table from '../Table';
import {
	chartHeight,
	chartWidth,
	cropStagesWithDaysMap,
	CustomToolTip,
	DashboardChartHeader,
	getKeyFromText,
	LegendCellRenderer,
} from './util';

const COLORS = [
	{ tailwindColorName: 'bg-darkBlue', colorHex: '#405189' },
	{ tailwindColorName: 'bg-lightBlue', colorHex: '#299CDB' },
	{ tailwindColorName: 'bg-darkOrange', colorHex: '#E25A5A' },
	{ tailwindColorName: 'bg-orange', colorHex: '#F7B84B' },
	{ tailwindColorName: 'bg-green', colorHex: '#0AB39C' },
	{ tailwindColorName: 'bg-WashedOutPurple', colorHex: '#89839C' },
	{ tailwindColorName: 'bg-DarkVanilla', colorHex: '#D0B8A8' },
	{ tailwindColorName: 'bg-ChinaRed', colorHex: '#AD2B10' },
];

const RADIAN = Math.PI / 180;

const CropStagesPieChart = ({
	farmerCodes = [],
	companyCropId,
	cropVariety,
	districts = [],
	states = [],
	fromDate,
	toDate,
}) => {
	const [chartData, setChartData] = useState([]);
	const [customLoading, setCustomLoading] = useState(true);

	const {
		data: cropStagesChartData,
		isLoading,
		isError,
		error,
		isFetching,
	} = useGetCropStagesWithDaysChartDataQuery(
		companyCropId?.length && fromDate && toDate
			? {
					farmerCodes,
					companyCropId,
					cropVarietyId: cropVariety,
					districts,
					states,
					fromDate,
					toDate,
			  }
			: skipToken
	);
	const [getCropStagesWithDaysExcelData] =
		useGetCropStagesWithDaysExcelDataMutation();

	useEffect(() => {
		if (isFetching) {
			setCustomLoading(true);
		}
	}, [isFetching]);

	useEffect(() => {
		const keys = Object.keys(cropStagesChartData || {}) || [];
		if (keys.length) {
			const mData = keys.map((key, index) => ({
				value: cropStagesChartData[key],
				id: getKeyFromText({ text: key }),
				name: cropStagesWithDaysMap[key],
				color: COLORS[index % COLORS.length],
			}));
			setChartData(mData);
			setCustomLoading(false);
		} else {
			setChartData([]);
			setCustomLoading(false);
		}
	}, [cropStagesChartData]);

	const columns = useMemo(
		() => [
			{
				Header: 'Stage',
				accessor: 'name',
				Cell: ({ row }) => <LegendCellRenderer row={row} />,
				width: 150,
			},
			{
				Header: 'Acres',
				accessor: 'value',
				Cell: ({ row }) => (
					<p>
						{renderAcres({
							val: row?.original?.value || 0,
						})}
					</p>
				),
				width: 100,
			},
		],
		[]
	);

	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
	}) => {
		const radius = innerRadius - 6 + (outerRadius - innerRadius) * 1.25;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				className="text-xs"
				x={x}
				y={y}
				fill="black"
				textAnchor={x > cx ? 'start' : 'end'}
				dominantBaseline="central"
			>
				{`${
					(percent * 100).toFixed(2).endsWith('.00')
						? `${percent * 100 === 0 ? '' : `${(percent * 100).toFixed(0)} %`}`
						: `${(percent * 100).toFixed(2)}%`
				}`}
			</text>
		);
	};

	const isData = useMemo(() => {
		const total = chartData.reduce((a, b) => a + b.value, 0);
		if (total <= 0) {
			return false;
		}
		return true;
	}, [chartData]);

	const downloadExcelData = async () => {
		try {
			const result = await getCropStagesWithDaysExcelData({
				farmerCodes,
				companyCropId,
				cropVarietyId: cropVariety,
				states,
				districts,
				fromDate,
				toDate,
			}).unwrap();
			if (result && result?.length) {
				const finalResult = result?.map((item) => ({
					'Farmer Name': item?.firstName || '',
					'Cultivated Area': item?.cultivatedArea || '',
					Crop: item?.cropName || '',
					'Crop Variety': item?.cropVarietyName || '',
					'Stage Name': item?.stageName || '',
					Mandal: item?.mandal || '',
					Village: item?.village || '',
					District: item?.district || '',
					State: item?.state || '',
					Postcode: item?.postcode || '',
				}));
				const exportData = {
					data: finalResult,
					fileName: 'cropStages',
					exportType: 'csv',
				};
				exportFromJSON(exportData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const dropdownBtns = [
		{
			label: 'Export To Excel',
			action: async () => {
				await downloadExcelData();
			},
			isDisabled: !chartData.length || !isData,
		},
	];

	return (
		<>
			<DashboardChartHeader
				text="Crop Stages"
				subText="(Acres)"
				dropdownBtns={dropdownBtns}
			/>
			{isLoading || customLoading ? (
				<div className="card-center">
					<Loading />
				</div>
			) : isError ? (
				<div className="card-center">
					<ErrorOccured fullScreenMode msg={error} />
				</div>
			) : chartData.length && isData ? (
				<ResponsiveContainer width="100%" height="100%" className="p-2.5">
					<PieChart width={chartWidth} height={chartHeight}>
						<Pie
							data={chartData}
							dataKey="value"
							cx="40%"
							cy="40%"
							innerRadius={60}
							label={renderCustomizedLabel}
							labelLine={false}
							outerRadius={95}
							minAngle={10}
						>
							{chartData.map((entry) => (
								<Cell key={entry.id} fill={entry?.color?.colorHex} />
							))}
						</Pie>
						<Legend
							className="hidden lg:block h-64 overflow-y-auto scrollbar ml-3"
							align="right"
							layout="vertical"
							verticalAlign="middle"
							wrapperStyle={{ right: '5%', top: '10%' }}
							content={
								<Table
									data={chartData}
									columns={columns}
									theadClassName="w-full text-center text-sm font-semibold"
									tdClassName="text-xs font-normal"
								/>
							}
						/>
						<Tooltip content={<CustomToolTip unit="Acres" />} />
					</PieChart>
				</ResponsiveContainer>
			) : (
				<h3 className="card-center text-xl font-medium">No Data found</h3>
			)}
		</>
	);
};

export default CropStagesPieChart;
