/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ErrorOccured from '../components/ErrorOccured';
import Loading from '../components/Loading';
import NavMenu from '../components/NavMenu';
import { useAuth, useGetFilters } from '../hooks';
import {
	ChevronDoubleRightLeft,
	DashboardIcon,
	ChevronLeftRight,
	LogoutIcon,
	ExecutivesIcon,
	UserProfileIcon,
	FarmerIcon,
	ReportsIcon,
	ImagesIcon,
} from '../icons';
import { useGetCompanyInfoQuery } from '../redux/api/slices/companyApiSlice';
import { logout } from '../redux/api/slices/userApiSlice';
import MapIcon from '../icons/MapIcon';
import { useGetApplicationSettingsQuery } from '../redux/api/slices/dashboardApiSlice';
import { useGetSettingsFromState } from '../redux/api/hooks/dashboardHooks';
import ResetPassword from '../icons/ResetPassword';
import ImageLogo from '../icons/ImageLogo';

const linksClassName = `flex items-center py-2 px-2.5 text-sm font-normal rounded-md text-white hover:bg-sideMenuHoverColor1`;

const NavBar = ({
	activeTab = '/',
	showExpandedSideBar,
	setShowExpandedSideBar,
}) => {
	const auth = useAuth();
	const { cId } = useParams();
	const navigate = useNavigate();
	const filters = useGetFilters();
	const [selectedCompanyCropID, setSelectedCompanyCropID] = useState(['']);
	const dashboardSettings = useGetSettingsFromState();
	const mapLabel = dashboardSettings?.mapNavLabel || '';
	const {
		data: companyInfo,
		isLoading: companyInfoIsLoading,
		isFetching: companyInfoIsFetching,
		isError: companyInfoIsError,
		error: companyInfoError,
	} = useGetCompanyInfoQuery(
		cId &&
			auth?.accessToken &&
			selectedCompanyCropID &&
			selectedCompanyCropID?.length &&
			selectedCompanyCropID[0]
			? {
					companyId: selectedCompanyCropID,
			  }
			: skipToken
	);

	const { isError, error } = useGetApplicationSettingsQuery();

	const dispatch = useDispatch();

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCompanyCropID(filters.companyCropID);
		}
	}, [filters]);

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		isError && console.log(error);
	}, [isError, error]);

	const userNavItems = useMemo(
		() => [
			{
				id: 'user-profile-btn',
				label: 'Profile',
				btnClassName: '',
				activeIcon: (
					<UserProfileIcon className="h-[18px] w-[18px] mr-1.5" fill="#fff" />
				),
				inActiveIcon: (
					<UserProfileIcon
						className="h-[18px] w-[18px] mr-1.5"
						fill="#325b4e"
					/>
				),
				onClickAction: () =>
					navigate(`/cid/${cId}/user-profile/${auth.userId}`),
			},
			{
				id: 'change-password-btn',
				label: 'Change Password',
				btnClassName: '',
				activeIcon: (
					<ResetPassword className="h-[18px] w-[18px] mr-1.5" fill="#fff" />
				),
				inActiveIcon: (
					<ResetPassword className="h-[18px] w-[18px] mr-1.5" fill="#325b4e" />
				),
				onClickAction: () =>
					navigate(`/cid/${cId}/change-password/${auth.userId}`),
			},
			{
				id: 'logout-btn',
				label: 'Logout',
				btnClassName: '',
				activeIcon: (
					<LogoutIcon className="h-[18px] w-[18px] mr-1.5" fill="#fff" />
				),
				inActiveIcon: (
					<LogoutIcon className="h-[18px] w-[18px] mr-1.5" fill="#325b4e" />
				),
				onClickAction: () => {
					dispatch(logout());
					navigate('/login');
				},
			},
		],
		[]
	);

	const reportsNavItems = useMemo(
		() => [
			{
				id: 'chemical-composition-report-nav',
				label: 'Chemical Composition Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/chemical-composition-report`),
			},
			{
				id: 'ipm-adherence-report-nav',
				label: 'IPM Adherence Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/ipm-adherence-report`),
			},
			{
				id: 'disease-report',
				label: 'Disease Report',
				btnClassName: '',
				onClickAction: () => navigate(`/cid/${cId}/reports/disease-report`),
			},
			{
				id: 'farm-land-details-activity-report',
				label: 'Farm Land Details Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/farm-land-details-activity-report`),
			},
			{
				id: 'nursery-details-activity-report',
				label: 'Nursery Details Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/nursery-details-activity-report`),
			},
			{
				id: 'main-field-activity-report',
				label: 'Main Field Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/main-field-activity-report`),
			},
			{
				id: 'nutrient-activity-report',
				label: 'Nutrient Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/nutrient-activity-report`),
			},
			{
				id: 'pest-activity-report',
				label: 'Pest Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/pest-activity-report`),
			},
			{
				id: 'irrigation-activity-report',
				label: 'Irrigation Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/irrigation-activity-report`),
			},
			{
				id: 'post-harvest-activity-report',
				label: 'Post Harvest Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/post-harvest-activity-report`),
			},
			{
				id: 'product-sampling-activity-report',
				label: 'Product Sampling Activity Report',
				btnClassName: '',
				onClickAction: () =>
					navigate(`/cid/${cId}/reports/product-sampling-activity-report`),
			},
		],
		[]
	);

	useEffect(() => {
		(async () => {
			const hideSideBar = await localStorage.getItem('showExpandedSideBar');
			setShowExpandedSideBar(hideSideBar === 'true');
		})();
	}, []);

	useEffect(() => {
		const fevicon = document.getElementById('fevicon');
		if (fevicon && companyInfo?.logo) {
			fevicon.href = companyInfo?.logo;
		}
	}, [companyInfo?.logo]);

	const userProfile = auth?.profilePic ? (
		<img
			className="h-6 w-6 object-contain rounded-full"
			src={auth.profilePic}
			alt="avatar"
		/>
	) : (
		<UserProfileIcon className="h-6 w-6 hover:bg-halfGreen rounded-2xl" />
	);

	if (companyInfoIsLoading || companyInfoIsFetching) {
		return <Loading fullScreenMode />;
	}

	if (companyInfoIsError) {
		return <ErrorOccured fullScreenMode msg={companyInfoError.error} />;
	}

	return (
		<nav
			className={`h-screen fixed bg-sideMenuColor1 ${
				showExpandedSideBar ? 'w-56' : 'w-16'
			}`}
		>
			<div
				className={`${
					showExpandedSideBar ? 'h-16 md:h-24' : 'h-16'
				} bg-white bg-opacity-80 shadow-xl flex items-center space-x-2 py-3 mb-5`}
			>
				{companyInfo?.logo ? (
					<img
						className={`${
							showExpandedSideBar ? 'h-full ml-4' : 'ml-2 h-16 w-12'
						} object-contain`}
						src={companyInfo.logo}
						alt="avatar"
					/>
				) : (
					<p
						className={`${
							showExpandedSideBar ? 'text-2xl leading-6' : 'text-base leading-5'
						}  `}
					>
						{companyInfo?.companyName}
					</p>
				)}
			</div>
			<div className="h-screen pl-2.5 pr-2.5">
				<ul className="space-y-2">
					<li className="space-y-4">
						<Link
							to={`${cId}/dashboard`}
							className={`${linksClassName} ${
								(activeTab || '').includes('dashboard')
									? 'bg-sideMenuHoverColor2 hover:bg-sideMenuHoverColor2'
									: ''
							}`}
							title={showExpandedSideBar ? '' : 'Dashboard'}
						>
							<DashboardIcon className="h-5 w-5" fill="white" />
							{showExpandedSideBar && <span className="ml-2.5">Dashboard</span>}
						</Link>
					</li>
					<li className="space-y-4">
						<Link
							to={`${cId}/executives`}
							className={`${linksClassName} ${
								(activeTab || '').includes('executives')
									? 'bg-sideMenuHoverColor2 hover:bg-sideMenuHoverColor2'
									: ''
							}`}
							title={showExpandedSideBar ? '' : 'Executives'}
						>
							<ExecutivesIcon className="h-6 w-6" fill="white" />
							{showExpandedSideBar && (
								<span className="ml-2.5">Executives</span>
							)}
						</Link>
					</li>

					<li className="space-y-4">
						<Link
							to={`${cId}/farmers`}
							className={`${linksClassName} ${
								(activeTab || '').includes('farmers')
									? 'bg-sideMenuHoverColor2 hover:bg-sideMenuHoverColor2'
									: ''
							}`}
							title={showExpandedSideBar ? '' : 'Farmers'}
						>
							<FarmerIcon className="h-6 w-6 text-white" fill="white" />
							{showExpandedSideBar && <span className="ml-2.5">Farmers</span>}
						</Link>
					</li>

					<li className="space-y-4">
						<Link
							to={`${cId}/districtWiseStageImages`}
							className={`${linksClassName} ${
								(activeTab || '').includes('District Wise Stage Images')
									? 'bg-sideMenuHoverColor2 hover:bg-sideMenuHoverColor2'
									: ''
							}`}
							title={showExpandedSideBar ? '' : 'District Wise Stage Images'}
						>
							<ImagesIcon className="h-6 w-6 text-white" fill="white" />
							{/* <ImageLogo className="h-6 w-6 text-white" fill="white" /> */}
							{showExpandedSideBar && (
								<span className="ml-2.5">District Wise Stage Images</span>
							)}
						</Link>
					</li>

					<li className="space-y-4">
						<Link
							to={`${cId}/summary`}
							className={`${linksClassName} ${
								(activeTab || '').includes('/summary')
									? 'bg-sideMenuHoverColor2 hover:bg-sideMenuHoverColor2'
									: ''
							}`}
							title={showExpandedSideBar ? '' : isError ? 'Summary' : mapLabel}
						>
							<MapIcon className="h-6 w-6 text-white" fill="white" />
							{showExpandedSideBar && (
								<span className="ml-2.5">{isError ? 'Summary' : mapLabel}</span>
							)}
						</Link>
					</li>

					<li className="space-y-4">
						<NavMenu
							navMenuItems={reportsNavItems}
							className="z-50"
							itemsClassName={`top-0 justify-between
								${showExpandedSideBar ? 'left-56' : 'left-16'}
							`}
							btnClassName="w-full"
							btnChildren={
								<div
									className={`${linksClassName} justify-between w-full pr-0 ${
										(activeTab || '').includes('reports')
											? 'bg-sideMenuHoverColor2 hover:bg-sideMenuHoverColor2'
											: ''
									}`}
									title={showExpandedSideBar ? '' : 'Reports'}
								>
									<div className="flex-row flex">
										<ReportsIcon
											className="w-6 h-6 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 text-white"
											fill="white"
										/>

										{showExpandedSideBar && (
											<span className="ml-2.5 justify-start items-start text-left">
												Reports
											</span>
										)}
									</div>
									<div className="mr-3">
										<ChevronLeftRight
											direction="right"
											className="h-5 w-5"
											color="white"
										/>
									</div>
								</div>
							}
						/>
					</li>
				</ul>
			</div>

			<div
				className={`absolute bottom-8 cursor-pointer rounded-lg text-white ${
					showExpandedSideBar ? 'px-2.5 w-full' : ''
				}`}
			>
				<div
					className={`flex justify-center items-center rounded-md ${
						showExpandedSideBar
							? 'px-2.5 py-1 bg-sideMenuHoverColor1 hover:bg-sideMenuHoverColor1 bg-opacity-80'
							: 'px-2.5 py-1'
					} z-10`}
				>
					<NavMenu
						navMenuItems={userNavItems}
						className="z-50 w-full"
						itemsClassName={
							showExpandedSideBar
								? '-top-12 left-48 justify-between'
								: '-top-14 left-16 justify-between'
						}
						btnChildren={
							showExpandedSideBar ? (
								<div className="flex flex-row justify-between items-center w-full">
									<div className="flex flex-row items-center">
										{userProfile}
										<div className="flex flex-col items-start ml-2">
											<p className="capitalize text-sm font-semibold text-white text-left">
												{`${auth?.firstName} ${auth?.lastName || ''}`}
											</p>
											<p className="cursor-pointer text-xs font-normal text-white text-left">
												Company Admin
											</p>
										</div>
									</div>
									<ChevronLeftRight
										direction="right"
										className="h-5 w-5 ml-4"
										color="white"
									/>
								</div>
							) : (
								<div className="w-12 overflow-hidden rounded-full flex justify-center items-center">
									{userProfile}
								</div>
							)
						}
					/>
				</div>
			</div>

			<div
				className="absolute bottom-0 right-0 bg-slate-300 rounded-l-md p-1"
				onClick={() => {
					setShowExpandedSideBar(!showExpandedSideBar);
					localStorage.setItem('showExpandedSideBar', !showExpandedSideBar);
				}}
			>
				<ChevronDoubleRightLeft
					direction={showExpandedSideBar ? 'left' : 'right'}
					color="black"
					className="h-4 w-4"
				/>
			</div>
		</nav>
	);
};

export default NavBar;
