import React from 'react';

const GenerateInitialsSvg = ({
	text,
	bgColor = 'white',
	textColor = 'black',
	rounded = true,
	height = 24,
	width = 24,
	className = '',
}) => {
	const getInitials = (str) =>
		(
			str
				.split(' ')
				.map((n) => n[0])
				.join('')
				.replace(/(\w)(\w)?(\w)*(\w)$/, '$1$4') || ''
		).toUpperCase();

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 ${height} ${width}`}
			className={className}
		>
			{rounded ? (
				<circle cx={height / 2} cy={width / 2} r={height / 2} fill={bgColor} />
			) : (
				<path fill={bgColor} d="M0 0h1000v1000H0z" />
			)}
			<text
				x="50%"
				y="50%"
				dominantBaseline="central"
				textAnchor="middle"
				fontSize={height / 2}
				fill={textColor}
			>
				{getInitials(text)}
			</text>
		</svg>
	);
};

export default GenerateInitialsSvg;
