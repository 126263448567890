/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import exportFromJSON from 'export-from-json';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '../Table';
import {
	chartHeight,
	chartWidth,
	CustomToolTip,
	DashboardChartHeader,
	encodeChemical,
	getKeyFromText,
	LegendCellRenderer,
} from './util';
import {
	useGetChemicalCompositionChartDataQuery,
	useGetChemicalCompositionExcelDataMutation,
} from '../../redux/api/slices/dashboardApiSlice';
import ErrorOccured from '../ErrorOccured';
import Loading from '../Loading';

const COLORS = [
	{ tailwindColorName: 'bg-Azure', colorHex: '#0088FE' },
	{ tailwindColorName: 'bg-CaribbeanGreen', colorHex: '#C40025' },
	{ tailwindColorName: 'bg-RipeMango', colorHex: '#286CFF' },
	{ tailwindColorName: 'bg-MangoTango', colorHex: '#FF8042' },
	{ tailwindColorName: 'bg-Bistre', colorHex: '#3C2317' },
	{ tailwindColorName: 'bg-TealBlue', colorHex: '#256D85' },
	{ tailwindColorName: 'bg-DarkVanilla', colorHex: '#D0B8A8' },
	{ tailwindColorName: 'bg-FluorescentBlue', colorHex: '#31E1F7' },
];

// const RADIAN = Math.PI / 180;

const ChemicalCompositionChart = ({
	farmerCodes = [],
	companyCropId = [],
	cropVariety = [],
	states = [],
	districts = [],
	fromDate,
	toDate,
}) => {
	const [chartData, setChartData] = useState([]);
	const [customLoading, setCustomLoading] = useState(true);
	const navigation = useNavigate();
	const { cId } = useParams();

	const {
		data: chemicalCompositionChartData,
		isLoading,
		isError,
		error,
		isFetching,
	} = useGetChemicalCompositionChartDataQuery(
		companyCropId?.length && fromDate && toDate
			? {
					farmerCodes,
					companyCropId,
					cropVarietyId: cropVariety,
					states,
					districts,
					fromDate,
					toDate,
			  }
			: skipToken
	);

	const [getChemicalCompositionExcelData] =
		useGetChemicalCompositionExcelDataMutation();

	useEffect(() => {
		if (isFetching) {
			setCustomLoading(true);
		}
	}, [isFetching]);

	useEffect(() => {
		const keys = Object.keys(chemicalCompositionChartData || {});
		if (keys.length) {
			const mData = keys.map((key, index) => ({
				value: chemicalCompositionChartData[key],
				id: getKeyFromText(key),
				name: key,
				color: COLORS[index % COLORS.length],
			}));
			mData.sort((a, b) => b.value - a.value);
			setChartData(mData);
			setCustomLoading(false);
		} else {
			setChartData([]);
			setCustomLoading(false);
		}
	}, [chemicalCompositionChartData]);

	const columns = useMemo(
		() => [
			{
				Header: 'Chemical Name',
				accessor: 'name',
				Cell: ({ row }) => <LegendCellRenderer row={row} />,
				width: 200,
			},
			{
				Header: 'Count',
				accessor: 'value',
				Cell: ({ row }) => <p>{row?.original?.value}</p>,
				width: 80,
			},
		],
		[]
	);

	// const renderCustomizedLabel = ({
	// 	cx,
	// 	cy,
	// 	midAngle,
	// 	innerRadius,
	// 	outerRadius,
	// 	percent,
	// }) => {
	// 	const radius = innerRadius - 6 + (outerRadius - innerRadius) * 0.4;
	// 	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	// 	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	// 	return (
	// 		<text
	// 			className="text-xs"
	// 			x={x}
	// 			y={y}
	// 			fill="white"
	// 			textAnchor={x > cx ? 'start' : 'end'}
	// 			dominantBaseline="central"
	// 		>
	// 			{`${(percent * 100).toFixed(0)}%`}
	// 		</text>
	// 	);
	// };

	const downloadExcelData = async () => {
		try {
			const result = await getChemicalCompositionExcelData({
				farmerCodes,
				companyCropId,
				cropVarietyId: cropVariety,
				states,
				districts,
				fromDate,
				toDate,
			}).unwrap();
			if (result && result?.length) {
				const finalResult = result?.map((item) => ({
					'Farmer Name': `${item?.firstName || ''} ${item?.lastName || ''}`,
					'Company Name': item?.companyName || '',
					Crop: item?.cropName || '',
					'Crop Variety': item?.varietiName || '',
					Chemical: item?.chemicalName || '',
					Usage: item?.usage || '',
					'Brand Name': item?.tradeNameNCompany || '',
					Village: item?.village || '',
					Mandal: item?.mandal || '',
					District: item?.district || '',
					State: item?.state || '',
					Postcode: item?.pincode || '',
				}));
				const exportData = {
					data: finalResult,
					fileName: 'chemical_composition',
					exportType: 'csv',
				};
				exportFromJSON(exportData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const dropdownBtns = [
		{
			label: 'Export To Excel',
			action: async () => {
				await downloadExcelData();
			},
			isDisabled: !chartData.length,
		},
	];

	return (
		<>
			<DashboardChartHeader
				text="Chemical Composition"
				dropdownBtns={dropdownBtns}
			/>
			{isLoading || customLoading ? (
				<div className="card-center">
					<Loading />
				</div>
			) : isError ? (
				<div className="card-center">
					<ErrorOccured fullScreenMode msg={error} />
				</div>
			) : chartData.length ? (
				<ResponsiveContainer width="100%" height="100%" className="p-2.5">
					<PieChart width={chartWidth} height={chartHeight}>
						<Pie
							data={chartData}
							dataKey="value"
							cx="40%"
							cy="40%"
							innerRadius={60}
							labelLine={false}
							outerRadius={95}
							minAngle={10}
						>
							{chartData.map((entry) => (
								<Cell
									key={entry.id}
									fill={entry?.color?.colorHex}
									onClick={() =>
										navigation(
											`/cid/${cId}/reports/chemical-composition-report/${encodeChemical(
												entry.name.trim()
											)}`
										)
									}
								/>
							))}
						</Pie>
						<Legend
							className="hidden lg:block h-64 overflow-y-auto scrollbar ml-3"
							align="right"
							layout="vertical"
							verticalAlign="middle"
							wrapperStyle={{ right: '5%', top: '10%' }}
							content={
								<Table
									data={chartData}
									columns={columns}
									theadClassName="w-full text-center text-sm font-semibold"
									tdClassName="text-xs font-normal"
								/>
							}
						/>
						<Tooltip content={<CustomToolTip />} />
					</PieChart>
				</ResponsiveContainer>
			) : (
				<h3 className="card-center text-xl font-medium">No Data found</h3>
			)}
		</>
	);
};

export default ChemicalCompositionChart;
