import { API_DATE_FORMAT } from '.';

const {
	format: dateFnsFormat,
	startOfMonth,
	lastDayOfMonth,
} = require('date-fns');

export const getCurrentFormattedDate = ({ reqFormat = 'dd_MMM_yyyy' }) =>
	dateFnsFormat(new Date(), reqFormat);

export const getFormattedDate = ({ date, reqFormat }) =>
	dateFnsFormat(new Date(date), reqFormat);

export const getStartOfMonth = ({ date }) => startOfMonth(date);

export const getEndOfMonth = ({ date }) => lastDayOfMonth(date);

export const getPreviousDate = (days) => {
	const date = new Date();
	date.setDate(date.getDate() - days);
	const formattedDate = getFormattedDate({
		date,
		reqFormat: API_DATE_FORMAT,
	});
	return formattedDate;
};

export const getDaysBetweenDates = (date1, date2) => {
	const oneDay = 24 * 60 * 60 * 1000;
	const startDate = new Date(date1);
	const endDate = new Date(date2);
	const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
	const days = Math.round(timeDifference / oneDay);

	return days;
};
