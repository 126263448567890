import React from 'react';

const ResetPassword = (props) => {
	const { height = 24, width = 24, fill = '#000', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			viewBox={`0 0 ${height} ${width}`}
			className={className}
			width={width}
			height={height}
		>
			{/* <path
				  d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 21.75c-5.81 0-10.5-4.69-10.5-10.5S6.19 1.75 12 1.75 22.5 6.44 22.5 12 17.81 21.75 12 21.75zm0-17.083v9.584h1.5V4.667h-1.5z"
                  fill={color}
			/> */}
			<g>
				<path d="M12,22c-1.8,0-3.5,0-5.3,0c-0.9,0-1.8-0.5-2.1-1.4c-0.1-0.3-0.2-0.7-0.2-1c0-2.8,0-5.5,0-8.3c0-0.9,0.3-1.6,1.1-2.1 C5.9,9,6.3,8.9,6.7,8.9c0.2,0,0.3,0,0.3-0.2c0-0.6,0-1.2,0-1.7c0-2,1.3-3.8,3.1-4.5c2.4-1,5.1,0,6.4,2.3C16.8,5.4,17,6.1,17,6.9 c0,0.6,0,1.2,0,1.8c0,0.3,0,0.3,0.3,0.3c1.2,0,2,0.7,2.2,1.8c0,0.2,0.1,0.4,0.1,0.5c0,2.8,0,5.7,0,8.5c0,1-0.6,1.8-1.5,2.2 C17.8,22,17.5,22,17.2,22C15.4,22,13.7,22,12,22z M12,19.9c1.7,0,3.4,0,5.2,0c0.4,0,0.4,0,0.4-0.4c0-2.7,0-5.5,0-8.2 c0-0.4,0-0.4-0.4-0.4c-3.4,0-6.9,0-10.3,0c-0.3,0-0.3,0-0.3,0.3c0,2.8,0,5.6,0,8.4c0,0.3,0,0.3,0.3,0.3C8.5,19.9,10.3,19.9,12,19.9 z M12,8.9c0.9,0,1.8,0,2.8,0c0.2,0,0.2-0.1,0.2-0.2c0-0.5,0-0.9,0-1.4c0-0.3,0-0.6-0.1-0.9C14.6,5,13.4,4,11.9,4 c-1.5,0-2.8,1.3-2.9,2.8c0,0.6,0,1.2,0,1.8c0,0.2,0.1,0.3,0.2,0.2C10.2,8.9,11.1,8.9,12,8.9z" />
				<path d="M11,16.6c0.1-0.2,0-0.4-0.2-0.6c-0.4-0.5-0.4-1.1,0-1.7c0.3-0.5,0.9-0.7,1.5-0.6c0.6,0.1,1.1,0.6,1.2,1.2 c0.1,0.4-0.1,0.8-0.3,1.1C13,16.2,13,16.3,13,16.4c0,0.1,0,0.2,0,0.3c0,0.6-0.4,1-1,1c-0.4,0-0.9-0.3-1-0.8 C11,16.9,11,16.7,11,16.6z" />
			</g>
		</svg>
	);
};

export default ResetPassword;
