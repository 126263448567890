import React from 'react';

const ChevronUpDownIcon = (props) => {
	const {
		height = 24,
		width = 24,
		color = '#FA9527',
		fill = 'none',
		strokeWidth = 1.5,
		className = '',
	} = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			viewBox={`0 0 ${height} ${width}`}
			strokeWidth={strokeWidth}
			stroke={color}
			className={className}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
			/>
		</svg>
	);
};

export default ChevronUpDownIcon;
