import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../../util/envConfig';

export const fileDownloadApi = createApi({
	reducerPath: 'fileDownloadApi',
	baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
	endpoints: (builder) => ({
		downloadFile: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [''],
				cropVarietyId = [''],
				states = [''],
				districts = [''],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-download-farmer-summary-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
	}),
});

export const { useDownloadFileMutation } = fileDownloadApi;
