/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorOccured from '../../components/ErrorOccured';
import Loading from '../../components/Loading';
import NoDataFound from '../../components/NoData';
import Table from '../../components/Table';
import { useGetFilters } from '../../hooks';
import { useGetChemicalCompositionReportDataQuery } from '../../redux/api/slices/dashboardApiSlice';
import { API_DATE_FORMAT, exportToExcel, prepareExportData } from '../../util';
import { getCurrentFormattedDate, getFormattedDate } from '../../util/dateUtil';
import { decodeChemical } from '../../components/DashboardCharts/util';

const ChemicalCompositionReportByChemicalName = () => {
	const { chemicalName, cId } = useParams();
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const filters = useGetFilters();
	const [pageSize, setPageSize] = useState(20);
	const navigation = useNavigate();

	const filterValue = decodeChemical(chemicalName);

	const {
		data: chemicalCompositionReportData = [],
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetChemicalCompositionReportDataQuery(
		selectedCrop?.length &&
			ranges?.length &&
			ranges[0]?.startDate &&
			ranges[0]?.endDate &&
			chemicalName &&
			filterValue
			? {
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety || [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.startDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
					toDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.endDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),

					filterValue,
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
		}
	}, [filters]);

	const columns = useMemo(
		() => [
			{
				Header: 'Farmer Name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.firstName || ''} ${
						row?.original?.lastName || ''
					} `}</p>
				),
			},
			{
				Header: 'Crop Variety',
				accessor: 'varietiName',
				Cell: ({ row }) => <p>{row?.original?.varietiName}</p>,
			},
			{
				Header: 'Chemical Name',
				accessor: 'chemicalName',
				Cell: ({ row }) => <p>{row?.original?.chemicalName}</p>,
			},
			{
				Header: 'Brand Name',
				accessor: 'tradeNameNCompany',
				Cell: ({ row }) => <p>{row?.original?.tradeNameNCompany}</p>,
			},
			{
				Header: 'Usage (count)',
				accessor: 'usage',
				Cell: ({ row }) => <p>{row?.original?.usage}</p>,
				width: 120,
			},
			{
				Header: 'Village',
				accessor: 'village',
				Cell: ({ row }) => <p>{row?.original?.village}</p>,
			},
			{
				Header: 'Mandal',
				accessor: 'mandal',
				Cell: ({ row }) => <p>{row?.original?.mandal}</p>,
			},
			{
				Header: 'District',
				accessor: 'district',
				Cell: ({ row }) => <p>{row?.original?.district}</p>,
			},
			{
				Header: 'State',
				accessor: 'state',
				Cell: ({ row }) => <p>{row?.original?.state}</p>,
			},
			{
				Header: 'Pincode',
				accessor: 'pincode',
				Cell: ({ row }) => <p>{row?.original?.pincode}</p>,
				width: 80,
			},
		],
		[]
	);

	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: chemicalCompositionReportData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		const sheetData = exportData.map((chemcData, index) => ({
			'Sl. No': index + 1,
			'Farmer Name': `${chemcData?.firstName} ${chemcData?.lastName || ''}`,
			Crop: chemcData?.cropName || '',
			'Crop Variety': chemcData?.varietiName || '',
			'Chemical Technical Name': chemcData?.chemicalName,
			'Brand Name': chemcData?.tradeNameNCompany,
			'Usage (count)': chemcData?.usage,
			Village: chemcData?.village,
			Mandal: chemcData?.mandal,
			District: chemcData?.district,
			State: chemcData?.state,
			Pincode: chemcData?.pincode,
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `Chemical_Composition_Report_${chemicalName}_${getCurrentFormattedDate(
					{}
				)}.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div>
			<button
				type="button"
				onClick={() => {
					navigation(`/cId/${cId}/dashboard`);
				}}
				className="flex items-center w-9 h-9 justify-center bg-white p-2 text-white rounded-lg border border-gray-400 ml-1"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="#4EAA6F"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</button>
			{chemicalCompositionReportData?.length ? (
				<Table
					data={chemicalCompositionReportData}
					columns={columns}
					className="w-full card table border-0 shadow-none overflow-x-hidden"
					theadClassName="thead text-sm text-left "
					tdClassName="px-4 td text-sm border-b border-border2 text-labelColor"
					displayTotal
					isFilterable
					title={`Chemical Composition Report : ${filterValue}`}
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion
					defaultPageSize={pageSize}
				/>
			) : (
				<>
					<h2>{`Chemical Composition Report : ${filterValue}`}</h2>
					<NoDataFound />
				</>
			)}
		</div>
	);
};

export default ChemicalCompositionReportByChemicalName;
