import { useSelector } from 'react-redux';
import {
	getCurrentUser,
	getUserAppliedFilters,
} from '../redux/api/slices/userApiSlice';

const useAuth = () => useSelector(getCurrentUser);

const useGetFilters = () => useSelector(getUserAppliedFilters);

export { useAuth, useGetFilters };
