/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCompanyFarmersQuery } from '../redux/api/slices/companyApiSlice';
// import { useGetFarmerSummaryReportMutation } from '../redux/api/slices/dashboardApiSlice';
import Loading from '../components/Loading';
import Table from '../components/Table';
import ErrorOccured from '../components/ErrorOccured';
import { useAuth, useGetFilters } from '../hooks';
import { apiBaseUrl } from '../util/envConfig';
import {
	API_DATE_FORMAT,
	exportToExcel,
	prepareExportData,
	renderAcres,
} from '../util';

import { getCurrentFormattedDate, getFormattedDate } from '../util/dateUtil';
import NoDataFound from '../components/NoData';

const Farmers = () => {
	const auth = useAuth();
	// const [getFarmerSummaryReport] = useGetFarmerSummaryReportMutation();
	const [selectedFarmer, setSelectedFarmer] = useState();
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const [ranges, setRanges] = useState();
	const [isDownloading, setIsDownloading] = useState(false);
	const { cId } = useParams();
	const filters = useGetFilters();

	const {
		data: farmersData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetCompanyFarmersQuery(
		cId && selectedCrop && ranges?.length
			? {
					farmerCode: selectedFarmer?.id,
					companyId: cId,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety || [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setRanges(filters.ranges);
			setSelectedFarmer(filters.farmer);
		}
	}, [filters]);

	const downloadSummaryReport = async (
		selectedFarmer,
		selectedCrop,
		selectedCropVariety,
		selectedStates,
		selectedDistricts,
		ranges
	) => {
		try {
			setIsDownloading(true);
			const farmerCodes = [selectedFarmer?.farmercode];
			const cropVarietyId = selectedCropVariety || [''];
			const companyCropId = selectedCrop;
			const states = selectedStates;
			const districts = selectedDistricts;
			const fromDate = getFormattedDate({
				date: new Date(ranges[0]?.startDate),
				reqFormat: API_DATE_FORMAT,
			});
			const toDate = getFormattedDate({
				date: new Date(ranges[0]?.endDate),
				reqFormat: API_DATE_FORMAT,
			});
			// Todo: Need to Use RTK Query
			const payload = {
				companyCropId,
				cropVarietyId,
				states,
				districts,
				fromDate,
				toDate,
				farmerCodes,
			};
			const url = `${apiBaseUrl}/reports/get-download-farmer-summary-report`;
			console.log('url : ', url);
			const response = await fetch(url, {
				method: 'POST',
				body: JSON.stringify(payload), // Assuming payload is a JSON object
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${auth?.accessToken}`,
					// Add any other necessary headers
				},
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const blob = await response.blob();
			const fileUrl = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = fileUrl;
			link.download = `${farmerCodes[0]}_Farmer_Report.xlsx`;
			link.click();
			URL.revokeObjectURL(fileUrl);
			setIsDownloading(false);
		} catch (err) {
			setIsDownloading(false);
			console.log(err);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Farmer Name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<p>{`${row?.original?.lastName || ''} ${
						row?.original?.firstName || ''
					}`}</p>
				),
				width: 150,
			},
			{
				Header: 'Farmer Code',
				accessor: 'farmercode',
				Cell: ({ row }) => <p>{row?.original?.farmercode || ''}</p>,
				width: 100,
			},
			{
				Header: 'Cluster',
				accessor: 'clusterName',
				Cell: ({ row }) => <p>{row?.original?.clusterName}</p>,
				width: 100,
			},
			{
				Header: 'Village',
				accessor: 'villageName',
				Cell: ({ row }) => <p>{row?.original?.villageName}</p>,
				width: 200,
			},
			{
				Header: 'Crop',
				accessor: 'cropName',
				Cell: ({ row }) => <p>{row?.original?.cropName}</p>,
				width: 200,
			},
			{
				Header: 'Crop Variety',
				accessor: 'cropVarietyName',
				Cell: ({ row }) => <p>{row?.original?.cropVarietyName}</p>,
				width: 200,
			},
			{
				Header: 'Acres',
				accessor: 'farmerAcres',
				Cell: ({ row }) => (
					<p className="w-full text-center">
						{renderAcres({
							val: row?.original?.farmerAcres || 0,
						})}
					</p>
				),
				width: 80,
			},
			{
				Header: 'Actions',
				accessor: '',
				Cell: ({ row }) => (
					<p className="w-100 text-center">
						<button
							type="button"
							onClick={() =>
								downloadSummaryReport(
									row.original,
									selectedCrop,
									selectedCropVariety,
									selectedStates,
									selectedDistricts,
									ranges
								)
							}
							className="w-100 px-4 py-2 text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-secondary tracking-wider"
						>
							Download Summary
						</button>
					</p>
				),
				width: 200,
			},
		],
		[
			selectedCrop,
			selectedStates,
			selectedDistricts,
			selectedCropVariety,
			selectedFarmer,
		]
	);

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: farmersData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		const sheetData = exportData.map((farmer, index) => ({
			'Sl. No': index + 1,
			'Farmer Name': `${farmer?.firstName} ${farmer?.lastName || ''}`,
			Crop: farmer?.cropName,
			'Crop Variety': farmer?.cropVarietyName,
			Cluster: farmer?.clusterName,
			Village: farmer?.villageName,
			'Cultivated Area': farmer?.farmerAcres || 0,
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `Farmers_${getCurrentFormattedDate({})}.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	if (isLoading || isFetching || isDownloading) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{farmersData?.length ? (
				<Table
					data={farmersData}
					columns={columns}
					className="w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-2.5 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title="Farmers"
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isLoading || isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion
					defaultPageSize={20}
				/>
			) : (
				<NoDataFound />
			)}
		</div>
	);
};

export default Farmers;
