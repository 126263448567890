import React from 'react';

const LogoutIcon = (props) => {
	const { height = 24, width = 24, fill = '#000', className = '' } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			viewBox={`0 0 ${height} ${width}`}
			className={className}
			width={width}
			height={height}
		>
			{/* <path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
			/> */}
			<g>
				<path d="M22,12c0,2.9,0,5.7,0,8.6c0,0.8-0.6,1.4-1.4,1.4c-3.3,0-6.5,0-9.8,0c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0-1.6,0-2.4 c0-0.6,0.5-1.2,1.1-1.2c0.7-0.1,1.3,0.4,1.4,1c0,0.4,0,0.7,0,1.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.2,0.2c2.4,0,4.7,0,7.1,0 c0.1,0,0.2,0,0.2-0.2c0,0,0-0.1,0-0.1c0-4.8,0-9.6,0-14.4c0-0.3,0-0.3-0.3-0.3c-2.3,0-4.6,0-6.9,0c-0.3,0-0.3,0-0.3,0.3 c0,0.3,0,0.7,0,1C12,6.5,11.5,7,10.7,7C10,7,9.5,6.5,9.5,5.8c0-0.8,0-1.7,0-2.5C9.5,2.6,10,2,10.7,2c3.3,0,6.7,0,10,0 C21.4,2,22,2.6,22,3.3C22,6.2,22,9.1,22,12z" />
				<path d="M6.3,13.3c0.5,0.5,1.1,1,1.6,1.6c0.3,0.3,0.4,0.6,0.4,1c0,0.5-0.3,0.9-0.7,1.1c-0.5,0.2-0.9,0.2-1.4-0.2 c-0.1,0-0.1-0.1-0.2-0.1c-1.2-1.2-2.4-2.4-3.6-3.6c-0.6-0.6-0.6-1.3,0-1.9c1.2-1.2,2.4-2.4,3.7-3.7C6.5,7,6.9,6.9,7.4,7.1 c0.5,0.1,0.8,0.5,0.9,1c0.1,0.4,0,0.8-0.3,1.1C7.4,9.6,7,10.1,6.5,10.6c0,0-0.1,0.1-0.2,0.1c0.1,0.1,0.2,0,0.2,0c3,0,6.1,0,9.1,0 c0.6,0,1.1,0.4,1.2,0.9c0.2,0.8-0.4,1.6-1.2,1.6c-3.1,0-6.1,0-9.2,0C6.5,13.3,6.5,13.3,6.3,13.3z" />
			</g>
		</svg>
	);
};

export default LogoutIcon;
