/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import India from '../../components/Maps/India';
import StateMap from '../../components/Maps/State';
import DistrictMap from '../../components/Maps/DistrictMap';
import { useGetStateAndDistrictDataMutation } from '../../redux/api/slices/dashboardApiSlice';
import Loading from '../../components/Loading';
import ErrorOccured from '../../components/ErrorOccured';
import { useGetFilters } from '../../hooks';
import { getFormattedDate } from '../../util/dateUtil';
import { API_DATE_FORMAT } from '../../util';

const IndiaMapReport = () => {
	const [getIndiaData, { isError, error }] =
		useGetStateAndDistrictDataMutation();

	const filters = useGetFilters();

	const [IsState, setIsState] = useState(false);
	const [stateName, setStateName] = useState('');
	const [isDistrict, setIsDistrict] = useState('');
	const [selectedCompanyCropID, setSelectedCompanyCropID] = useState();
	const [coordinates, setCoordinates] = useState([0, 0]);
	const [stateId, setStateId] = useState('');
	const [districtDetails, setDistrictDetails] = useState({
		id: '',
		districtName: '',
		isActive: false,
		stateId: '',
	});

	const [data, setData] = useState({});
	const [stateIds, setStateIds] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [ranges, setRanges] = useState();

	useEffect(() => {
		isDistrict && setIsState(false);
	}, [isDistrict]);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCompanyCropID(filters.companyCropID);
			setRanges(filters.ranges);
		}
	}, [filters]);

	const getData = async (companyId) => {
		setLoading(true);
		try {
			if (ranges?.length) {
				const indiaData = await getIndiaData({
					companyId,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
				}).unwrap();
				if (indiaData && Object.keys(indiaData || {}).length) {
					const mapObject = {};
					const stateIdArray = Object.keys(indiaData).map((value) => {
						mapObject[value] = indiaData[value];
						return value;
					});
					setData(mapObject);
					setStateIds(stateIdArray);
				} else {
					setData({});
				}
			}
			setLoading(false);
		} catch (err) {
			setData({});
			setLoading(false);
		}
	};

	useEffect(() => {
		selectedCompanyCropID &&
			selectedCompanyCropID?.length &&
			getData(selectedCompanyCropID);
	}, [selectedCompanyCropID, ranges]);

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	return (
		<>
			{isLoading ? (
				<Loading fullScreenMode />
			) : (
				<div className="bg-[#4e6080] relative w-full h-screen">
					<div className="absolute top-0 left-0 z-10">
						{IsState || isDistrict ? (
							<div className="flex items-center mt-2 ml-2">
								<button
									type="button"
									onClick={() => {
										setIsDistrict(false);
										setIsState(false);
										setDistrictDetails({
											...districtDetails,
											id: '',
											isActive: false,
											districtName: '',
											stateId: '',
										});
									}}
									className="flex items-center w-9 h-9 justify-center bg-white p-2 text-white rounded-lg border border-gray-400"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-5 w-5"
										viewBox="0 0 24 24"
										fill="none"
										stroke="#4EAA6F"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path d="M3 9l9-7 9 7v8a2 2 0 0 1-2 2h-2v-6a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v6H5a2 2 0 0 1-2-2z" />
										<polyline points="9 22 9 12 15 12 15 22" />
									</svg>
								</button>
								{isDistrict ? (
									<button
										type="button"
										onClick={() => {
											setIsDistrict(false);
											setIsState(true);
											setDistrictDetails({
												...districtDetails,
												id: '',
												isActive: false,
												districtName: '',
												stateId: '',
											});
										}}
										className="flex items-center w-9 h-9 justify-center bg-white p-2 text-white rounded-lg border border-gray-400 ml-1"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-5 w-5"
											fill="none"
											viewBox="0 0 24 24"
											stroke="#4EAA6F"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M15 19l-7-7 7-7"
											/>
										</svg>
									</button>
								) : null}
							</div>
						) : null}
					</div>
					{IsState ? (
						<StateMap
							stateName={stateName}
							setCoordinates={setCoordinates}
							setIsDistrict={setIsDistrict}
							stateId={stateId}
							districtDetails={districtDetails}
							setDistrictDetails={setDistrictDetails}
							totalData={data}
							availableStateIds={stateIds}
						/>
					) : isDistrict ? (
						<DistrictMap
							coordinates={coordinates}
							districtDetails={districtDetails}
							totalData={data}
						/>
					) : (
						<India
							setIsState={setIsState}
							setStateName={setStateName}
							setStateId={setStateId}
							totalData={data}
							availableStateIds={stateIds}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default IndiaMapReport;
