/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
// src/components/ForgotPassword.js

import React, { useState, useRef } from 'react';
import { useForgotPasswordMutation } from '../redux/api/slices/userApiSlice';
import ResetPassword from './ResetPassword';
import { BighaatSvgLogo } from '../icons';
import Loading from '../components/Loading';

const ForgotPassword = () => {
	const [forgot] = useForgotPasswordMutation();
	const [userName, setUserName] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [isLoading,setIsLoading]=useState(false);
	const fieldRef = useRef();
	const [showResetPassword, setShowResetPassword] = useState(false);
	const handleForgotPasswordFormSubmit = async (e) => {
		e.preventDefault();
		
		if (userName) {
			setIsLoading(true);
			try {
				const result = await forgot({
					userName,
					changeType: 'FORGOT',
				}).unwrap();
				if (
					result.status ===
					'Please use the code sent to your registered email to reset your password'
				) {
					setShowResetPassword(true);
				} else {
					setErrMsg(result.status);
				}
				setIsLoading(false);
			} catch (error) {
				setErrMsg(error?.data);
				setIsLoading(false);
				console.error('Something went wrong!! error::', error);
			}
		}
	};

	if (isLoading) {
		return <Loading fullScreenMode color="green" />;
	}
	return (
		<section className="w-full min-h-screen overflow-hidden bg-gradient-to-tr from-primary via-primary to-gradientEndGreen flex flex-row px-10">
			<div className="w-1/2 flex flex-col m-auto items-end">
				<h3 className="text-3xl font-medium text-white absolute">Powered by</h3>
				<div className="flex flex-row">
					<BighaatSvgLogo />
					{/* <h3 className="text-3xl font-medium text-white pb-4">.com</h3> */}
				</div>
				{/* <h3 className="text-2xl font-medium text-white pl-44 pb-4 tracking-widest">
					ONE STOP AGRO STORE
				</h3> */}
			</div>
			<div className="w-1/2 flex justify-center items-center">
				<div className="w-80 px-6 py-8 bg-white border-t rounded-2xl shadow-lg lg:max-w-md">
					{!showResetPassword && (
						<>
							<h1 className="text-2xl font-semibold pb-6 text-green-500">
								Forgot Password
							</h1>
							<form className="mt-4" onSubmit={handleForgotPasswordFormSubmit}>
								{errMsg && <p className="errMsg">{errMsg}</p>}
								<div>
									<label
										htmlFor="username"
										className="block text-sm text-gray-800"
									>
										Email
										<input
											type="email"
											id="username"
											className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
											ref={fieldRef}
											autoComplete="off"
											onChange={(e) => setUserName(e.target.value)}
											value={userName}
											required
										/>
									</label>
								</div>
								<div className="mt-6">
									<div className="mt-8">
										<button
											type="submit"
											className="w-full px-4 py-2 text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-secondary tracking-wider"
										>
											Get Code to Email
										</button>
									</div>
								</div>
							</form>
						</>
					)}
					{showResetPassword && <ResetPassword userName={userName} />}
				</div>
			</div>
		</section>
	);
};

export default ForgotPassword;
