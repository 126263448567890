import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

const NavMenu = ({
	className = '',
	btnChildren,
	btnClassName = '',
	itemsClassName = '',
	navMenuItems = [],
}) => (
	<Menu as="div" className={`123relative ${className}`}>
		<Menu.Button
			className={`inline-flex justify-center items-center w-full ${btnClassName}`}
		>
			{btnChildren && btnChildren}
		</Menu.Button>

		<Transition
			as={Fragment}
			enter="transition ease-in duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-out duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<Menu.Items
				className={`absolute w-56 rounded-sm bg-white shadow-lg border border-sfBorder transform translate-y-0 ${itemsClassName}`}
			>
				<div className="overflow-hidden">
					{navMenuItems.map((item) => (
						<Menu.Item key={item?.id}>
							{({ active }) => (
								<button
									type="button"
									className={`${
										active ? 'bg-sideMenuHoverColor1 text-white' : 'text-black'
									} group flex w-full items-center px-3 py-2 text-sm font-normal text-left ${
										item?.btnClassName
									}`}
									onClick={() =>
										(item?.onClickAction && item?.onClickAction()) || {}
									}
								>
									{active
										? item?.activeIcon && item?.activeIcon
										: item?.inActiveIcon && item?.inActiveIcon}
									{item?.label}
								</button>
							)}
						</Menu.Item>
					))}
				</div>
			</Menu.Items>
		</Transition>
	</Menu>
);

export default NavMenu;
