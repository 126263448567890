import React, { useEffect, useState } from 'react';

const RadioButton = ({
	radioButtons = [],
	onChangeValue = false,
	defaultActiveRadioValue = false,
}) => {
	const [selectedOption, setSelectedOption] = useState('');

	useEffect(() => {
		if (defaultActiveRadioValue) {
			setSelectedOption(defaultActiveRadioValue);
		}
	}, [defaultActiveRadioValue]);

	const handleOptionChange = (event, radioBtn) => {
		setSelectedOption(event.target.value);
		// eslint-disable-next-line no-unused-expressions
		onChangeValue && onChangeValue(event.target.value, radioBtn);
	};

	return (
		<div className="flex items-center justify-end gap-2">
			{radioButtons?.length
				? radioButtons?.map((radioBtn) => (
						// eslint-disable-next-line jsx-a11y/label-has-associated-control
						<label key={radioBtn?.value} className="flex gap-1 items-center">
							<input
								type="radio"
								className="h-4 w-4"
								value={radioBtn?.value}
								checked={selectedOption === radioBtn?.value}
								onChange={(e) => handleOptionChange(e, radioBtn)}
							/>
							{radioBtn?.label}
						</label>
				  ))
				: null}
		</div>
	);
};

export default RadioButton;
