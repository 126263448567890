import { createEntityAdapter } from '@reduxjs/toolkit';
import apiSlice from '../apiSlice';

const companyAdapter = createEntityAdapter({});

// eslint-disable-next-line no-unused-vars
const initialState = companyAdapter.getInitialState();

export const companyApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCompanyInfo: builder.query({
			query: ({ companyId }) =>
				`dashboard/get-company-info?companyId=${companyId}`,
		}),

		getFarmersList: builder.query({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `company/get-farmers-list-by-companyId?api-version=1.0`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
				},
				method: 'POST',
			}),
		}),
		// getCompanyCropsNVarieties: builder.query({
		// 	query: (companyId) =>
		// 		`dashboard/get-crop-varieties-by-companyId?companyId=${companyId}`,
		// }),
		// getFarmersList: builder.query({
		// 	query: ({ companyCropId }) =>
		// 		`company/get-farmers-list-by-companyId?companyCropId=${companyCropId}`,
		// }),
		getCompanyFarmers: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-company-farmers-by-companyId?api-version=1.0`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCompanyCropsNVarieties: builder.query({
			query: (companyId) =>
				`dashboard/get-crop-varieties-by-companyId?companyId=${companyId}`,
		}),
		getCompanyStates: builder.query({
			query: (companyId) =>
				`dashboard/get-States-by-companyCropId?companyCropId=${companyId}`,
		}),

		getCompanyDistricts: builder.query({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				fromDate,
				toDate,
				farmerCodes = [],
			}) => ({
				url: `dashboard/get-districts-by-companyCropId?api-version=1.0`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		// getCompanyDistricts: builder.query({
		// 	query: ({ companyId, stateId }) =>
		// 		`dashboard/get-districts-by-companyCropId?companyCropId=${companyId}&&stateId=${stateId}&api-version=1.0`,
		// }),
		getCompanyExecutives: builder.query({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-company-executives-by-companyId?api-version=1.0`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
				},
				method: 'POST',
			}),
		}),
		getCompanyDistrictWiseStageImages: builder.query({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				farmerCodes = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-district-stage-images-report?api-version=1.0`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					farmerCodes,
					fromDate,
					toDate,
				},
				method: 'POST',
			}),
		}),
		getCompanyFormersStateDistrict: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate = '',
				toDate = '',
			}) => ({
				url: `dashboard/get-company-farmers-by-companyId?api-version=1.0`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCompanyByID: builder.query({
			query: ({ userId = '' }) => ({
				url: `company/get-companies-list?userId=${userId}`,
				method: 'GET',
			}),
		}),
		getExecutiveActivitiesByID: builder.query({
			query: ({ fromDate = '', toDate = '', executiveId = '' }) => ({
				url: `dashboard/get-executive-activities-list?executiveId=${executiveId}&toDate=${toDate}&fromDate=${fromDate}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetCompanyInfoQuery,
	useGetCompanyFarmersQuery,
	useGetCompanyCropsNVarietiesQuery,
	useGetCompanyExecutivesQuery,
	useGetCompanyStatesQuery,
	useGetCompanyDistrictsQuery,
	useGetCompanyFormersStateDistrictQuery,
	useGetCompanyByIDQuery,
	useGetExecutiveActivitiesByIDQuery,
	useGetCompanyDistrictWiseStageImagesQuery,
	useGetFarmersListQuery,
} = companyApiSlice;
