/* eslint-disable react/button-has-type */
import React from 'react';

const Popup = ({ isOpen, message, onClose }) => (
	<div
		className={`fixed inset-0 flex items-center justify-center z-50 h-screen ${
			isOpen ? 'block' : 'hidden'
		}`}
	>
		<div className="bg-white flex flex-col rounded-lg p-5 shadow-lg">
			<h2 className="text-2xl font-semibold text-black text-center">
				Password Reset
			</h2>
			<p className="text-sm font-normal text-black text-center mt-4">
				{message}
			</p>
			<button
				className="bg-sideMenuColor1 w-full mt-6 hover:bg-sideMenuHoverColor2 uppercase text-sm font-medium text-white text-center py-2 px-3 rounded-md"
				onClick={onClose}
			>
				Close
			</button>
		</div>
	</div>
);

export default Popup;
