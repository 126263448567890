/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorOccured from '../../components/ErrorOccured';
import Loading from '../../components/Loading';
import NoDataFound from '../../components/NoData';
import Table from '../../components/Table';
import { useGetFilters } from '../../hooks';
import { useGetDiseaseReportDataQuery } from '../../redux/api/slices/dashboardApiSlice';
import { API_DATE_FORMAT, exportToExcel, prepareExportData } from '../../util';
import { getCurrentFormattedDate, getFormattedDate } from '../../util/dateUtil';
import { decodeChemical } from '../../components/DashboardCharts/util';
import { useGetProductSamplingActivityReportQuery } from '../../redux/api/slices/dashboardApiSlice';

const ProductSamplingActivityReport = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const filters = useGetFilters();
	const [pageSize, setPageSize] = useState(20);
	const navigation = useNavigate();
	const [selectedFarmer, setSelectedFarmer] = useState();

	const {
		data: ProductSamplingActivityReportData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetProductSamplingActivityReportQuery(
		selectedCrop?.length &&
			ranges?.length &&
			ranges[0]?.startDate &&
			ranges[0]?.endDate
			? {
					farmerCode: selectedFarmer?.id,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety ? selectedCropVariety : [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.startDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
					toDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.endDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setSelectedFarmer(filters.farmer);
		}
	}, [filters]);

	const columns = useMemo(
		() => [
			{
				Header: 'Company Name',
				accessor: 'companyName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.companyName || ''}`}</p>
				),
			},
			{
				Header: 'Farmer Code',
				accessor: 'farmercode',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.farmercode || ''}`}</p>
				),
			},
			{
				Header: 'Farmer Name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.firstName || ''}`}</p>
				),
			},
			{
				Header: 'Executive Name',
				accessor: 'executiveName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.executiveName || ''}`}</p>
				),
			},
			{
				Header: 'Crop Name',
				accessor: 'cropName',
				Cell: ({ row }) => <p>{row?.original?.cropName}</p>,
			},
			{
				Header: 'Crop Variety',
				accessor: 'varietiName',
				Cell: ({ row }) => <p>{row?.original?.varietiName}</p>,
			},
			{
				Header: 'District Name',
				accessor: 'district',
				Cell: ({ row }) => <p>{row?.original?.district}</p>,
			},
			{
				Header: 'Village Name',
				accessor: 'village',
				Cell: ({ row }) => <p>{row?.original?.village}</p>,
			},
			{
				Header: 'Sampling Date',
				accessor: 'samplingdate',
				Cell: ({ row }) => (
					<p>
						{getFormattedDate({
							date: addDays(new Date(row?.original?.samplingdate), 1),
							reqFormat: API_DATE_FORMAT,
						})}
					</p>
				),
			},
			{
				Header: 'Sample Size',
				accessor: 'sampleSize',
				Cell: ({ row }) => <p>{row?.original?.sampleSize || ''}</p>,
				width: 200,
			},
			{
				Header: 'Grade oF the Sample',
				accessor: 'gradeofthesample',
				Cell: ({ row }) => <p>{row?.original?.gradeofthesample || ''}</p>,
				width: 200,
			},
			{
				Header: 'Condition of the sample collected',
				accessor: 'conditionofthesamplecollected',
				Cell: ({ row }) => (
					<p>{row?.original?.conditionofthesamplecollected || ''}</p>
				),
				width: 200,
			},
			{
				Header: 'harvest Number',
				accessor: 'harvestnumber',
				Cell: ({ row }) => <p>{row?.original?.harvestnumber || ''}</p>,
				width: 200,
			},
			{
				Header: 'Sampling Done by',
				accessor: 'samplingdoneby',
				Cell: ({ row }) => <p>{row?.original?.samplingdoneby || ''}</p>,
				width: 200,
			},
		],
		[]
	);

	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: ProductSamplingActivityReportData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		console.log('exportData => ', exportData);
		const sheetData = exportData.map((chemcData, index) => ({
			'Sl. No': index + 1,
			'Company Name': chemcData?.companyName || '',
			'Farmer Code': chemcData?.farmercode || '',
			'Farmer Name': chemcData?.firstName || '',
			'Executive Name': chemcData?.executiveName || '',
			'Crop Name': chemcData?.cropName || '',
			'Crop Variety': chemcData?.varietiName || '',
			'District Name': chemcData?.district || '',
			Village: chemcData?.village,
			'Sampling Date': `${chemcData?.samplingdate || ''}`,
			SampleSize: chemcData?.sampleSize || '',
			GradeoftheSample: chemcData?.gradeofthesample || '',
			Conditionofthesamplecollected:
				chemcData?.conditionofthesamplecollected || '',
			harvestnumber: chemcData?.harvestnumber || '',
			samplingdoneby: chemcData?.samplingdoneby || '',
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `ProductSamplingActivityReport.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{/* {diseaseName ? ( */}
			{/* <button
				type="button"
				onClick={() => {
					navigation(`/cId/${cId}/dashboard`);
				}}
				className="flex items-center w-9 h-9 justify-center bg-white p-2 text-white rounded-lg border border-gray-400 ml-1"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					fill="none"
					viewBox="0 0 24 24"
					stroke="#4EAA6F"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</button> */}
			{/* ) : null} */}
			{ProductSamplingActivityReportData?.length ? (
				<Table
					data={ProductSamplingActivityReportData}
					columns={columns}
					className="overflow-x-hidden w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-1.5 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title={`Product Sampling Activity Report`}
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion
					defaultPageSize={pageSize}
				/>
			) : (
				<>
					<h2>Product Sampling Activity Report</h2>
					<NoDataFound />
				</>
			)}
		</div>
	);
};

export default ProductSamplingActivityReport;
