import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Login from './pages/Login';
import 'leaflet/dist/leaflet.css';
import './styles.css';
import RequireAuth from './components/RequireAuth';
import Dashboard from './pages/Dashboard';
import Executives from './pages/Executives';
import Farmers from './pages/Farmers';
import { loadAuthFromLocalStorage } from './redux/api/slices/userApiSlice';
import CropStagesReport from './pages/reports/CropStagesReport';
import ExpectedHarvestReport from './pages/reports/ExpectedHarvestReport';
import ChemicalCompositionReport from './pages/reports/ChemicalCompositionReport';
import CompanyUserProfile from './pages/CompanyUserProfile';
import IPMAdherenceReport from './pages/reports/IPMAdherenceReport';
import IndiaMapReport from './pages/reports/IndiaMapReport';
import ExecutivesActivity from './pages/ExecutivesActivity';
import ChemicalCompositionReportByChemicalName from './pages/reports/ChemicalCompositionReportByChemicalName';
import DiseaseReport from './pages/reports/DiseaseReport';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import DistrictAndStageWiseImages from './pages/DistrictAndStageWiseImages';
import FarmLandDetailsActivityReport from './pages/reports/FarmLandDetailsActivityReport';
import NurseryDetailsActivityReport from './pages/reports/NurseryDetailsActivityReport';
import MainFieldActivityReport from './pages/reports/MainFieldActivityReport';
import NutrientActivityReport from './pages/reports/NutrientActivityReport';
import ProductSamplingActivityReport from './pages/reports/ProductSamplingActivityReport';
import PostHarvestActivityReport from './pages/reports/PostHarvestActivityReport';
import IrrigationActivityReport from './pages/reports/IrrigationActivityReport';
import PestActivityReport from './pages/reports/PestActivityReport';
const App = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(loadAuthFromLocalStorage());
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="login" element={<Login />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="cid" element={<RequireAuth />}>
					<Route path=":cId/dashboard" element={<Dashboard />} />
					<Route path=":cId/executives" element={<Executives />} />
					<Route
						path=":cId/executives/:executiveId"
						element={<ExecutivesActivity />}
					/>
					<Route path=":cId/farmers" element={<Farmers />} />
					<Route
						path=":cId/districtWiseStageImages"
						element={<DistrictAndStageWiseImages />}
					/>
					<Route path=":cId/summary" element={<IndiaMapReport />} />
					<Route path=":cId/reports">
						<Route path="crop-stages-report" element={<CropStagesReport />} />
						<Route
							path="expected-harvest-report"
							element={<ExpectedHarvestReport />}
						/>
						<Route
							path="chemical-composition-report"
							element={<ChemicalCompositionReport />}
						/>
						<Route
							path="chemical-composition-report/:chemicalName"
							element={<ChemicalCompositionReportByChemicalName />}
						/>
						<Route path="disease-report" element={<DiseaseReport />} />
						<Route
							path="disease-report/:diseaseName"
							element={<DiseaseReport />}
						/>
						<Route
							path="ipm-adherence-report"
							element={<IPMAdherenceReport />}
						/>
						<Route
							path="farm-land-details-activity-report"
							element={<FarmLandDetailsActivityReport />}
						/>
						<Route
							path="nursery-details-activity-report"
							element={<NurseryDetailsActivityReport />}
						/>
						<Route
							path="main-field-activity-report"
							element={<MainFieldActivityReport />}
						/>
						<Route
							path="nutrient-activity-report"
							element={<NutrientActivityReport />}
						/>
						<Route
							path="product-sampling-activity-report"
							element={<ProductSamplingActivityReport />}
						/>
						<Route
							path="post-harvest-activity-report"
							element={<PostHarvestActivityReport />}
						/>
						<Route
							path="irrigation-activity-report"
							element={<IrrigationActivityReport />}
						/>
						<Route
							path="pest-activity-report"
							element={<PestActivityReport />}
						/>
					</Route>

					<Route path=":cId/user-profile">
						<Route path=":userId" element={<CompanyUserProfile />} />
					</Route>
					<Route path=":cId/change-password">
						<Route path=":userId" element={<ChangePassword />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
