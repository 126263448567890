import React from 'react';

const FarmerIcon = (props) => {
	const { height = 20, width = 20, fill = '#fa9527', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${height} ${width}`}
			className={className}
		>
			<g>
				<rect width={20} height={20} fill="none" />
				<g transform="translate(4 2)">
					<path
						d="M-999.855,75.142a7.046,7.046,0,0,0-2.573-5.391,3.752,3.752,0,0,1-3.028,1.384,4.08,4.08,0,0,1-3.037-1.384,7.037,7.037,0,0,0-2.593,5.349c0,3.042,3.267,2.937,3.267,2.937h5.309A2.6,2.6,0,0,0-999.855,75.142Z"
						transform="translate(1011.087 -62.041)"
						fill={fill}
					/>
					<path
						d="M-977.653,30.824c-1.571,1.121-2.283-.132-2.283-.132a3.405,3.405,0,0,1-3.812.242,3.772,3.772,0,0,0,.67,2.682,2.8,2.8,0,0,0,2.4,1.1,2.9,2.9,0,0,0,2.339-1.1A3.742,3.742,0,0,0-977.653,30.824Z"
						transform="translate(986.32 -26.33)"
						fill={fill}
					/>
					<path
						d="M-958.575-17.407a6.867,6.867,0,0,0,1.176.77l.034.015a4.305,4.305,0,0,0,.687-1.244c-.055-.015-.1-.03-.153-.043a4.08,4.08,0,0,0-.758-.1.614.614,0,0,1-.062-.009h-.585a.162.162,0,0,1-.03.008c-.189.012-.378.023-.568.036-.081.005-.161.016-.251.025.016.024.027.042.039.058A2.789,2.789,0,0,0-958.575-17.407Z"
						transform="translate(963.749 18.015)"
						fill={fill}
					/>
					<path
						d="M-984.946-12.927a4.185,4.185,0,0,1-2.639,1.025,5.485,5.485,0,0,1-1.269-.1l-.14-.026c.006.022.009.037.014.051a1.219,1.219,0,0,0,.134.265,1.782,1.782,0,0,0,.547.528,2.734,2.734,0,0,0,.891.35,4.429,4.429,0,0,0,.557.082,3.115,3.115,0,0,0,.329.014,3.391,3.391,0,0,0,.491-.052,4.042,4.042,0,0,0,1.155-.369,4.3,4.3,0,0,0,1.386-1.108,5.867,5.867,0,0,0,.909-1.512,6.019,6.019,0,0,0,.264-.763.072.072,0,0,0-.026-.087c-.074-.057-.144-.122-.22-.175a3.5,3.5,0,0,0-.8-.4.34.34,0,0,0-.043-.01c-.007.015-.013.027-.017.04a5.105,5.105,0,0,1-.358.788A5.238,5.238,0,0,1-984.946-12.927Z"
						transform="translate(990.915 15.478)"
						fill={fill}
					/>
					<path
						d="M-935.555-1.563l-.047.035.021.012.122.038a2.431,2.431,0,0,0,.887.1,1.486,1.486,0,0,0,1-.493,2.159,2.159,0,0,0,.432-.762,1.948,1.948,0,0,0,.058-1.141,1.693,1.693,0,0,0-.4-.742c-.013-.014-.027-.026-.044-.042-.006.021-.01.035-.013.049a3.926,3.926,0,0,1-.476,1.256A5.549,5.549,0,0,1-935.555-1.563Z"
						transform="translate(942.368 5.805)"
						fill={fill}
					/>
					<path
						d="M-986.315-8.086a3.841,3.841,0,0,0,1.056.411,2.11,2.11,0,0,0,.881.015,1.291,1.291,0,0,0,.406-.154c.017-.01.033-.023.056-.039L-984-7.881a5.648,5.648,0,0,1-1.472-.762,3.583,3.583,0,0,1-.841-.829.082.082,0,0,0-.015-.013l-.781.879.033.027A6.511,6.511,0,0,0-986.315-8.086Z"
						transform="translate(989.249 10.277)"
						fill={fill}
					/>
					<path
						d="M-974.279-15.179a8.827,8.827,0,0,0,1.522.9.673.673,0,0,0,.12.042.721.721,0,0,0,.7-.169c.014-.013.027-.028.044-.045a4.367,4.367,0,0,1-1.836-1.588,4.151,4.151,0,0,0-1.061.409l.059.063A3.8,3.8,0,0,0-974.279-15.179Z"
						transform="translate(978.037 16.224)"
						fill={fill}
					/>
					<path
						d="M-989.935,5.219a3.558,3.558,0,0,0,.855.121,2.306,2.306,0,0,0,.7-.089c.042-.013.083-.028.124-.043a.122.122,0,0,0-.046-.021c-.162-.046-.325-.088-.485-.138a3.3,3.3,0,0,1-.682-.292.946.946,0,0,1-.4-.386c-.011.018-.019.027-.024.038a1.127,1.127,0,0,0-.109.742A.077.077,0,0,0-989.935,5.219Z"
						transform="translate(991.916 -2.294)"
						fill={fill}
					/>
				</g>
			</g>
		</svg>
	);
};

export default FarmerIcon;
