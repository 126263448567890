/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	Sector,
	Tooltip,
} from 'recharts';
import exportFromJSON from 'export-from-json';
import {
	useGetCropVarietiesChartDataQuery,
	useGetCropVarietiesExcelDataMutation,
} from '../../redux/api/slices/dashboardApiSlice';
import { renderAcres } from '../../util';
import ErrorOccured from '../ErrorOccured';
import Loading from '../Loading';
import Table from '../Table';
import {
	chartHeight,
	chartWidth,
	CustomToolTip,
	DashboardChartHeader,
	getKeyFromText,
	LegendCellRenderer,
} from './util';

const COLORS = [
	{ tailwindColorName: 'bg-DarkPurple', colorHex: '#614E86' },
	{ tailwindColorName: 'bg-MangoTango', colorHex: '#FF8042' },
	{ tailwindColorName: 'bg-CaribbeanGreen', colorHex: '#C40025' },
	{ tailwindColorName: 'bg-OrchidRed', colorHex: '#AD878D' },
	{ tailwindColorName: 'bg-Bistre', colorHex: '#3C2317' },
];
const RADIAN = Math.PI / 180;

const CropVarietiesChart = ({
	farmerCodes,
	companyCropId,
	cropVariety,
	states = [],
	districts = [],
	fromDate,
	toDate,
}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [chartData, setChartData] = useState([]);
	const [customLoading, setCustomLoading] = useState(true);

	const [getCropVarietiesExcelData] = useGetCropVarietiesExcelDataMutation();

	const {
		data: cropVarietiesChartData,
		isLoading,
		isError,
		error,
		isFetching,
	} = useGetCropVarietiesChartDataQuery(
		companyCropId?.length && fromDate && toDate
			? {
					farmerCodes,
					companyCropId,
					cropVarietyId: cropVariety,
					states,
					districts,
					fromDate,
					toDate,
			  }
			: skipToken
	);

	useEffect(() => {
		if (isFetching) {
			setCustomLoading(true);
		}
	}, [isFetching]);

	useEffect(() => {
		if (cropVarietiesChartData?.length) {
			const mData = cropVarietiesChartData.map((item, index) => ({
				id: item?.id && getKeyFromText({ text: item?.id }),
				name: item?.id,
				value: item?.count && parseFloat(item.count),
				color: COLORS[index % COLORS.length],
			}));
			setChartData(mData);
			setActiveIndex(0);
			setCustomLoading(false);
		} else {
			setChartData([]);
			setCustomLoading(false);
		}
	}, [cropVarietiesChartData]);

	const columns = useMemo(
		() => [
			{
				Header: 'Crop Variety',
				accessor: 'name',
				Cell: ({ row }) => <LegendCellRenderer row={row} />,
				width: 150,
			},
			{
				Header: 'Acres',
				accessor: 'value',
				Cell: ({ row }) => (
					<p>
						{renderAcres({
							val: row?.original?.value || 0,
						})}
					</p>
				),
				width: 100,
			},
		],
		[]
	);

	const downloadExcelData = async () => {
		try {
			const result = await getCropVarietiesExcelData({
				farmerCodes,
				companyCropId,
				cropVarietyId: cropVariety,
				states,
				districts,
				fromDate,
				toDate,
			}).unwrap();
			if (result && result?.length) {
				const finalResult = result?.map((item) => ({
					'Farmer Name': item?.firstName || '',
					'Cultivated Area': item?.cultivatedArea || '',
					Crop: item?.cropName || '',
					'Crop Variety': item?.cropVarietyName || '',
					Mandal: item?.mandal || '',
					Village: item?.village || '',
					District: item?.district || '',
					State: item?.state || '',
					Postcode: item?.postcode || '',
				}));
				const exportData = {
					data: finalResult,
					fileName: 'CropVarieties',
					exportType: 'csv',
				};
				exportFromJSON(exportData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const dropdownBtns = [
		{
			label: 'Export To Excel',
			action: async () => {
				await downloadExcelData();
			},
			isDisabled: !chartData.length,
		},
	];

	const renderActiveShape = ({
		cx,
		cy,
		innerRadius,
		outerRadius,
		startAngle,
		midAngle,
		endAngle,
		fill,
		percent,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<g>
				<text x={x} y={y} fill="white" textAnchor="middle">
					{`${
						(percent * 100).toFixed(2).endsWith('.00')
							? `${percent * 100 === 0 ? '' : `${(percent * 100).toFixed(0)}%`}`
							: `${(percent * 100).toFixed(2)}%`
					}`}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius + 4}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
			</g>
		);
	};

	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 1.25;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text className="text-xs" x={x - 15} y={y + 10} fill="black">
				{`${
					(percent * 100).toFixed(2).endsWith('.00')
						? `${percent * 100 === 0 ? '' : `${(percent * 100).toFixed(0)}%`}`
						: `${(percent * 100).toFixed(2)}%`
				}`}
			</text>
		);
	};

	return (
		<>
			<DashboardChartHeader
				text="Crop Varieties"
				subText="(Acres)"
				dropdownBtns={dropdownBtns}
			/>
			{isLoading || customLoading ? (
				<div className="card-center">
					<Loading />
				</div>
			) : isError ? (
				<div className="card-center">
					<ErrorOccured fullScreenMode msg={error} />
				</div>
			) : chartData.length ? (
				<ResponsiveContainer width="100%" height="100%" className="p-2.5">
					<PieChart height={chartHeight} width={chartWidth}>
						<Pie
							activeIndex={activeIndex}
							data={chartData}
							dataKey="value"
							cx="40%"
							cy="40%"
							activeShape={renderActiveShape}
							label={renderCustomizedLabel}
							outerRadius={100}
							minAngle={10}
						>
							{chartData.map((entry) => (
								<Cell key={entry.id} fill={entry?.color?.colorHex} />
							))}
						</Pie>
						<Legend
							className="hidden lg:block h-64 overflow-y-auto scrollbar ml-3"
							align="right"
							layout="vertical"
							verticalAlign="middle"
							wrapperStyle={{ right: '5%', top: '10%' }}
							content={
								<Table
									className=""
									data={chartData}
									columns={columns}
									theadClassName="w-full text-center text-sm font-semibold"
									tdClassName="text-xs font-normal"
								/>
							}
						/>
						<Tooltip content={<CustomToolTip unit="Acres" />} />
					</PieChart>
				</ResponsiveContainer>
			) : (
				<h3 className="card-center text-xl font-medium">No Data found</h3>
			)}
		</>
	);
};

export default CropVarietiesChart;
