/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ErrorOccured from '../components/ErrorOccured';
import Loading from '../components/Loading';
import { useAuth } from '../hooks';
import {
	useGetCompanyUserProfileInfoQuery,
	useResetPasswordMutation,
	setCredentials,
} from '../redux/api/slices/userApiSlice';
import Popup from '../components/Popup';

const ChangePassword = () => {
	const dispatch = useDispatch();
	const [reset] = useResetPasswordMutation();
	const [showPopup, setShowPopup] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const auth = useAuth();
	const navigate = useNavigate();

	const {
		data: userInfo,
		isLoading,
		isError,
		error,
	} = useGetCompanyUserProfileInfoQuery(auth.userId ? auth.userId : skipToken);
	const handleResetPassword = async (e) => {
		e.preventDefault();
		// Check if the new password and confirm password match
		if (newPassword !== confirmNewPassword) {
			setErrMsg("New password and confirm password don't match.");
			return;
		}

		// Check if the new password is the same as the current password
		if (newPassword === currentPassword) {
			setErrMsg('New password should be different from the current password.');
			return;
		}

		if (userInfo?.email && newPassword && currentPassword) {
			try {
				const result = await reset({
					Id: auth?.userId,
					otpCode: null,
					userName: userInfo?.email,
					oldPassword: currentPassword,
					newPassword,
					changeType: '',
				}).unwrap();
				if (result) {
					const { status } = result;
					if (status === 'Password changed successfully') setShowPopup(true);
					else setErrMsg(status);
				}
				setCurrentPassword('');
				setConfirmNewPassword('');
				setNewPassword('');
			} catch (err) {
				setErrMsg(err);
				console.error('Something went wrong!! error::', err);
			}
		}
	};
	const closePopup = () => {
		setShowPopup(false);
		dispatch(setCredentials({ user: null }));
		navigate(`/login`);
	};
	if (isLoading) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured msg={error} />;
	}

	if (showPopup) {
		return (
			<Popup
				message="Password changed successfully... Please login again"
				onClose={closePopup}
				isOpen={showPopup}
			/>
		);
	}

	return (
		<div className="min-h-screen flex items-center justify-center">
			<div className="bg-white p-5 rounded-lg shadow-lg w-full sm:w-96">
				<h2 className="text-2xl font-semibold text-black">Change Password</h2>
				<div className="mt-5">
					<label
						className="block text-disabledText text-sm font-normal mb-1.5"
						htmlFor="currentPassword"
					>
						Current Password
					</label>
					<input
						className="appearance-none border border-borderColor placeholder-gray-500 placeholder-opacity-25 rounded-md w-full py-2 px-3 text-black text-sm font-normal"
						id="currentPassword"
						type="password"
						placeholder="Current Password"
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
					/>
				</div>
				<div className="mt-5">
					<label
						className="block text-disabledText text-sm font-normal mb-1.5"
						htmlFor="newPassword"
					>
						New Password
					</label>
					<input
						className="appearance-none border border-borderColor placeholder-gray-500 placeholder-opacity-25 rounded-md w-full py-2 px-3 text-black text-sm font-normal"
						id="newPassword"
						type="password"
						placeholder="New Password"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
					/>
				</div>
				<div className="mt-5">
					<label
						className="block text-disabledText text-sm font-normal mb-1.5"
						htmlFor="confirmNewPassword"
					>
						Confirm New Password
					</label>
					<input
						className="appearance-none border border-borderColor rounded-md placeholder-gray-500 placeholder-opacity-25 w-full py-2 px-3 text-black text-sm font-normal"
						id="confirmNewPassword"
						type="password"
						placeholder="Confirm New Password"
						value={confirmNewPassword}
						onChange={(e) => setConfirmNewPassword(e.target.value)}
					/>
				</div>
				{errMsg && (
					<p className="text-xs font-medium text-RedOrange mt-0.5 py-0">
						{errMsg}
					</p>
				)}

				<button
					className="w-full px-4 py-2 mt-6 flex flex-row justify-center items-center uppercase rounded-lg bg-sideMenuColor1 text-sm font-medium text-white text-center hover:bg-sideMenuHoverColor2 tracking-wide"
					onClick={handleResetPassword}
				>
					Change Password
				</button>
			</div>
		</div>
	);
};

export default ChangePassword;
