/* API base urls */
export const apiBaseUrlsObj = {
	dev: 'https://farmertraceabilityapidev.devbh.in/farmer-traceability-service/api',
	qa: 'https://farmertraceabilityapi.qabh.in/farmer-traceability-service/api',
	beta: 'https://apibeta.bighaat.com/farmer-traceability-service/api',
	prod: 'https://api.bighaat.com/farmer-traceability-service/api',
};

/* 
	Change it when you are moving to resp. branch releases
	main branch - PROD
	beta-main - Beta 
	dev-main - Dev
*/
export const env = 'prod';

const mapBaseURL =
	env === 'beta' || env === 'prod'
		? 'https://bighaatprodblobv2.blob.core.windows.net/india-geojson-data/'
		: 'https://bighaatdevnewblob.blob.core.windows.net/india-geojson-data/';

const apiBaseUrl = apiBaseUrlsObj[env];

export { apiBaseUrl, mapBaseURL };
