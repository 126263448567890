import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../../util/envConfig';

const apiSlice = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: apiBaseUrl,
		prepareHeaders: async (headers, { getState }) => {
			headers.set('Content-Type', 'application/json');
			const token = getState()?.auth?.user?.accessToken;
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: () => ({}),
});

export default apiSlice;
