/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ErrorOccured from '../components/ErrorOccured';
import Loading from '../components/Loading';
import { SelectColumnFilter } from '../components/Table/Filters';
import Table from '../components/Table';
import { useGetCompanyExecutivesQuery } from '../redux/api/slices/companyApiSlice';
import { API_DATE_FORMAT, exportToExcel, prepareExportData } from '../util';
import { getCurrentFormattedDate, getFormattedDate } from '../util/dateUtil';
import { useGetFilters } from '../hooks';
import NoDataFound from '../components/NoData';

const Executives = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const [ranges, setRanges] = useState();
	const { cId } = useParams();
	const filters = useGetFilters();
	const {
		data: executivesData,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetCompanyExecutivesQuery(
		cId && selectedCrop && ranges?.length
			? {
					companyId: cId,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety ? selectedCropVariety : [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setRanges(filters.ranges);
		}
	}, [filters]);

	const columns = useMemo(
		() => [
			{
				Header: 'Executive Name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<Link
						className="hover:text-secondary"
						to={{
							pathname: `${row?.original?.executiveId}`,
						}}
						state={{
							executiveName: `${row?.original?.firstName || ''} ${
								row?.original?.lastName || ''
							} `,
						}}
					>
						<p className="text-left">{`${row?.original?.firstName || ''} ${
							row?.original?.lastName || ''
						} `}</p>
					</Link>
				),
				width: 200,
			},
			{
				Header: 'Cluster',
				accessor: 'clusterName',
				Cell: ({ row }) => <p>{row?.original?.clusterName}</p>,
				width: 200,
			},
			{
				Header: 'Village',
				accessor: 'villageName',
				Cell: ({ row }) => <p>{row?.original?.villageName}</p>,
				width: 200,
			},
			{
				Header: 'Crop Variety',
				accessor: 'cropVarietyName',
				Cell: ({ row }) => <p>{row?.original?.cropVarietyName}</p>,
				width: 180,
			},
			{
				Header: 'Status',
				accessor: 'executiveStatus',
				Cell: ({ row }) => (
					<div>
						{!row?.original?.executiveStatus ? (
							<p className="text-center text-sm font-normal text-disabledText">
								Active
							</p>
						) : (
							<p className="text-center text-sm font-normal text-disabledText">
								Inactive
							</p>
						)}
					</div>
				),
				width: 160,
				Filter: ({ column }) => {
					const options = [
						{
							label: 'Active',
							value: false,
						},
						{
							label: 'Inactive',
							value: true,
						},
					];
					return SelectColumnFilter({ column, options });
				},
				filter: 'exactTextCase',
			},
		],
		[]
	);

	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: executivesData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		const sheetData = exportData.map((executive, index) => ({
			'Sl. No': index + 1,
			'Executive Name': `${executive?.firstName} ${
				executive?.lastName ? executive?.lastName : ''
			}`,
			'Crop Variety': executive?.cropVarietyName,
			Village: executive?.villageName,
			'Cluster Name': executive?.clusterName,
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `Executives_${getCurrentFormattedDate({})}.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{executivesData?.length ? (
				<Table
					data={executivesData}
					columns={columns}
					className="w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-2 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title="Executives"
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion
					defaultPageSize={20}
				/>
			) : (
				<NoDataFound />
			)}
		</div>
	);
};

export default Executives;
