import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ChemicalCompositionChart from '../components/DashboardCharts/ChemicalCompositionChart';
import CropStagesPieChart from '../components/DashboardCharts/CropStagesPieChart';
import CropVarietiesChart from '../components/DashboardCharts/CropVarietiesChart';
import ExpectedHarvestChart from '../components/DashboardCharts/ExpectedHarvestChart';
import IPMAdherenceChart from '../components/DashboardCharts/IPMAdherenceChart';
import SowingWindowChart from '../components/DashboardCharts/SowingWindowChart';
import ErrorOccured from '../components/ErrorOccured';
import Loading from '../components/Loading';
import { useGetCompanyFormersStateDistrictQuery } from '../redux/api/slices/companyApiSlice';
import { API_DATE_FORMAT, renderAcres } from '../util';
import { useGetIPMNonIPMStatsQuery } from '../redux/api/slices/dashboardApiSlice';
import { CropsIcon, FarmerIcon } from '../icons';
import { getFormattedDate } from '../util/dateUtil';
import { useGetFilters } from '../hooks';
import GradeChart from '../components/DashboardCharts/GradeChart';
import DiseasesPieChart from '../components/DashboardCharts/DiseasePieChart';

const Dashboard = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedFarmer, setSelectedFarmer] = useState([]);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const { cId } = useParams();
	const navigate = useNavigate();
	const filters = useGetFilters();

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops || ['']);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setSelectedFarmer(filters.farmer);
		}
	}, [filters]);

	const {
		data: ipmNonIpmFarmersAcres,
		isLoading: ipmNonIpmFarmersAcresIsLoading,
		isError: ipmNonIpmFarmersAcresIsError,
		error: ipmNonIpmFarmersAcresError,
	} = useGetIPMNonIPMStatsQuery(
		selectedCrop?.length && ranges?.length
			? {
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety?.length
						? selectedCropVariety
						: [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
					farmerCodes: selectedFarmer,
			  }
			: skipToken
	);

	const {
		data: companyFarmersData,
		isLoading: companyFarmersDataIsLoading,
		isError: companyFarmersDataIsError,
		error: companyFarmersDataError,
	} = useGetCompanyFormersStateDistrictQuery(
		cId && selectedCrop?.length && ranges?.length
			? {
					farmerCodes: selectedFarmer,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety,
					states: selectedStates,
					districts: selectedDistricts,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
			  }
			: skipToken
	);

	return (
		<div className="mt-4">
			<div className="flex flex-col md:flex-row">
				<div className="w-full md:w-3/4 flex flex-col md:flex-row">
					<div className="card rounded-2xl h-48 md:h-52 md:w-1/2 bg-white shadow md:mr-2">
						<h3 className="text-base px-4 py-3 border-b border-gray-300 font-semibold">
							IPM
						</h3>
						<div className="flex flex-row justify-evenly py-3.5">
							{ipmNonIpmFarmersAcresIsLoading ? (
								<Loading />
							) : ipmNonIpmFarmersAcresIsError ? (
								<ErrorOccured msg={ipmNonIpmFarmersAcresError} />
							) : (
								<>
									<div className="h-32 md:h-32 w-32 md:w-44 bg-cardBg rounded-md mx-2 flex flex-col justify-center items-center">
										<div className="iconBgStyle mb-1.5">
											<FarmerIcon className="h-10 w-10" fill="#FA9527" />
										</div>
										<h3 className="text-sm font-semibold">Total Farmers</h3>
										<h5 className="text-base font-normal">
											{ipmNonIpmFarmersAcres?.ipm?.farmersCount || 0}
										</h5>
									</div>
									<div className="h-32 md:h-32 w-32 md:w-44 bg-cardBg rounded-md mx-2 flex flex-col justify-center items-center">
										<div className="iconBgStyle mb-1.5">
											<CropsIcon className="h-10 w-6" fill="#4EAA6F" />
										</div>
										<h3 className="text-sm font-semibold">Acres</h3>
										<h5 className="text-base font-normal">
											{renderAcres({
												val: ipmNonIpmFarmersAcres?.ipm?.totalAcres || 0,
											})}
										</h5>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="card rounded-2xl h-48 md:h-52 md:w-1/2 bg-white shadow md:ml-2 md:mr-0 mt-2 md:mt-0 mr-2">
						<h3 className="text-base px-4 py-3 border-b border-gray-200 font-semibold">
							Non-IPM
						</h3>
						<div className="flex flex-row justify-evenly py-3.5">
							{ipmNonIpmFarmersAcresIsLoading ? (
								<Loading />
							) : ipmNonIpmFarmersAcresIsError ? (
								<ErrorOccured msg={ipmNonIpmFarmersAcresError} />
							) : (
								<>
									<div className="h-32 md:h-32 w-32 md:w-44 bg-cardBg rounded-md mx-2 flex flex-col justify-center items-center">
										<div className="iconBgStyle mb-1.5">
											<FarmerIcon className="h-10 w-10" fill="#FA9527" />
										</div>
										<h3 className="text-sm font-semibold">Total Farmers</h3>
										<h5 className="text-base font-normal">
											{ipmNonIpmFarmersAcres?.nonIpm?.farmersCount || 0}
										</h5>
									</div>
									<div className="h-32 md:h-32 w-32 md:w-44 bg-cardBg rounded-md mx-2 flex flex-col justify-center items-center">
										<div className="iconBgStyle mb-1.5">
											<CropsIcon className="h-10 w-6" fill="#4EAA6F" />
										</div>
										<h3 className="text-sm font-semibold">Acres</h3>
										<h5 className="text-base font-normal">
											{renderAcres({
												val: ipmNonIpmFarmersAcres?.nonIpm?.totalAcres || 0,
											})}
										</h5>
									</div>
								</>
							)}
						</div>
					</div>
				</div>

				<div className="md:w-1/4 flex flex-col md:ml-4 mt-4 md:mt-0">
					<div
						className="card rounded-2xl h-24 mb-4 bg-white flex flex-row justify-center items-center shadow px-2 lg:px-4"
						role="button"
						onClick={() => navigate(`/cid/${cId}/farmers`)}
					>
						<div className="w-1/4">
							<div className="iconBgStyle h-14 w-14 bg-cardBg rounded-full">
								<FarmerIcon className="h-10 w-10" fill="#FA9527" />
							</div>
						</div>
						<div className="w-3/4 h-full flex flex-col justify-center ml-3 items-start">
							<h3 className="text-sm font-semibold">No. of Farmers</h3>
							{companyFarmersDataIsLoading ? (
								<Loading />
							) : companyFarmersDataIsError ? (
								<ErrorOccured msg={companyFarmersDataError} fullScreenMode />
							) : (
								<h5 className="text-base font-normal">
									{companyFarmersData?.length || 0}
								</h5>
							)}
						</div>
					</div>
					<div className="card rounded-2xl h-24 bg-white mb-4 flex flex-row justify-center items-center shadow px-2 lg:px-4">
						<div className="w-1/4">
							<div className="iconBgStyle h-14 w-14 bg-cardBg rounded-full">
								<CropsIcon className="h-10 w-6" fill="#4EAA6F" />
							</div>
						</div>
						<div className="w-3/4 h-full flex flex-col justify-center items-start ml-3">
							<h3 className="text-sm font-semibold">Total Acres</h3>
							<h5 className="text-base font-normal">
								{renderAcres({
									val:
										(ipmNonIpmFarmersAcres?.nonIpm?.totalAcres || 0) +
										(ipmNonIpmFarmersAcres?.ipm?.totalAcres || 0),
								})}
							</h5>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-5">
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<CropVarietiesChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<CropStagesPieChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<SowingWindowChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<ExpectedHarvestChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<ChemicalCompositionChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<IPMAdherenceChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<GradeChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
					<div className="card h-96 rounded-2xl bg-white shadow 123shadow-[0px_0px_12px_#ddd]">
						<DiseasesPieChart
							farmerCodes={selectedFarmer}
							companyCropId={selectedCrop.length ? selectedCrop : ['']}
							cropVariety={
								selectedCropVariety?.length ? selectedCropVariety : ['']
							}
							states={selectedStates?.length ? selectedStates : ['']}
							districts={selectedDistricts?.length ? selectedDistricts : ['']}
							fromDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.startDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
							toDate={
								ranges?.length &&
								getFormattedDate({
									date: new Date(ranges[0]?.endDate),
									reqFormat: API_DATE_FORMAT,
								})
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
