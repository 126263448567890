import React from 'react';

const ChevronLeftRight = (props) => {
	const {
		height = 24,
		width = 24,
		color = 'black',
		fill = 'none',
		strokeWidth = 1.5,
		className = '',
		direction = 'left',
	} = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			viewBox={`0 0 ${height} ${width}`}
			strokeWidth={strokeWidth}
			stroke={color}
			className={className}
		>
			{direction === 'left' ? (
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M15.75 19.5L8.25 12l7.5-7.5"
				/>
			) : (
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M8.25 4.5l7.5 7.5-7.5 7.5"
				/>
			)}
		</svg>
	);
};

export default ChevronLeftRight;
