/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorOccured from '../../components/ErrorOccured';
import Loading from '../../components/Loading';
import NoDataFound from '../../components/NoData';
import Table from '../../components/Table';
import { useGetFilters } from '../../hooks';
import { useGetChemicalCompositionReportDataQuery } from '../../redux/api/slices/dashboardApiSlice';
import {
	API_DATE_FORMAT,
	DEFAULT_GUID,
	exportToExcel,
	prepareExportData,
} from '../../util';
import { getCurrentFormattedDate, getFormattedDate } from '../../util/dateUtil';

const ChemicalCompositionReport = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const filters = useGetFilters();
	const [pageSize, setPageSize] = useState(20);

	const {
		data: chemicalCompositionReportData = [],
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetChemicalCompositionReportDataQuery(
		selectedCrop?.length &&
			ranges?.length &&
			ranges[0]?.startDate &&
			ranges[0]?.endDate
			? {
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety ? selectedCropVariety : [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.startDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
					toDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.endDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
		}
	}, [filters]);

	const columns = useMemo(
		() => [
			{
				Header: 'Farmer Name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.firstName || ''} ${
						row?.original?.lastName || ''
					} `}</p>
				),
			},
			{
				Header: 'Crop Variety',
				accessor: 'varietiName',
				Cell: ({ row }) => <p>{row?.original?.varietiName}</p>,
			},
			{
				Header: 'Chemical Name',
				accessor: 'chemicalName',
				Cell: ({ row }) => <p>{row?.original?.chemicalName}</p>,
			},
			{
				Header: 'Brand Name',
				accessor: 'tradeNameNCompany',
				Cell: ({ row }) => <p>{row?.original?.tradeNameNCompany}</p>,
			},
			{
				Header: 'Usage (count)',
				accessor: 'usage',
				Cell: ({ row }) => <p>{row?.original?.usage}</p>,
				width: 120,
			},
			{
				Header: 'Village',
				accessor: 'village',
				Cell: ({ row }) => <p>{row?.original?.village}</p>,
			},
			{
				Header: 'Mandal',
				accessor: 'mandal',
				Cell: ({ row }) => <p>{row?.original?.mandal}</p>,
			},
			{
				Header: 'District',
				accessor: 'district',
				Cell: ({ row }) => <p>{row?.original?.district}</p>,
			},
			{
				Header: 'State',
				accessor: 'state',
				Cell: ({ row }) => <p>{row?.original?.state}</p>,
			},
			{
				Header: 'Pincode',
				accessor: 'pincode',
				Cell: ({ row }) => <p>{row?.original?.pincode}</p>,
				width: 80,
			},
		],
		[]
	);

	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: chemicalCompositionReportData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});
		const sheetData = exportData.map((chemcData, index) => ({
			'Sl. No': index + 1,
			'Farmer Name': `${chemcData?.firstName} ${chemcData?.lastName || ''}`,
			Crop: chemcData?.cropName || '',
			'Crop Variety': chemcData?.varietiName || '',
			'Chemical Technical Name': chemcData?.chemicalName,
			'Brand Name': chemcData?.tradeNameNCompany,
			'Usage (count)': chemcData?.usage,
			Village: chemcData?.village,
			Mandal: chemcData?.mandal,
			District: chemcData?.district,
			State: chemcData?.state,
			Pincode: chemcData?.pincode,
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `Chemical_Composition_Report_${getCurrentFormattedDate(
					{}
				)}.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{chemicalCompositionReportData?.length ? (
				<Table
					data={chemicalCompositionReportData}
					columns={columns}
					className="overflow-x-hidden w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-1.5 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title="Chemical Composition Report"
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion={true}
					defaultPageSize={pageSize}
				/>
			) : (
				<>
					<h2>Chemical Composition Report</h2>
					<NoDataFound />
				</>
			)}
		</div>
	);
};

export default ChemicalCompositionReport;
