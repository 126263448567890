/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import {
	useFilters,
	useTable,
	useSortBy,
	useFlexLayout,
	usePagination,
} from 'react-table';
import { CaretUpDownIcon, ExportIcon } from '../../icons';
import Loading from '../Loading';
import { DefaultFilterForColumn } from './Filters';
import NoDataFound from '../NoData';

const Table = ({
	data = [],
	columns = [],
	className = '',
	theadClassName = '',
	trClassName = '',
	tdClassName = '',
	displayTotal = false,
	setFilters,
	isFilterable = false,
	setSortBy,
	initialState = {},
	title = '',
	displayExcelExport = false,
	handleExportToExcel,
	exportExcelBtnLabel = 'Export to Excel',
	isExportInprogress = false,
	isPaginagtion = false,
	defaultPageSize = 20,
}) => {
	useEffect(() => {
		setPageSize(defaultPageSize);
	}, []);
	const defaultColumn = React.useMemo(
		() => ({
			Filter: isFilterable ? DefaultFilterForColumn : [],
		}),
		[]
	);

	const mInitialState = React.useMemo(() => initialState, [initialState]);

	const filterTypes = React.useMemo(
		() => ({
			text: (rows, id, filterValue) =>
				rows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				}),
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		page,
		prepareRow,
		state,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			defaultColumn,
			filterTypes,
			mInitialState,
			manualPagination: false,
		},
		useFilters,
		useSortBy,
		useFlexLayout,
		usePagination
	);

	useEffect(() => {
		if (setFilters) {
			setFilters(state.filters);
		}
		if (setSortBy) {
			setSortBy(state.sortBy);
		}
	}, [state, setFilters]);

	return (
		<div>
			<div className="flex flex-row items-center justify-between">
				<div className="flex flex-row items-center">
					{title && (
						<h2 className="text-2xl font-normal text-primary">{title}</h2>
					)}
					{displayTotal && (
						<span className="text-xs font-medium text-black ml-1.5 mt-1">
							{data.length} records
						</span>
					)}
				</div>

				{displayExcelExport ? (
					<div className="w-auto px-2.5 py-1.5 flex flex-row justify-center items-center rounded-md bg-secondary text-sm font-medium text-white text-center ml-2 hover:bg-opacity-90">
						{isExportInprogress ? (
							<Loading className="h-5 w-5" color="white" />
						) : (
							<button
								type="button"
								className={`text-sm flex items-center justify-center ${
									!rows.length ? 'cursor-not-allowed' : 'cursor-pointer'
								}`}
								onClick={handleExportToExcel && handleExportToExcel}
								disabled={!rows.length}
							>
								<ExportIcon className="h-5 w-5 mr-1.5" color="white" />
								{exportExcelBtnLabel}
							</button>
						)}
					</div>
				) : null}
			</div>

			{/* {isPaginagtion && (
				<div className="flex flex-row items-center mt-7 justify-end">
					<button
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
						className="w-8 h-8 p-0.5 ml-0 flex justify-center items-center text-sm font-normal text-white bg-halfGreen border-0 rounded-l-md hover:bg-primary disabled:cursor-not-allowed"
					>
						<span className="sr-only">Previous</span>
						<svg
							aria-hidden="true"
							className="w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
					<div className="h-8 border border-borderColor bg-white p-1.5">
						<p className="text-sm font-normal text-disabledText text-center">
							Page
							<span className="text-sm font-medium text-black text-center ml-1">
								{pageIndex + 1} of {pageOptions.length}
							</span>
						</p>
					</div>
					<button
						onClick={() => nextPage()}
						disabled={!canNextPage}
						className="w-8 h-8 p-0.5 flex justify-center items-center text-sm font-normal text-white bg-halfGreen border-0 rounded-r-md hover:bg-primary disabled:cursor-not-allowed"
					>
						<span className="sr-only">Next</span>
						<svg
							aria-hidden="true"
							className="w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
				</div>
			)} */}

			<div className="w-full overflow-x-auto scrollbar scroll-smooth">
				<table {...getTableProps()} className={className}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									const {
										style,
										key,
										role,
										title: columnTitle,
										onClick,
										colSpan,
									} = column.getHeaderProps(column.getSortByToggleProps());
									return (
										<th
											className={`${theadClassName} ${column.className || ''}`}
											key={key}
											style={style}
											title={columnTitle}
											colSpan={colSpan}
										>
											<div
												className="flex flex-row items-center"
												role={role}
												onClick={onClick}
											>
												{column.render('Header')}
												<span>
													{column.isSorted ? (
														column.isSortedDesc ? (
															<CaretUpDownIcon
																className="h-4 w-4"
																color="#4EAA6F"
																down
															/>
														) : (
															<CaretUpDownIcon
																className="h-4 w-4"
																color="#4EAA6F"
																down={false}
															/>
														)
													) : (
														''
													)}
												</span>
											</div>

											<div>
												{column.canFilter ? column.render('Filter') : null}
											</div>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody className=" overflow-x-scroll" {...getTableBodyProps()}>
						{isPaginagtion ? (
							<>
								{page.length ? (
									page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()} className={trClassName}>
												{row.cells.map((cell) => {
													return (
														<td
															className={tdClassName}
															{...cell.getCellProps()}
														>
															{cell.render('Cell')}
														</td>
													);
												})}
											</tr>
										);
									})
								) : (
									<tr className="bg-[#F9F9F9]">
										<td>
											<NoDataFound isFullScreen={false} />
										</td>
									</tr>
								)}
							</>
						) : (
							<>
								{rows.length ? (
									rows.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()} className={trClassName}>
												{row.cells.map((cell) => (
													<td className={tdClassName} {...cell.getCellProps()}>
														{cell.render('Cell')}
													</td>
												))}
											</tr>
										);
									})
								) : (
									<tr className="bg-[#F9F9F9]">
										<td>
											<NoDataFound isFullScreen={false} />
										</td>
									</tr>
								)}
							</>
						)}
					</tbody>
				</table>
			</div>
			{isPaginagtion && (
				<div className="flex flex-row items-center mt-3 justify-end">
					<button
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
						className="w-8 h-8 p-0.5 ml-0 flex justify-center items-center text-sm font-normal text-white bg-halfGreen border-0 rounded-l-md hover:bg-primary disabled:cursor-not-allowed"
					>
						<span className="sr-only">Previous</span>
						<svg
							aria-hidden="true"
							className="w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
					<div className="h-8 border border-borderColor bg-white p-1.5">
						<p className="text-sm font-normal text-disabledText text-center">
							Page
							<span className="text-sm font-medium text-black text-center ml-1">
								{pageIndex + 1} of {pageOptions.length}
							</span>
						</p>
					</div>
					<button
						onClick={() => nextPage()}
						disabled={!canNextPage}
						className="w-8 h-8 p-0.5 flex justify-center items-center text-sm font-normal text-white bg-halfGreen border-0 rounded-r-md hover:bg-primary disabled:cursor-not-allowed"
					>
						<span className="sr-only">Next</span>
						<svg
							aria-hidden="true"
							className="w-4 h-4"
							fill="currentColor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
								clipRule="evenodd"
							></path>
						</svg>
					</button>
				</div>
			)}
		</div>
	);
};

export default Table;
