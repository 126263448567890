/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { mapBaseURL } from './envConfig';

/**
 * console.log(geoJson.poly.coordinates.flat(3))
 * centroid(geoJson.poly)
 *
 *
 */

export const removeDuplicates = (arr, objectKey) => {
	let newArray = [];
	let uniqueObject = {};

	for (let i in arr) {
		let objTitle = arr[i][objectKey];
		uniqueObject[objTitle] = arr[i];
	}
	for (let i in uniqueObject) {
		newArray.push(uniqueObject[i]);
	}
	return newArray;
};

export const statesBlobJsonMap = {
	'6203a5ee199fc3be0e45f039': 'andamannicobar',
	'6203a5ee199fc3be0e45f03a': 'andhrapradesh',
	'6203a5ee199fc3be0e45f03b': 'arunachalpradesh',
	'6203a5ee199fc3be0e45f03c': 'assam',
	'6203a5ee199fc3be0e45f03d': 'bihar',
	'6203a5ee199fc3be0e45f03e': 'chandigarh',
	'6203a5ee199fc3be0e45f03f': 'chhattisgarh',
	'620e0c7b7ba899379aadca20': 'daman_and_diu',
	'6203a5ee199fc3be0e45f040': 'delhi',
	'6203a5ee199fc3be0e45f041': 'goa',
	'6203a5ee199fc3be0e45f042': 'gujarat',
	'6203a5ee199fc3be0e45f043': 'haryana',
	'6203a5ee199fc3be0e45f044': 'himachalpradesh',
	'6203a5ee199fc3be0e45f045': 'jammukashmir',
	'6203a5ee199fc3be0e45f046': 'jharkhand',
	'6203a5ee199fc3be0e45f047': 'karnataka',
	'6203a5ee199fc3be0e45f048': 'kerala',
	'6203a5ee199fc3be0e45f04a': 'lakshadweep',
	'6203a5ee199fc3be0e45f04b': 'madhyapradesh',
	'6203a5ee199fc3be0e45f04c': 'maharashtra',
	'6203a5ee199fc3be0e45f04d': 'manipur',
	'6203a5ee199fc3be0e45f04e': 'meghalaya',
	'6203a5ee199fc3be0e45f04f': 'mizoram',
	'6203a5ee199fc3be0e45f050': 'nagaland',
	'6203a5ee199fc3be0e45f051': 'odisha',
	'6203a5ee199fc3be0e45f053': 'punjab',
	'6203a5ee199fc3be0e45f052': 'puducherry',
	'6203a5ee199fc3be0e45f054': 'rajasthan',
	'6203a5ee199fc3be0e45f055': 'sikkim',
	'6203a5ee199fc3be0e45f056': 'tamilnadu',
	'6203a5ee199fc3be0e45f057': 'telangana',
	'6203a5ee199fc3be0e45f059': 'tripura',
	'6203a5ee199fc3be0e45f05a': 'uttarakhand',
	'6203a5ee199fc3be0e45f05b': 'uttarpradesh',
	'6203a5ee199fc3be0e45f05c': 'westbengal',
};

export const getRandomHexColor = (opacity = 1.0) => {
	// Generate a random number between 0 and 16777215 (decimal equivalent of #FFFFFF)
	const randomColor = Math.floor(Math.random() * 16777215);

	// Convert the decimal color value to hex
	const hexColor = randomColor.toString(16).padStart(6, '0');

	// Append the opacity (alpha) value to the hex color
	const colorWithOpacity =
		hexColor +
		Math.round(opacity * 255)
			.toString(16)
			.padStart(2, '0');

	// Return the final hex color with opacity
	return `#${colorWithOpacity}`;
};

export const fetchIndiaMap = async (path, setData, setIsLoading, token) => {
	try {
		if (token) {
			const response = await fetch(`${mapBaseURL}${path}${token}`, {
				responseType: 'blob',
			});
			const blobData = await response.json();
			setData(blobData);
			setIsLoading(false);
		}
	} catch (error) {
		setIsLoading(false);
	}
};
