/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import Loading from '../Loading';
import { useGetFarmerDataForMapsMutation } from '../../redux/api/slices/dashboardApiSlice';
import NoDataFound from '../NoData';
import { CloseIcon } from '../../icons';
import { getDaysBetweenDates } from '../../util/dateUtil';
import { useGetFilters } from '../../hooks';

const MapPopUp = ({ geoTagId = '', districtDetails = {}, onClose }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [getFarmerDataForMaps] = useGetFarmerDataForMapsMutation();
	const [farmerDetails, setFarmerDetails] = useState({});
	const popupRef = useRef(null);
	const [selectedCompanyCropID, setSelectedCompanyCropID] = useState();
	const filters = useGetFilters();

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCompanyCropID(filters.companyCropID);
		}
	}, [filters]);

	const getFarmerDetails = async (district, farmerDetailsId, companyId) => {
		try {
			const { id, stateId } = district;

			const response = await getFarmerDataForMaps({
				farmDetailId: farmerDetailsId,
				companyId,
			}).unwrap();

			if (response && Object.keys(response || {})?.length) {
				const farmersData = response[stateId]?.mapDistricts[id]?.farmers || [];
				const farmDetails =
					response[stateId]?.mapDistricts[id]?.farmDetails || [];
				const executive = response[stateId]?.mapDistricts[id]?.executives || [];
				setFarmerDetails({
					farmerName: farmersData?.length
						? `${farmersData[0]?.contactDetails?.firstName} ${
								farmersData[0]?.contactDetails?.lastName || ''
						  }`
						: '',
					area: farmDetails?.length
						? `${farmDetails[0]?.cultivatedArea} Acres`
						: '0 Acres',
					crops: response[stateId]?.distinctCrops
						? response[stateId]?.distinctCrops
						: [],
					cropVarieties: response[stateId]?.distinctVarieties
						? response[stateId]?.distinctVarieties
						: [],
					daysAfterSowing:
						farmDetails?.length && farmDetails[0]?.startDate
							? getDaysBetweenDates(
									farmDetails[0]?.startDate,
									farmDetails[0]?.endDate
							  )
							: 0,
					lastVisitedBy: executive?.length
						? `${executive[0]?.firstName} ${executive[0]?.lastName || ''}`
						: '',
				});

				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleClickOutside = (event) => {
		if (popupRef.current && !popupRef.current?.contains(event?.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (
			geoTagId &&
			Object.keys(districtDetails || {})?.length &&
			selectedCompanyCropID
		) {
			getFarmerDetails(districtDetails, geoTagId, selectedCompanyCropID);
		} else {
			setIsLoading(true);
		}

		return () => {
			setIsLoading(true);
			// eslint-disable-next-line no-unused-expressions
		};
	}, [geoTagId, districtDetails, selectedCompanyCropID]);

	return (
		<div className="w-1/3 fixed bottom-1.5 right-1.5 z-10 bg-white rounded-lg shadow-lg drop-shadow-xl p-2.5">
			<div className="flex justify-between items-center mb-5">
				<p>
					<label className="text-base font-bold">Summary</label>
				</p>
				<button type="button" onClick={onClose}>
					<CloseIcon className="h-8 w-8" fill="#cacaca" color="#ffffff" />
				</button>
			</div>
			{isLoading ? (
				<div className="flex justify-center items-center">
					<Loading />
				</div>
			) : Object.keys(farmerDetails || {}).length ? (
				<section
					ref={popupRef}
					className="flex flex-wrap gap-1 justify-between"
				>
					<div className="flex flex-row w-full mb-2">
						<div className="flex w-1/2 gap-1 flex-col p-1.5 rounded-md border mr-2">
							<label className="text-sm font-medium text-left text-gray-500">
								Farmer Name
							</label>
							<label className="text-base font-bold text-primary">
								{farmerDetails?.farmerName}
							</label>
						</div>
						<div className="flex w-1/2 gap-1 flex-col p-1.5 rounded-md border">
							<label className="text-sm font-medium text-left text-gray-500">
								Area
							</label>
							<label className="text-base font-bold text-primary">
								{farmerDetails?.area || ' - '}
							</label>
						</div>
					</div>
					<div className="flex flex-row w-full mb-2">
						<div className="flex w-1/2 gap-1 flex-col p-1.5 rounded-md border mr-2">
							<label className="text-sm font-medium text-left text-gray-500">
								Crop
							</label>
							<label className="text-base font-bold text-primary">
								{farmerDetails?.crops?.join(',') || '-'}
							</label>
						</div>
						<div className="flex w-1/2 gap-1 flex-col p-1.5 rounded-md border">
							<label className="text-sm font-medium text-left text-gray-500">
								Crop Varieties
							</label>
							<label className="text-base font-bold text-primary">
								{farmerDetails?.cropVarieties?.join(',') || '-'}
							</label>
						</div>
					</div>
					<div className="flex flex-row w-full">
						<div className="flex w-1/2 gap-1 flex-col p-1.5 rounded-md border mr-2">
							<label className="text-sm font-medium text-left text-gray-500">
								Days After Sowing
							</label>
							<label className="text-base font-bold text-primary">
								{farmerDetails?.daysAfterSowing || 0}
							</label>
						</div>
						<div className="flex w-1/2 gap-1 flex-col p-1.5 rounded-md border">
							<label className="text-sm font-medium text-left text-gray-500">
								Last Visited By
							</label>
							<label className="text-base font-bold text-primary">
								{farmerDetails?.lastVisitedBy || '-'}
							</label>
						</div>
					</div>
				</section>
			) : (
				<div className="flex justify-center items-center">
					<NoDataFound isFullScreen={false} />
				</div>
			)}
		</div>
	);
};

export default MapPopUp;
