import React from 'react';

const DashboardIcon = (props) => {
	const { height = 24, width = 24, fill = '#e0e1e8', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={width}
			height={height}
			fill={fill}
			className={className}
		>
			<g>
				<path d="M22,6.6c0,0.8,0,1.6,0,2.3c0,1.3-1,2.3-2.3,2.3c-1.6,0-3.1,0-4.7,0c-1.3,0-2.3-1-2.3-2.3c0-1.6,0-3.1,0-4.7 C12.8,3,13.8,2,15,2c1.6,0,3.1,0,4.7,0C21,2,22,3,22,4.3C22,5.1,22,5.8,22,6.6z M17.4,9.7c0.7,0,1.4,0,2.1,0c0.6,0,0.9-0.3,0.9-0.9 c0-1.4,0-2.8,0-4.3c0-0.6-0.3-0.9-0.9-0.9c-1.4,0-2.8,0-4.3,0c-0.6,0-0.9,0.3-0.9,0.9c0,1.4,0,2.8,0,4.3c0,0.6,0.3,0.9,0.9,0.9 C16,9.7,16.7,9.7,17.4,9.7z" />
				<path d="M6.6,12.8c0.8,0,1.6,0,2.4,0c1.3,0,2.3,1,2.3,2.3c0,1.6,0,3.1,0,4.7c0,1.2-1,2.2-2.2,2.2c-1.6,0-3.2,0-4.8,0 C3,22,2,21,2,19.8c0-1.6,0-3.2,0-4.8c0-1.2,1-2.2,2.2-2.2C5,12.8,5.8,12.8,6.6,12.8C6.6,12.8,6.6,12.8,6.6,12.8z M9.7,17.4 c0-0.7,0-1.5,0-2.2c0-0.6-0.3-0.9-0.9-0.9c-1.4,0-2.9,0-4.3,0c-0.6,0-0.9,0.3-0.9,0.9c0,1.4,0,2.9,0,4.3c0,0.6,0.3,0.9,0.9,0.9 c1.4,0,2.9,0,4.3,0c0.6,0,0.9-0.3,0.9-0.9C9.7,18.8,9.7,18.1,9.7,17.4z" />
				<path d="M6.6,11.2c-0.8,0-1.6,0-2.3,0C3,11.2,2,10.2,2,8.9c0-1.6,0-3.1,0-4.7C2,3.1,3.1,2,4.3,2c1.5,0,3.1,0,4.6,0 c1.3,0,2.3,1,2.3,2.3c0,1.6,0,3.1,0,4.7c0,1.2-1,2.2-2.3,2.3C8.2,11.2,7.4,11.2,6.6,11.2z M6.6,3.6C6.6,3.6,6.6,3.6,6.6,3.6 c-0.7,0-1.5,0-2.2,0c-0.6,0-0.9,0.3-0.9,0.9c0,1.5,0,2.9,0,4.4c0,0.6,0.3,0.9,0.9,0.9c1.5,0,2.9,0,4.4,0c0.6,0,0.9-0.3,0.9-0.9 c0-1.5,0-2.9,0-4.4c0-0.6-0.3-0.9-0.9-0.9C8.1,3.6,7.4,3.6,6.6,3.6z" />
				<path d="M22,17.4c0,0.8,0,1.5,0,2.3c0,1.3-1,2.3-2.3,2.3c-1.6,0-3.1,0-4.7,0c-1.2,0-2.3-1-2.3-2.3c0-1.3,0-2.6,0-3.8 c0-0.5,0.3-0.8,0.8-0.8c0.5,0,0.8,0.3,0.8,0.8c0,1.2,0,2.4,0,3.6c0,0.7,0.3,1,1,1c1.4,0,2.8,0,4.2,0c0.7,0,1-0.3,1-0.9 c0-1.4,0-2.8,0-4.2c0-0.7-0.3-0.9-1-0.9c-1.5,0-2.9,0-4.4,0c-0.4,0-0.7-0.2-0.8-0.5c-0.1-0.3,0-0.6,0.3-0.9 c0.1-0.1,0.3-0.2,0.5-0.2c1.6,0,3.2,0,4.8,0c1.1,0,2.1,1.1,2.1,2.2C22,15.8,22,16.6,22,17.4C22,17.4,22,17.4,22,17.4z" />
			</g>
			{/* <defs>
				<clipPath>
					<rect width={20} height={20} />
				</clipPath>
			</defs>
			<g>
				<rect width={20} height={20} fill="none" />
				<path
					d="M4.5,13.389h7.111V4.5H4.5Zm0,7.111h7.111V15.167H4.5Zm8.889,0H20.5V11.611H13.389Zm0-16V9.833H20.5V4.5Z"
					transform="translate(-2.5 -2.5)"
					fill={fill}
				/>
			</g> */}
		</svg>
	);
};

export default DashboardIcon;
