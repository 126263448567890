import React from 'react';

const ImagesIcon = (props) => {
	const { width = 24, height = 24, fill = '#000000', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width={width}
			height={height}
			fill={fill}
			className={className}
		>
			<g>
				<path d="M10.8,3.3c2.1,0,4.1,0,6.2,0c1.2,0,2.2,0.8,2.5,2c0.1,0.3,0.1,0.7,0.1,1c0,0.1,0,0.2,0.2,0.2C21,6.4,22,7.5,22,8.8 c0,3.2,0,6.3,0,9.5c0,1.4-1.1,2.4-2.4,2.4c-4,0-8,0-12,0c-1.3,0-2.4-1-2.4-2.3c0-0.1,0-0.2-0.2-0.2c-0.5,0-1,0-1.5-0.2 c-1-0.5-1.5-1.2-1.5-2.3c0-1.1,0-2.3,0-3.4C2,10.1,2,8,2,5.8c0-1.2,0.8-2.2,2-2.5c0.2-0.1,0.4-0.1,0.7-0.1 C6.7,3.3,8.7,3.3,10.8,3.3z M6.4,15.7c0.1,0,0.1-0.1,0.1-0.1c1.1-1,2.2-2,3.3-2.9c0.8-0.7,1.9-0.7,2.7,0.1c1,1,2,2,3.1,3 c0.1,0.1,0.2,0.1,0.3,0c0.2-0.2,0.5-0.5,0.7-0.7c0.8-0.7,1.8-0.8,2.6-0.1c0.5,0.4,1,0.8,1.5,1.3c0,0,0.1,0.1,0.1,0.1 c0-0.1,0-0.1,0-0.2c0-2.4,0-4.8,0-7.3c0-0.8-0.5-1.3-1.3-1.3c-3.9,0-7.9,0-11.8,0c-0.8,0-1.3,0.5-1.3,1.3c0,2.2,0,4.4,0,6.6 C6.4,15.6,6.4,15.6,6.4,15.7z M10.8,4.5c-2.1,0-4.1,0-6.2,0C3.8,4.5,3.3,5,3.3,5.8c0,0.9,0,1.8,0,2.7c0,2.4,0,4.8,0,7.2 c0,0.7,0.4,1.2,1.1,1.3c0.2,0,0.4,0,0.6,0c0.2,0,0.2,0,0.2-0.2c0-2.6,0-5.2,0-7.9c0-0.2,0-0.4,0.1-0.6c0.3-1.1,1.2-1.9,2.4-1.9 c3.4,0,6.8,0,10.1,0c0.2,0,0.4,0.1,0.5,0c0.1-0.1,0-0.3,0-0.5c0-0.8-0.5-1.4-1.4-1.4C14.8,4.5,12.8,4.5,10.8,4.5z M9.7,19.5 c0.7,0,1.5,0,2.2,0c0.1,0,0.2,0,0.3-0.1c0.8-0.8,1.6-1.6,2.4-2.4c0.1-0.1,0.1-0.2,0-0.3c-1-1-2-2-3-3c-0.3-0.3-0.6-0.4-1,0 c-1.2,1.1-2.4,2.1-3.6,3.2c-0.2,0.2-0.5,0.3-0.6,0.6c-0.1,0.2,0,0.5,0,0.8c0,0.7,0.5,1.2,1.2,1.2C8.3,19.5,9,19.5,9.7,19.5z  M13.9,19.5c0.1,0,0.2,0,0.3,0c1.8,0,3.5,0,5.3,0c0.9,0,1.3-0.5,1.3-1.3c0-0.1,0-0.2-0.1-0.2c-0.8-0.6-1.6-1.3-2.3-1.9 c-0.4-0.3-0.6-0.3-1,0c-0.9,0.9-1.9,1.9-2.8,2.8C14.3,19,14.1,19.3,13.9,19.5z" />
				<path d="M16.6,12.4c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C18.4,11.6,17.6,12.4,16.6,12.4z M16.6,11.1 c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6C16,10.9,16.2,11.1,16.6,11.1z" />
			</g>
		</svg>
	);
};
export default ImagesIcon;
