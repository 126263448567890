import { createSlice } from '@reduxjs/toolkit';
import apiSlice from '../apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: '/identity/login',
				method: 'POST',
				body: credentials,
			}),
		}),
		resetPassword: builder.mutation({
			query: (credentials) => ({
				url: '/identity/reset-password',
				method: 'POST',
				body: credentials,
			}),
		}),
		forgotPassword: builder.mutation({
			query: (credentials) => ({
				url: `/identity/send-forgot-password-code?userName=${credentials.userName}&changeType=${credentials.changeType}`,
				method: 'POST',
				// body: credentials,
			}),
		}),
		getCompanyUserProfileInfo: builder.query({
			query: (userId) => `dashboard/get-company-user-info?userId=${userId}`,
		}),
	}),
});

export const {
	useLoginMutation,
	useResetPasswordMutation,
	useForgotPasswordMutation,
	useGetCompanyUserProfileInfoQuery,
} = userApiSlice;

const slice = createSlice({
	name: 'auth',
	initialState: { user: {}, filters: {} },
	reducers: {
		setCredentials: (state, { payload: { user } }) => {
			localStorage.setItem('auth', JSON.stringify(user));
			state.user = user;
		},
		loadAuthFromLocalStorage: (state) => {
			const extAuthStr = localStorage.getItem('auth');
			const extAuthJson = JSON.parse(extAuthStr);
			if (extAuthStr) {
				state.user = extAuthJson;
			}
		},
		logout: (state) => {
			localStorage.removeItem('auth');
			state.user = null;
		},
		updateFilters: (state, action) => ({
			...state,
			filters: { ...state.filters, ...action?.payload },
		}),
	},
});

export const {
	setCredentials,
	loadAuthFromLocalStorage,
	logout,
	updateFilters,
} = slice.actions;

export const authReducer = slice.reducer;

export const getCurrentUser = (state) => state.auth.user;

export const getUserAppliedFilters = (state) => state.auth.filters;
