import React, { useState, useEffect } from 'react';
import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
	ZoomableGroup,
} from 'react-simple-maps';
import * as turf from '@turf/turf';
import { fetchIndiaMap, removeDuplicates } from '../../util/mapUtils';
import MapSmallPopup from './MapSmallPopup';
import NoDataFound from '../NoData';
import Loading from '../Loading';
import { useGetSettingsFromState } from '../../redux/api/hooks/dashboardHooks';

const India = ({
	setIsState,
	setStateName,
	setStateId,
	totalData = {},
	availableStateIds = [],
}) => {
	const [markers, setMarkers] = useState([]);
	const [tooltipContent, setTooltipContent] = useState(false);
	const [stateDetails, setStateDetails] = useState({
		id: '',
		stateName: '',
		isActive: false,
		tooltip: {},
	});

	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [state, setState] = useState({
		farmerCount: 0,
		totalFarmArea: 0,
		crops: [],
		varieties: [],
	});

	const dashboardSettings = useGetSettingsFromState();
	const token = dashboardSettings?.SASToken || '';

	useEffect(() => {
		if (data?.features?.length && turf.polygon && turf.centroid) {
			const values = data.features.map((item) => {
				let centroids = [0, 0];
				try {
					const polygons =
						item.geometry.type === 'MultiPolygon'
							? turf.polygon(item.geometry.coordinates.flat())
							: turf.polygon(item.geometry.coordinates);
					centroids = turf.centroid(polygons);
					// eslint-disable-next-line no-empty
				} catch (error) {}
				return {
					markerOffset: 3.5,
					name: item.properties.stateName,
					coordinates: centroids.geometry.coordinates,
				};
			});

			const removeDummy = removeDuplicates(values, 'name');

			setMarkers(removeDummy);
		}
	}, [data]);

	useEffect(() => {
		setIsLoading(true);
		if (token) {
			fetchIndiaMap('india.json', setData, setIsLoading, token);
		}
	}, [token]);

	const getStateData = (id, stateName, isActive, tooltip) => {
		if (totalData && Object.keys(totalData || {})?.length) {
			const uniqStateId = availableStateIds?.find((value) => value === id);
			if (uniqStateId) {
				const totalFarmerCount = totalData[uniqStateId]?.farmerCount || 0;
				setStateDetails({
					...stateDetails,
					isActive,
					id,
					stateName,
					tooltip,
				});
				setState({
					farmerCount: totalFarmerCount,
					totalFarmArea: totalData[uniqStateId]?.cultivatedArea || 0,
					crops: totalData[uniqStateId]?.distinctCrops
						? totalData[uniqStateId]?.distinctCrops
						: [],
					varieties: totalData[uniqStateId]?.distinctVarieties
						? totalData[uniqStateId]?.distinctVarieties
						: [],
				});
				setTooltipContent(true);
				return true;
			}
		}
		setStateDetails({
			...stateDetails,
			isActive: false,
			id,
			stateName,
			tooltip,
		});
		setTooltipContent(true);
		return false;
	};

	return (
		<div className="flex justify-center">
			<section className=" h-screen w-3/6 focus-visible:outline-0 focus-visible:border-0">
				{isLoading ? (
					<div className="flex justify-center items-center">
						<Loading />
					</div>
				) : data ? (
					<div className="relative focus-visible:outline-0 focus-visible:border-0 mt-4">
						<ComposableMap
							data-tip=""
							projection="geoMercator"
							width={160}
							height={160}
							projectionConfig={{ scale: 220 }}
						>
							<ZoomableGroup zoom={1.2} center={[80, 22]} disableZooming>
								<Geographies geography={data}>
									{({ geographies }) =>
										geographies.map((geo) => {
											const { id: stateId } = geo.properties;
											const uniqStateId = availableStateIds?.find(
												(value) => value === stateId
											);

											return (
												<Geography
													key={geo.rsmKey}
													geography={geo}
													onMouseEnter={() => {
														const { stateName, id, isActive, tooltip } =
															geo.properties;
														getStateData(id, stateName, isActive, tooltip);
													}}
													onMouseLeave={() => {
														setTooltipContent(false);
														setStateDetails({
															...stateDetails,
															id: '',
															isActive: false,
															stateName: '',
															tooltip: {},
														});
													}}
													onClick={() => {
														const { stateName, isActive, id } = geo.properties;
														if (isActive && uniqStateId) {
															setStateName(stateName);
															setIsState(true);
															setTooltipContent(false);
															setStateId(id);
															setStateDetails({
																...stateDetails,
																id: '',
																isActive: false,
																stateName: '',
																tooltip: {},
															});
														}
													}}
													style={{
														default: {
															fill: uniqStateId ? '#2E8B57' : '#D6D6DA',
															outline: 'none',
															stroke: '#737373',
															strokeWidth: 0.1,
														},
														hover: {
															fill: uniqStateId ? 'forestgreen' : '#D6D6DA',
															outline: 'none',
															stroke: '#737373',
															strokeWidth: 0.1,
														},
														pressed: {
															fill: uniqStateId ? '#2E8B57' : '#D6D6DA',
															outline: 'none',
															stroke: '#737373',
															strokeWidth: 0.1,
														},
													}}
												/>
											);
										})
									}
								</Geographies>
								{markers.map(({ name, coordinates, markerOffset }) => (
									<Marker key={name} coordinates={coordinates}>
										<text
											textAnchor="middle"
											y={markerOffset}
											style={{
												fontFamily: 'Open Sans',
												fill: '#000',
												fontSize: 1.75,
												fontWeight: '400',
											}}
										>
											{name}
										</text>
									</Marker>
								))}
							</ZoomableGroup>
						</ComposableMap>
						{tooltipContent ? (
							<MapSmallPopup
								stateId={stateDetails.id}
								isActive={stateDetails.isActive}
								tooltip={stateDetails.isActive}
								header={stateDetails.stateName}
								type="State"
								data={state}
							/>
						) : null}
					</div>
				) : (
					<NoDataFound />
				)}
			</section>
		</div>
	);
};

export default India;
