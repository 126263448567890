import React from 'react';

const NoDataFound = ({ isFullScreen = true }) => (
	<div
		className={`w-full bg-tableRowBg flex justify-center items-center${
			isFullScreen ? 'min-h-[34rem]' : ''
		}`}
	>
		<p className="text-sm font-normal text-black text-center px-2.5 py-3">
			No Data Found
		</p>
	</div>
);

export default NoDataFound;
