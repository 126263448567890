/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import {
	PieChart,
	Pie,
	Cell,
	ResponsiveContainer,
	Legend,
	Tooltip,
} from 'recharts';
import exportFromJSON from 'export-from-json';
import {
	useGetSowingWindowChartDataQuery,
	useGetSowingWindowExcelDataMutation,
} from '../../redux/api/slices/dashboardApiSlice';
import { monthsMap, renderAcres } from '../../util';
import ErrorOccured from '../ErrorOccured';
import Loading from '../Loading';
import Table from '../Table';
import {
	chartHeight,
	chartWidth,
	CustomToolTip,
	DashboardChartHeader,
	getKeyFromText,
	LegendCellRenderer,
} from './util';

const COLORS = [
	{ tailwindColorName: 'bg-lightPurple', colorHex: '#9F7AEF' },
	{ tailwindColorName: 'bg-lightGreen3', colorHex: '#AFF07B' },
	{ tailwindColorName: 'bg-biscuit', colorHex: '#F28E7B' },
	{ tailwindColorName: 'bg-BlusihPurple', colorHex: '#8661A5' },
	{ tailwindColorName: 'bg-OrchidRed', colorHex: '#AD878D' },
	{ tailwindColorName: 'bg-Bistre', colorHex: '#3C2317' },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
	cx,
	cy,
	midAngle,
	innerRadius,
	outerRadius,
	percent,
}) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text
			className="text-xs"
			x={x}
			y={y}
			fill="white"
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline="central"
		>
			{/* {`${(percent * 100).toFixed(0)}%`} */}
			{`${Math.round((percent * 100 + Number.EPSILON) * 10) / 10}%`}
		</text>
	);
};

const SowingWindowChart = ({
	farmerCodes,
	companyCropId,
	cropVariety,
	states = [],
	districts = [],
	fromDate,
	toDate,
}) => {
	const [chartData, setChartData] = useState([]);
	const [customLoading, setCustomLoading] = useState(true);
	const {
		data: sowingWindowChartData,
		isLoading,
		isError,
		error,
		isFetching,
	} = useGetSowingWindowChartDataQuery(
		companyCropId?.length && fromDate && toDate
			? {
				farmerCodes,
					companyCropId,
					cropVarietyId: cropVariety,
					districts,
					states,
					fromDate,
					toDate,
			  }
			: skipToken
	);

	const [getSowingWindowExcelData] = useGetSowingWindowExcelDataMutation();

	useEffect(() => {
		if (isFetching) {
			setCustomLoading(true);
		}
	}, [isFetching]);

	useEffect(() => {
		const mData = (sowingWindowChartData || []).map((item, index) => {
			const monthName = monthsMap[item.id];
			return {
				id: getKeyFromText(monthName),
				name: monthName,
				value: item?.windowMonth,
				color: COLORS[index % COLORS.length],
			};
		});
		setChartData(mData);
		setCustomLoading(false);
	}, [sowingWindowChartData]);

	const columns = useMemo(
		() => [
			{
				Header: 'Month',
				accessor: 'name',
				Cell: ({ row }) => <LegendCellRenderer row={row} />,
				width: 150,
			},
			{
				Header: 'Acres',
				accessor: 'value',
				Cell: ({ row }) => (
					<p>
						{renderAcres({
							val: row?.original?.value || 0,
						})}
					</p>
				),
				width: 100,
			},
		],
		[]
	);

	const downloadExcelData = async () => {
		try {
			const result = await getSowingWindowExcelData({
				farmerCodes,
				companyCropId,
				cropVarietyId: cropVariety,
				states,
				districts,
				fromDate,
				toDate,
			}).unwrap();
			if (result && result?.length) {
				const finalResult = result?.map((item) => ({
					'Farmer Name': item?.firstName || '',
					Month: item?.monthName || '',
					'Cultivated Area': item?.cultivatedArea || '',
					Crop: item?.cropName || '',
					'Crop Variety': item?.cropVarietyName || '',
					Mandal: item?.mandal || '',
					Village: item?.village || '',
					District: item?.district || '',
					State: item?.state || '',
					Postcode: item?.postcode || '',
				}));
				const exportData = {
					data: finalResult,
					fileName: 'Sowing_Window',
					exportType: 'csv',
				};
				exportFromJSON(exportData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const dropdownBtns = [
		{
			label: 'Export To Excel',
			action: async () => {
				await downloadExcelData();
			},
			isDisabled: !chartData.length,
		},
	];

	return (
		<>
			<DashboardChartHeader
				text="Sowing Window"
				subText="(Month)"
				dropdownBtns={dropdownBtns}
			/>
			{isLoading || customLoading ? (
				<div className="card-center">
					<Loading />
				</div>
			) : isError ? (
				<div className="card-center">
					<ErrorOccured fullScreenMode msg={error} />
				</div>
			) : chartData.length ? (
				<ResponsiveContainer width="100%" height="100%" className="p-2.5">
					<PieChart width={chartWidth} height={chartHeight}>
						<Pie
							data={chartData}
							cx="40%"
							cy="40%"
							labelLine={false}
							label={renderCustomizedLabel}
							fill="#8884d8"
							dataKey="value"
							outerRadius={100}
							minAngle={10}
						>
							{chartData.map((entry) => (
								<Cell key={entry.id} fill={entry?.color?.colorHex} />
							))}
						</Pie>
						<Legend
							className="hidden lg:block h-64 overflow-y-auto scrollbar ml-3"
							align="right"
							layout="vertical"
							verticalAlign="middle"
							wrapperStyle={{ right: '5%', top: '10%' }}
							content={
								<Table
									data={chartData}
									columns={columns}
									theadClassName="w-full text-center text-sm font-semibold"
									tdClassName="text-xs font-normal"
								/>
							}
						/>
						<Tooltip content={<CustomToolTip unit="Acres" />} />
					</PieChart>
				</ResponsiveContainer>
			) : (
				<h3 className="card-center text-xl font-medium">No Data found</h3>
			)}
		</>
	);
};

export default SowingWindowChart;
