import { Popover, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ChevronDownIcon } from '../icons';
import { DEFAULT_DATE_FORMAT } from '../util';
import { getFormattedDate } from '../util/dateUtil';

const DateSelectionPopover = ({
	className = '',
	btnClassName = '',
	ranges: submittedRanges,
	submittedRange,
	handleSubmit,
}) => {
	const [selectedRange, setSelectedRange] = useState('');
	const [ranges, setRanges] = useState([]);
	const buttonRef = useRef();
	const [prevDate, setPrevDate] = useState([]);
	const calenderState = useRef();

	const selectRangeHandler = (data) => {
		let str = '';
		const { startDate, endDate } = data[0];
		if (startDate) {
			str += getFormattedDate({
				date: new Date(startDate),
				reqFormat: DEFAULT_DATE_FORMAT,
			});
		}
		if (endDate) {
			str += ` - ${getFormattedDate({
				date: new Date(endDate),
				reqFormat: DEFAULT_DATE_FORMAT,
			})}`;
		}
		setSelectedRange(str);
	};

	useEffect(() => {
		if (submittedRanges?.length) {
			selectRangeHandler(submittedRanges);
		}
		setRanges(submittedRanges);
	}, [submittedRanges, submittedRange]);

	const handleSelect = (tRanges) => {
		if (tRanges?.length) {
			let str = '';
			const { startDate, endDate } = tRanges[0];
			if (startDate) {
				str += format(new Date(startDate), DEFAULT_DATE_FORMAT);
			}
			if (endDate) {
				str += ` - ${format(new Date(endDate), DEFAULT_DATE_FORMAT)}`;
			}
			setSelectedRange(str);
		}
		setRanges(tRanges);
	};

	const beforeLeave = () => {
		if (!calenderState.current) {
			selectRangeHandler(prevDate);
			calenderState.current = false;
			setRanges(prevDate);
			setPrevDate([]);
		}
	};

	const beforeEnter = () => {
		calenderState.current = false;
		setPrevDate(ranges);
	};

	return (
		<Popover className={`relative ${className}`}>
			{({ open }) => (
				<>
					<Popover.Button
						className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center rounded-md bg-white border-border1 border text-dropDownPlaceholderText shadow-none px-3 py-2 text-sm text-black hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 w-full ${btnClassName}`}
						ref={buttonRef}
					>
						<span className="w-full md:w-52 text-left text-sm text-dropDownPlaceholderText">
							{(selectedRange && selectedRange) || ''}
						</span>
						<ChevronDownIcon
							className={`${open ? '' : 'text-opacity-70'}
                  ml-2 h-5 w-5 text-white transition duration-150 ease-in-out group-hover:text-opacity-80`}
							aria-hidden="true"
						/>
					</Popover.Button>
					<Transition
						beforeLeave={() => beforeLeave()}
						beforeEnter={() => beforeEnter()}
						as={Fragment}
						enter="transition ease-out duration-200"
						enterFrom="opacity-0 translate-y-1"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100 translate-y-0"
						leaveTo="opacity-0 translate-y-1"
					>
						<Popover.Panel className="absolute right-0 z-10 mt-3 max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
							<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
								<DateRangePicker
									ranges={ranges}
									onChange={(item) =>
										handleSelect && handleSelect([item.selection])
									}
									showSelectionPreview
									months={2}
									moveRangeOnFirstSelection={false}
									direction="horizontal"
									preventSnapRefocus
									className="border-b-2 border-gray-200 w-full rdrMonth rdrDefinedRangesWrapper rdrDateRangePickerWrapper rdrStaticRangeLabel rdrInputRange mainClass"
								/>
								<button
									type="submit"
									className="w-24 bg-primary float-right px-2 py-1 m-2 text-white tracking-wide rounded-md hover:bg-primary outline-lightGreen2 hover:outline hover:outline-2"
									onClick={() => {
										// eslint-disable-next-line no-unused-expressions
										handleSubmit && handleSubmit(ranges);
										calenderState.current = true;
										buttonRef.current?.click();
									}}
								>
									SUBMIT
								</button>
							</div>
						</Popover.Panel>
					</Transition>
				</>
			)}
		</Popover>
	);
};

export default DateSelectionPopover;
