/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ErrorOccured from '../components/ErrorOccured';
import Loading from '../components/Loading';
import Table from '../components/Table';
import { useGetExecutiveActivitiesByIDQuery } from '../redux/api/slices/companyApiSlice';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATETIME_FORMAT } from '../util';
import { getFormattedDate, getPreviousDate } from '../util/dateUtil';
import NoDataFound from '../components/NoData';
import RadioButton from '../components/RadioButtons';

const ExecutivesActivity = () => {
	const [columnFilters, setColumnFilters] = useState([]);
	const [title, setTitle] = useState('');
	const [columnSortBy, setColumnSortBy] = useState([]);
	const { executiveId } = useParams();
	const [executiveDates, setExecutiveDates] = useState({
		toDate: getPreviousDate(1),
		fromDate: getPreviousDate(7),
	});
	const location = useLocation();
	const [executivesActivity, setExecutivesActivity] = useState([]);
	const [customLoading, SetCustomLoading] = useState(true);

	const {
		data: executivesActivityData = [],
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetExecutiveActivitiesByIDQuery(
		executiveId && executiveDates
			? {
					executiveId,
					toDate: executiveDates.toDate,
					fromDate: executiveDates.fromDate,
			  }
			: skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions, no-use-before-define
		executiveId && checkPreviousExecutive(location);
	}, [location, executiveId]);

	useEffect(() => {
		SetCustomLoading(true);
		const temp = [];
		if (executivesActivityData?.length) {
			executivesActivityData.forEach((executiveActivity) => {
				temp.push({
					...executiveActivity,
					fieldVisitDate: executiveActivity?.date
						? getFormattedDate({
								date: executiveActivity?.date,
								reqFormat: DEFAULT_DATE_FORMAT,
						  })
						: '',
					clusterName:
						executiveActivity?.firstActivity?.clusterName ===
						executiveActivity?.lastActivity?.clusterName
							? executiveActivity?.firstActivity?.clusterName
							: `${executiveActivity?.firstActivity?.clusterName} , 
				${executiveActivity?.lastActivity?.clusterName}`,
					villageName:
						executiveActivity?.firstActivity?.villageName ===
						executiveActivity?.lastActivity?.villageName
							? executiveActivity?.firstActivity?.villageName
							: `${executiveActivity?.firstActivity?.villageName} , 
					${executiveActivity?.lastActivity?.villageName}`,
					firstEntry: `${executiveActivity?.firstActivity?.cropName} , ${
						executiveActivity?.firstActivity?.cropVarietiName
					} , ${
						executiveActivity?.firstActivity?.createdOn
							? getFormattedDate({
									date: executiveActivity?.firstActivity?.createdOn,
									reqFormat: DEFAULT_DATETIME_FORMAT,
							  })
							: ''
					}`,
					lastEntry: `${executiveActivity?.lastActivity?.cropName} , ${
						executiveActivity?.lastActivity?.cropVarietiName
					} , ${
						executiveActivity?.lastActivity?.createdOn
							? getFormattedDate({
									date: executiveActivity?.lastActivity?.createdOn,
									reqFormat: DEFAULT_DATETIME_FORMAT,
							  })
							: ''
					}`,
				});
			});
		}
		setExecutivesActivity(temp);
		SetCustomLoading(false);
	}, [executivesActivityData]);

	const checkPreviousExecutive = async (routeLocation) => {
		const prevousExecutiveData = await localStorage.getItem(
			'executiveActivityData'
		);
		if (prevousExecutiveData) {
			const parsePrevousExecutiveData = JSON.parse(prevousExecutiveData);
			if (parsePrevousExecutiveData?.id === executiveId) {
				setTitle(parsePrevousExecutiveData?.executiveName);
			} else {
				await localStorage.removeItem('executiveActivityData');
				await localStorage.setItem(
					'executiveActivityData',
					JSON.stringify({
						id: executiveId,
						executiveName: routeLocation?.state?.executiveName,
					})
				);
				setTitle(routeLocation?.state.executiveName);
			}
		} else {
			await localStorage.setItem(
				'executiveActivityData',
				JSON.stringify({
					id: executiveId,
					executiveName: routeLocation?.state?.executiveName,
				})
			);
			setTitle(location?.state.executiveName);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'fieldVisitDate',
				Cell: ({ row }) => (
					<p className="text-left">{row?.original?.fieldVisitDate}</p>
				),
				width: 200,
			},
			{
				Header: 'Cluster',
				accessor: 'clusterName',
				Cell: ({ row }) => <p>{row?.original?.clusterName}</p>,
				width: 200,
			},
			{
				Header: 'Village',
				accessor: 'villageName',
				Cell: ({ row }) => <p>{row?.original?.villageName}</p>,
				width: 200,
			},
			{
				Header: 'First Entry',
				accessor: 'firstEntry',
				Cell: ({ row }) => <p>{row?.original?.firstEntry}</p>,
				width: 180,
			},
			{
				Header: 'Last Entry',
				accessor: 'lastEntry',
				Cell: ({ row }) => <p>{row?.original?.lastEntry}</p>,
				width: 180,
			},
		],
		[]
	);

	const datesRadioBtns = useMemo(
		() => [
			{
				label: '7 days',
				value: '7 days',
				dates: { toDate: getPreviousDate(1), fromDate: getPreviousDate(7) },
			},
			{
				label: '15 days',
				value: '15 days',
				dates: { toDate: getPreviousDate(1), fromDate: getPreviousDate(15) },
			},
			{
				label: '30 days',
				value: '30 days',
				dates: {
					toDate: getPreviousDate(1),
					fromDate: getPreviousDate(30),
				},
			},
		],
		[]
	);

	return (
		<div>
			<div className="flex justify-between items-center">
				<h2 className="text-lg font-semibold text-secondary">{title || ''}</h2>
				<RadioButton
					radioButtons={datesRadioBtns}
					onChangeValue={(value, item) => setExecutiveDates(item.dates)}
					defaultActiveRadioValue={datesRadioBtns[0].value}
				/>
			</div>
			{isLoading || isFetching || customLoading ? (
				<Loading fullScreenMode />
			) : isError ? (
				<ErrorOccured fullScreenMode msg={error} />
			) : executivesActivityData?.length && executivesActivity?.length ? (
				<Table
					data={executivesActivity}
					columns={columns}
					className="w-full h-full card table border-0 shadow-none"
					theadClassName="thead text-sm text-left"
					tdClassName="px-4 td text-sm border-b border-border2 text-labelColor"
					displayTotal
					isFilterable
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
				/>
			) : (
				<NoDataFound />
			)}
		</div>
	);
};

export default ExecutivesActivity;
