/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '../icons';

const DistrictAndStageImages = ({ data }) => {
	const districts = data
		.map((dist) => dist.district)
		.filter((value, index, self) => self.indexOf(value) === index);
	return (
		<div className="mb-4">
			{districts.map((district, districtIndex) => (
				<District key={districtIndex} district={district} data={data} />
			))}
		</div>
	);
};

const District = ({ district, data }) => {
	const [expanded, setExpanded] = useState(false);

	const toggleExpansion = () => {
		setExpanded(!expanded);
	};
	const stagesInDistrict = data?.filter((arr) => arr.district === district);
	return (
		<div className="xl:w-7/12 mx-auto mb-3">
			<button
				onClick={toggleExpansion}
				className="text-lg font-medium text-black text-center focus:outline-none border border-sfBorder rounded-md px-3 py-2 bg-white hover:bg-cardBg xl:w-full mx-auto flex flex-row items-center justify-between"
			>
				{district}
				{expanded ? (
					<ChevronUpIcon
						className="w-[18px] h-[18px] ease-in-out transition-all origin-center"
						fill="#FA9527"
					/>
				) : (
					<ChevronUpIcon
						className="w-[18px] h-[18px] rotate-180 ease-in-out transition-all origin-center"
						fill="#FA9527"
					/>
				)}
			</button>

			{expanded && (
				<div className="mb-3 p-3 bg-white border border-sfBorder rounded-b-md">
					{stagesInDistrict.map((res) => (
						<Stage images={res.images} stage={res.stage} />
					))}
				</div>
			)}
		</div>
	);
};

const Stage = ({ stage, images }) => {
	const itemsPerPage = 4;
	const [currentPage, setCurrentPage] = useState(1);
	const [expanded, setExpanded] = useState(false);

	const toggleExpansion = () => {
		setExpanded(!expanded);
	};
	const start = (currentPage - 1) * itemsPerPage;
	const end = start + itemsPerPage;
	const paginatedImages = images.slice(start, end);

	const totalPages = Math.ceil(images.length / itemsPerPage);

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<div className="border bg-white border-borderColor px-3 py-1.5">
			<button
				onClick={toggleExpansion}
				className="text-base font-medium text-halfGreen hover:text-opacity-85 flex w-full items-center focus:outline-none"
			>
				{stage}
			</button>
			{expanded && (
				<>
					<div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
						{paginatedImages.map((image, imageIndex) => (
							<Image key={imageIndex} src={image} alt="No Failed to Load" />
						))}
					</div>
					<div className="flex items-center justify-end mt-4 mb-2">
						<button
							onClick={handlePrevPage}
							className={`pl-1.5 pr-3 py-1.5 flex items-center justify-center flex-row rounded-md bg-secondary hover:bg-opacity-90 ${
								currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
							}`}
						>
							{/* <ChevronLeftIcon
								width={16}
								height={16}
								className="mr-1"
								fill="#fff"
							/> */}
							<p className="text-sm font-medium text-white text-center">
								Previous
							</p>
						</button>
						<button
							onClick={handleNextPage}
							className={`pl-3 pr-1.5 py-1.5 flex items-center justify-center flex-row rounded-md bg-secondary ml-3 hover:bg-opacity-90 ${
								currentPage === totalPages
									? 'opacity-50 cursor-not-allowed'
									: ''
							}`}
						>
							<p className="text-sm font-medium text-white text-center">Next</p>
							{/* <ChevronRightIcon
								width={16}
								height={16}
								className="ml-1"
								fill="#fff"
							/> */}
						</button>
					</div>
				</>
			)}
		</div>
	);
};

const Image = ({ src, alt }) => {
	const imgStyle = {
		// width: '300px',
		// height: '300px',
		width: '100%',
		height: '200px',
		cursor: 'pointer',
	};

	const onError = (e) => {
		e.target.src = '';
	};

	return <img src={src} alt={alt} style={imgStyle} onError={onError} />;
};

export default DistrictAndStageImages;
