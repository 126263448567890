/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	Sector,
	Tooltip,
} from 'recharts';
import exportFromJSON from 'export-from-json';
import {
	useGetIpmAdherenceChartDataQuery,
	useGetIpmAdherenceExcelDataMutation,
} from '../../redux/api/slices/dashboardApiSlice';
import { renderAcres } from '../../util';
import ErrorOccured from '../ErrorOccured';
import Loading from '../Loading';
import Table from '../Table';
import {
	chartHeight,
	chartWidth,
	CustomToolTip,
	DashboardChartHeader,
	LegendCellRenderer,
} from './util';

const COLORS = [
	{ tailwindColorName: 'bg-ipmYes', colorHex: '#0F8DCA' },
	{ tailwindColorName: 'bg-ipmNo', colorHex: '#FBC999' },
];

const RADIAN = Math.PI / 180;

const IPMAdherenceChart = ({
	farmerCodes = [],
	companyCropId = [],
	cropVariety = [],
	states = [],
	districts = [],
	fromDate,
	toDate,
}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [chartData, setChartData] = useState([]);
	const [getIpmAdherenceExcelData] = useGetIpmAdherenceExcelDataMutation();
	const [customLoading, setCustomLoading] = useState(true);

	const {
		data: ipmAdherenceChartData,
		isLoading,
		isError,
		error,
		isFetching,
	} = useGetIpmAdherenceChartDataQuery(
		companyCropId?.length && fromDate && toDate
			? {
					farmerCodes,
					companyCropId,
					cropVarietyId: cropVariety,
					districts,
					states,
					fromDate,
					toDate,
			  }
			: skipToken
	);

	useEffect(() => {
		if (isFetching) {
			setCustomLoading(true);
		}
	}, [isFetching]);

	useEffect(() => {
		if (ipmAdherenceChartData) {
			setChartData([
				{
					name: 'Yes',
					value: ipmAdherenceChartData?.IpmAdherentAcres
						? parseFloat(ipmAdherenceChartData?.IpmAdherentAcres.toFixed(2))
						: 0,
					id: 'ipm-yes',
					color: COLORS[0],
				},
				{
					name: 'No',
					value: ipmAdherenceChartData?.NonIpmAdherentAcres
						? parseFloat(ipmAdherenceChartData?.NonIpmAdherentAcres.toFixed(2))
						: 0,
					id: 'ipm-no',
					color: COLORS[1],
				},
			]);
			setActiveIndex(
				(ipmAdherenceChartData?.IpmAdherentAcres || 0) <
					(ipmAdherenceChartData.NonIpmAdherentAcres || 0)
					? 1
					: 0
			);
			setCustomLoading(false);
		} else {
			setChartData([]);
			setCustomLoading(false);
		}
	}, [ipmAdherenceChartData]);

	const columns = useMemo(
		() => [
			{
				Header: 'IPM',
				accessor: 'name',
				Cell: ({ row }) => <LegendCellRenderer row={row} />,
				width: 150,
			},
			{
				Header: 'Acres',
				accessor: 'value',
				Cell: ({ row }) => (
					<p>
						{renderAcres({
							val: row?.original?.value || 0,
						})}
					</p>
				),
				width: 100,
			},
		],
		[]
	);

	const renderActiveShape = ({
		cx,
		cy,
		innerRadius,
		outerRadius,
		startAngle,
		midAngle,
		endAngle,
		fill,
		percent,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<g>
				<text x={x} y={y} fill="white" textAnchor="middle">
					{`${
						(percent * 100).toFixed(2).endsWith('.00')
							? (percent * 100).toFixed(0)
							: (percent * 100).toFixed(2)
					}%`}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius + 4}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
			</g>
		);
	};

	const renderCustomizedLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		percent,
	}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text className="text-xs" x={x} y={y} fill="white">
				{`${
					(percent * 100).toFixed(2).endsWith('.00')
						? (percent * 100).toFixed(0)
						: (percent * 100).toFixed(2)
				}%`}
			</text>
		);
	};

	const isData = useMemo(() => {
		const total = chartData.reduce((a, b) => a + b.value, 0);
		if (total <= 0) {
			return false;
		}
		return true;
	}, [chartData]);

	const downloadExcelData = async () => {
		try {
			const result = await getIpmAdherenceExcelData({
				farmerCodes,
				companyCropId,
				cropVarietyId: cropVariety,
				states,
				districts,
				fromDate,
				toDate,
			}).unwrap();
			if (result && result?.length) {
				const finalResult = result?.map((item) => ({
					'Farmer Name': `${item?.firstName || ''} ${item?.lastName || ''}`,
					Crop: item?.cropName || '',
					'Crop Variety': item?.varietiName || '',
					'Is Ipm': item?.isIpm ? 'IPM' : 'Non-IPM',
					'Total Acres': item?.totalAcres || '',
					Village: item?.village || '',
					Mandal: item?.mandal || '',
					District: item?.district || '',
					State: item?.state || '',
					PinCode: item?.postcode || '',
				}));
				const exportData = {
					data: finalResult,
					fileName: 'IPM_Adherence',
					exportType: 'csv',
				};
				exportFromJSON(exportData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const dropdownBtns = [
		{
			label: 'Export To Excel',
			action: async () => {
				await downloadExcelData();
			},
			isDisabled: !chartData.length || !isData,
		},
	];

	return (
		<>
			<DashboardChartHeader text="IPM Adherence" dropdownBtns={dropdownBtns} />
			{isLoading || customLoading ? (
				<div className="card-center">
					<Loading />
				</div>
			) : isError ? (
				<div className="card-center">
					<ErrorOccured fullScreenMode msg={error} />
				</div>
			) : chartData.length && isData ? (
				<ResponsiveContainer width="100%" height="100%" className="p-2.5">
					<PieChart height={chartHeight} width={chartWidth}>
						<Pie
							activeIndex={activeIndex}
							data={chartData}
							dataKey="value"
							cx="40%"
							cy="40%"
							activeShape={renderActiveShape}
							label={renderCustomizedLabel}
							labelLine={false}
							outerRadius={100}
							minAngle={10}
						>
							{chartData.map((entry) => (
								<Cell key={entry.id} fill={entry?.color?.colorHex} />
							))}
						</Pie>
						<Legend
							className="hidden lg:block h-64 overflow-y-auto scrollbar ml-3"
							align="right"
							layout="vertical"
							verticalAlign="middle"
							wrapperStyle={{ right: '5%', top: '10%' }}
							content={
								<Table
									data={chartData}
									columns={columns}
									theadClassName="w-full text-center text-sm font-semibold"
									tdClassName="text-xs font-normal"
								/>
							}
						/>
						<Tooltip content={<CustomToolTip />} />
					</PieChart>
				</ResponsiveContainer>
			) : (
				<h6 className="card-center text-base font-normal text-black text-center">
					No Data found
				</h6>
			)}
		</>
	);
};

export default IPMAdherenceChart;
