import React from 'react';

export const DefaultFilterForColumn = ({ column = {} }) => {
	const { filterValue = '', setFilter, id } = column;
	return (
		<input
			className="rounded-md px-2 py-1.5 text-sm font-normal bg-white mt-1 text-black w-full"
			key={id}
			value={filterValue || ''}
			onChange={(e) => {
				setFilter(e.target.value || undefined);
			}}
			placeholder="Search"
			autoComplete="off"
		/>
	);
};

export const SelectColumnFilter = ({
	column: { filterValue, setFilter },
	options = [],
}) => (
	<select
		className="rounded-md px-2 py-1.5 text-sm font-normal bg-white mt-1 text-black w-full"
		value={filterValue}
		onChange={(e) => {
			setFilter(e.target.value || undefined);
		}}
	>
		<option value="">All</option>
		{options.map((option) => (
			<option key={option.value} value={option.value}>
				{option?.label}
			</option>
		))}
	</select>
);
