/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { addDays } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorOccured from '../../components/ErrorOccured';
import Loading from '../../components/Loading';
import NoDataFound from '../../components/NoData';
import Table from '../../components/Table';
import { SelectColumnFilter } from '../../components/Table/Filters';
import { useGetFilters } from '../../hooks';
import { useGetIPMAdherenceReportDataQuery } from '../../redux/api/slices/dashboardApiSlice';
import {
	API_DATE_FORMAT,
	DEFAULT_GUID,
	exportToExcel,
	prepareExportData,
} from '../../util';
import { getCurrentFormattedDate, getFormattedDate } from '../../util/dateUtil';

const IPMAdherenceReport = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [ranges, setRanges] = useState();
	const [isSmthngLoading, setIsSmthngLoading] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnSortBy, setColumnSortBy] = useState([]);
	const filters = useGetFilters();
	const [pageSize, setPageSize] = useState(20);

	const {
		data: IPMAdherenceReportData = [],
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetIPMAdherenceReportDataQuery(
		selectedCrop?.length &&
			ranges?.length &&
			ranges[0]?.startDate &&
			ranges[0]?.endDate
			? {
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety ? selectedCropVariety : [''],
					states: selectedStates,
					districts: selectedDistricts,
					fromDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.startDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
					toDate:
						ranges?.length &&
						getFormattedDate({
							date: addDays(new Date(ranges[0]?.endDate), 1),
							reqFormat: API_DATE_FORMAT,
						}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setRanges(filters.ranges);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
		}
	}, [filters]);

	const columns = useMemo(
		() => [
			{
				Header: 'Farmer name',
				accessor: 'firstName',
				Cell: ({ row }) => (
					<p className="text-left">{`${row?.original?.firstName || ''} ${
						row?.original?.lastName || ''
					} `}</p>
				),
			},
			{
				Header: 'Crop',
				accessor: 'cropName',
				Cell: ({ row }) => <p>{row?.original?.cropName}</p>,
			},
			{
				Header: 'Crop Variety',
				accessor: 'varietiName',
				Cell: ({ row }) => <p>{row?.original?.varietiName}</p>,
			},
			{
				Header: 'Is IPM',
				accessor: 'isIpm',
				Cell: ({ row }) => (
					<div>
						<p className="bg-green-200 rounded-md py-1 text-center text-sm">
							{!row?.original?.isIpm ? 'Non-IPM' : 'IPM'}
						</p>
					</div>
				),
				width: 120,
				Filter: ({ column }) => {
					const options = [
						{
							label: 'IPM',
							value: true,
						},
						{
							label: 'Non-IPM',
							value: false,
						},
					];
					return SelectColumnFilter({ column, options });
				},
				filter: 'exactTextCase',
			},
			{
				Header: 'Acres',
				accessor: 'totalAcres',
				Cell: ({ row }) => <p>{row?.original?.totalAcres}</p>,
				width: 120,
			},
		],
		[]
	);

	if (isLoading || isFetching) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	const handleExportToExcel = async () => {
		setIsSmthngLoading(true);
		const exportData = await prepareExportData({
			actualData: IPMAdherenceReportData,
			appliedFilters: columnFilters,
			appliedSortBy: columnSortBy,
		});

		const sheetData = exportData.map((IPMAdherenceData, index) => ({
			'Sl. No': index + 1,
			'Farmer Name': `${IPMAdherenceData?.firstName} ${
				IPMAdherenceData?.lastName || ''
			}`,
			Crop: IPMAdherenceData?.cropName || '',
			'Crop Variety': IPMAdherenceData?.varietiName || '',
			'Is IPM': IPMAdherenceData?.isIpm ? 'IPM' : 'Non-IPM',
			Acres: IPMAdherenceData?.totalAcres,
			Village: IPMAdherenceData?.village || '',
			Mandal: IPMAdherenceData?.mandal || '',
			District: IPMAdherenceData?.district || '',
			State: IPMAdherenceData?.state || '',
			PinCode: IPMAdherenceData?.postcode || '',
		}));
		try {
			await exportToExcel({
				sheetJsonData: sheetData,
				sheetName: `IPM_Adherence_Report_${getCurrentFormattedDate({})}.xlsx`,
			});
			setIsSmthngLoading(false);
		} catch (e) {
			setIsSmthngLoading(false);
		}
	};

	return (
		<div className="bg-white px-3 pt-3 pb-2.5 drop-shadow-sm rounded-lg">
			{IPMAdherenceReportData?.length ? (
				<Table
					data={IPMAdherenceReportData}
					columns={columns}
					className="overflow-x-hidden w-full h-full card table border-0 shadow-none executivesTable mt-4"
					theadClassName="thead text-sm font-medium text-white text-left"
					tdClassName="px-2.5 py-1.5 flex items-center td text-sm font-normal text-disabledText border-0 border-border2"
					displayTotal
					isFilterable
					title="IPM Adherence Report"
					displayExcelExport
					handleExportToExcel={handleExportToExcel}
					isExportInprogress={isSmthngLoading}
					initialState={{ filters: columnFilters, sortBy: columnSortBy }}
					setFilters={setColumnFilters}
					setSortBy={setColumnSortBy}
					isPaginagtion={true}
					defaultPageSize={pageSize}
				/>
			) : (
				<>
					<h2>IPM Adherence Report</h2>
					<NoDataFound />
				</>
			)}
		</div>
	);
};

export default IPMAdherenceReport;
