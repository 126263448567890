import { configureStore } from '@reduxjs/toolkit';
import apiSlice from './api/apiSlice';
import { authReducer } from './api/slices/userApiSlice';
import {
	dashboardApiSlice,
	dashboardReducer,
} from './api/slices/dashboardApiSlice';
import { fileDownloadApi } from './api/downloadSlice';

const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		[dashboardApiSlice.reducerPath]: dashboardApiSlice.reducer,
		[fileDownloadApi.reducerPath]: fileDownloadApi.reducer,
		auth: authReducer,
		dashboard: dashboardReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		})
			.concat(apiSlice.middleware)
			.concat(fileDownloadApi.middleware),
	devTools: true,
});

export default store;
