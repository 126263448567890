/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import Loading from '../components/Loading';
import ErrorOccured from '../components/ErrorOccured';
import { useGetCompanyDistrictWiseStageImagesQuery } from '../redux/api/slices/companyApiSlice';
import { API_DATE_FORMAT } from '../util';
import { useGetFilters } from '../hooks';
import { getFormattedDate } from '../util/dateUtil';
import DistrictAndStageImages from '../components/DistrictWiseStage';
import NoDataFound from '../components/NoData';

const DistrictAndStageWiseImages = () => {
	const [selectedCrop, setSelectedCrop] = useState(['']);
	const [selectedCropVariety, setSelectedCropVariety] = useState(['']);
	const [selectedStates, setSelectedStates] = useState(['']);
	const [selectedDistricts, setSelectedDistricts] = useState(['']);
	const [selectedFarmers, setSelectedFarmer] = useState(['']);
	const [ranges, setRanges] = useState();
	const { cId } = useParams();
	const filters = useGetFilters();

	const {
		data: districtWiseStageImages,
		isLoading,
		isFetching,
		isError,
		error,
	} = useGetCompanyDistrictWiseStageImagesQuery(
		cId && selectedCrop && ranges?.length
			? {
					companyId: cId,
					companyCropId: selectedCrop,
					cropVarietyId: selectedCropVariety || [''],
					states: selectedStates,
					districts: selectedDistricts,
					farmerCodes: selectedFarmers,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
			  }
			: skipToken
	);

	useEffect(() => {
		if (filters && Object.keys(filters || {}).length) {
			setSelectedCrop(filters.crops);
			setSelectedCropVariety(filters.cropVarieties);
			setSelectedStates(filters.states);
			setSelectedDistricts(filters.districts);
			setRanges(filters.ranges);
			setSelectedFarmer(filters.farmerCodes);
		}
	}, [filters]);

	if (isLoading || isFetching || districtWiseStageImages === undefined) {
		return <Loading fullScreenMode />;
	}

	if (districtWiseStageImages.length === 0) {
		return (
			<>
				<h2>No Images Found</h2>
				<NoDataFound />
			</>
		);
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	return <DistrictAndStageImages data={districtWiseStageImages} />;
};

export default DistrictAndStageWiseImages;
