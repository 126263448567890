import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import ErrorOccured from '../components/ErrorOccured';
import GenerateInitialsSvg from '../components/GenerateInitialsSvg';
import Loading from '../components/Loading';
import { useAuth } from '../hooks';
import { useGetCompanyUserProfileInfoQuery } from '../redux/api/slices/userApiSlice';

const profilePicClassname =
	'rounded-full mx-auto w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110';

const CompanyUserProfile = () => {
	const auth = useAuth();
	const {
		data: userInfo,
		isLoading,
		isError,
		error,
	} = useGetCompanyUserProfileInfoQuery(auth.userId ? auth.userId : skipToken);

	if (isLoading) {
		return <Loading fullScreenMode />;
	}

	if (isError) {
		return <ErrorOccured fullScreenMode msg={error} />;
	}

	return (
		<div className="bg-white h-screen flex flex-col items-center justify-center">
			{/* <h3>User Profile</h3> */}

			<div className="bg-[#fafafa] shadow-lg rounded-xl w-full md:w-4/6 lg:w-3/6 xl:w-2/6 border border-sfBorder px-4 py-5">
				<div className="flex justify-center items-center">
					{userInfo.profilePic ? (
						<img
							src={userInfo.profilePic}
							alt=""
							className={`${profilePicClassname} object-contain`}
						/>
					) : (
						<GenerateInitialsSvg
							text={`${userInfo.firstName} ${userInfo.lastName || ''}`}
							className={profilePicClassname}
							textColor="#FA9527"
							bgColor="#E0E1E8"
						/>
					)}
				</div>

				<div className="mt-5 flex flex-col justify-center">
					<h1 className="font-semibold text-center break-all text-3xl text-black">
						{`${userInfo.firstName} ${userInfo.lastName || ''}`}
					</h1>
					<p className="text-center text-sm text-disabledText font-normal mt-2">
						{userInfo.email}
					</p>
					<p className="text-center text-base text-halfGreen font-medium mt-1">
						{`${userInfo.countryCode} ${userInfo.phone}`}
					</p>
				</div>
			</div>
		</div>
	);
};

export default CompanyUserProfile;
