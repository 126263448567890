/* eslint-disable react/no-unstable-nested-components */
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useMemo, useState } from 'react';
import {
	PieChart,
	Pie,
	Sector,
	Cell,
	ResponsiveContainer,
	Legend,
	Tooltip,
} from 'recharts';
import exportFromJSON from 'export-from-json';
import {
	useGetExpectedHarvestChartDataQuery,
	useGetExpectedHarvestExcelDataMutation,
} from '../../redux/api/slices/dashboardApiSlice';
import ErrorOccured from '../ErrorOccured';
import Loading from '../Loading';
import Table from '../Table';
import {
	chartHeight,
	chartWidth,
	CustomToolTip,
	DashboardChartHeader,
	getKeyFromText,
	LegendCellRenderer,
} from './util';
import { monthsMap } from '../../util';

const COLORS = [
	{ tailwindColorName: 'bg-DarkBiscuit', colorHex: '#F06548' },
	{ tailwindColorName: 'bg-Violet', colorHex: '#5D45F4' },
	{ tailwindColorName: 'bg-LightestGreen', colorHex: '#37E5B1' },
	{ tailwindColorName: 'bg-SkyBlue', colorHex: '#45C1FF' },
	{ tailwindColorName: 'bg-LightGrey', colorHex: '#EFF3FF' },
	{ tailwindColorName: 'bg-OrchidRed', colorHex: '#AD878D' },
	{ tailwindColorName: 'bg-Bistre', colorHex: '#3C2317' },
];

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		// percent,
		// value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'start' : 'end';

	return (
		<g>
			{/* <text
				className="PieChartText"
				x={cx}
				y={cy}
				dy={8}
				textAnchor="middle"
				fill="#3DCC91"
			>
				Strong Buy
			</text> */}
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill="#000"
				className="text-sm font-semibold"
			>{`${payload.name}`}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill="#298E4D"
				className="text-xs font-normal"
			>
				{`${payload.value} Acres`}
			</text>
		</g>
	);
};

const ExpectedHarvestChart = ({
	farmerCodes,
	companyCropId,
	cropVariety,
	states = [],
	districts = [],
	fromDate,
	toDate,
}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const [chartData, setChartData] = useState([]);
	const [customToolTip, setCustomToolTip] = useState([]);
	const [getExpectedHarvestExcelData] =
		useGetExpectedHarvestExcelDataMutation();
	const [customLoading, setCustomLoading] = useState(true);

	const {
		data: expectedHarvestChartData,
		isLoading,
		isError,
		error,
		isFetching,
	} = useGetExpectedHarvestChartDataQuery(
		companyCropId?.length && fromDate && toDate
			? {
				farmerCodes,
					companyCropId,
					cropVarietyId: cropVariety,
					districts,
					states,
					fromDate,
					toDate,
			  }
			: skipToken
	);

	useEffect(() => {
		if (isFetching) {
			setCustomLoading(true);
		}
	}, [isFetching]);

	const columns = useMemo(
		() => [
			{
				Header: 'Month',
				accessor: 'name',
				Cell: ({ row }) => <LegendCellRenderer row={row} />,
				width: 150,
			},
			{
				Header: <p>Acres</p>,
				accessor: 'value',
				Cell: ({ row }) => (
					<p>
						{((row?.original?.value || 0) % 1 !== 0
							? parseFloat(row?.original?.value).toFixed(1)
							: row?.original?.value) || 0}
					</p>
				),
				width: 60,
			},
			// {
			// 	Header: <p>Tonnes</p>,
			// 	accessor: 'tonnes',
			// 	Cell: ({ row }) => (
			// 		<p>
			// 			{((row?.original?.tonnes || 0) % 1 !== 0
			// 				? parseFloat(row?.original?.tonnes).toFixed(1)
			// 				: row?.original?.tonnes) || 0}
			// 		</p>
			// 	),
			// 	width: 60,
			// },
		],
		[]
	);

	useEffect(() => {
		const mData = (expectedHarvestChartData || []).map((item, index) => {
			const monthName = monthsMap[item.month];
			return {
				id: getKeyFromText(monthName),
				name: monthName,
				value: parseFloat(item?.windowMonth?.toFixed(1)),
				color: COLORS[index % COLORS.length],
				tonnes: item.expectedYield,
			};
		});
		setCustomToolTip(
			mData.map((item, index) => ({
				id: index,
				name: item.name,
				value: item.tonnes,
				unit: 'Tonnes',
			}))
		);
		setChartData(mData);
		setCustomLoading(false);
	}, [expectedHarvestChartData]);

	const downloadExcelData = async () => {
		try {
			const result = await getExpectedHarvestExcelData({
				farmerCodes,
				companyCropId,
				cropVarietyId: cropVariety,
				states,
				districts,
				fromDate,
				toDate,
			}).unwrap();
			if (result && result?.length) {
				const finalResult = result?.map((item) => ({
					'Farmer Name': item?.firstName || '',
					'Farm Area': item?.farmArea || '',
					Crop: item?.cropName || '',
					'Crop Variety': item?.cropVarietyName || '',
					Month: item?.monthName || '',
					'Cultivated Area': item?.cultivatedArea || '',
					'Expected Yield': item?.expectedYield || '',
					Mandal: item?.mandal || '',
					Village: item?.village || '',
					District: item?.district || '',
					State: item?.state || '',
					Postcode: item?.postcode || '',
				}));
				const exportData = {
					data: finalResult,
					fileName: 'Expected_Harvest',
					exportType: 'csv',
				};
				exportFromJSON(exportData);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const dropdownBtns = [
		{
			label: 'Export To Excel',
			action: async () => {
				await downloadExcelData();
			},
			isDisabled: !chartData.length,
		},
	];

	return (
		<>
			<DashboardChartHeader
				text="Expected Harvest"
				subText="(Month, Acres)"
				dropdownBtns={dropdownBtns}
			/>
			{isLoading || customLoading ? (
				<div className="card-center">
					<Loading />
				</div>
			) : isError ? (
				<div className="card-center">
					<ErrorOccured fullScreenMode msg={error} />
				</div>
			) : chartData.length ? (
				<ResponsiveContainer width="100%" height="100%" className="p-2.5">
					<PieChart width={chartWidth} height={chartHeight}>
						<Pie
							dataKey="value"
							isAnimationActive={false}
							activeIndex={activeIndex}
							activeShape={renderActiveShape}
							data={chartData}
							cx="40%"
							cy="40%"
							labelLine={false}
							innerRadius={60}
							outerRadius={95}
							minAngle={10}
						>
							{chartData.map((entry, index) => (
								<Cell
									key={entry.id}
									fill={entry?.color?.colorHex}
									onMouseEnter={() => setActiveIndex(index)}
								/>
							))}
						</Pie>
						<Legend
							className="hidden lg:block h-64 overflow-y-auto scrollbar ml-3"
							align="right"
							layout="vertical"
							verticalAlign="middle"
							wrapperStyle={{ right: '5%', top: '10%' }}
							content={
								<Table
									data={chartData}
									columns={columns}
									theadClassName="w-full text-center text-sm font-semibold"
									tdClassName="text-xs font-normal"
								/>
							}
						/>
						<Tooltip
							content={
								<CustomToolTip unit="Acres" customToolTip={customToolTip} />
							}
						/>
					</PieChart>
				</ResponsiveContainer>
			) : (
				<h3 className="card-center text-xl font-medium">No Data found</h3>
			)}
		</>
	);
};

export default ExpectedHarvestChart;
