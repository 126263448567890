/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	useResetPasswordMutation,
	setCredentials,
} from '../redux/api/slices/userApiSlice';
import Popup from '../components/Popup';

const ResetPassword = ({userName}) => {
	const dispatch = useDispatch();
	const [reset] = useResetPasswordMutation();
	const [showPopup, setShowPopup] = useState(false);
	const [otp, setOtp] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const navigate = useNavigate();
   const handleResetPassword = async (e) => {
		e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setErrMsg("New password and confirm password don't match.");
      return;
    }



		if (otp && newPassword && userName) {
			try {
				const result = await reset({
					Id: null,
					otpCode: otp,
					userName,
					oldPassword: null,
					newPassword,
					changeType: 'FORGOT',
				}).unwrap();
				if (result) {
					const { status } = result;
					if (status === 'Password changed successfully') setShowPopup(true);
					else setErrMsg(status);
				}
				setOtp('');
				setConfirmNewPassword('');
				setNewPassword('');
			} catch (err) {
				setErrMsg(err);
				console.error('Something went wrong!! error::', err);
			}
		}
	};
	const closePopup = () => {
		setShowPopup(false);
		dispatch(setCredentials({ user: null }));
		navigate(`/login`);
	};
	if (showPopup) {
		return (
			<Popup
				message="Password Changed Successfully.Please login"
				onClose={closePopup}
				isOpen={showPopup}
			/>
		);
	}

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full sm:w-96">
        <h1 className="text-2xl font-semibold mb-4">Reset Password</h1>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="otp">
            OTP
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="otp"
            type="number"
            placeholder="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
           <p className="text-RedOrange py-4 text-xs">*Please use the code sent to your registered email to reset your password</p>
         </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="newPassword">
            New Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="newPassword"
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="confirmNewPassword">
            Confirm New Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmNewPassword"
            type="password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        {errMsg && <p className="errMsg">{errMsg}</p>}
        
        <button
         className="w-auto p-2 flex flex-row justify-center items-center rounded-md bg-primary outline outline-1 outline-white text-white hover:outline-halfGreen ml-2 disabled:cursor-not-allowed disabled:bg-disableColor"
          onClick={handleResetPassword}
          disabled={!(otp?.length>1 && newPassword.length>1 && confirmNewPassword?.length>1)}
          
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
