/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from '../hooks';
import Filters from '../pages/Filters';
import NavBar from '../pages/NavBar';
import { logout } from '../redux/api/slices/userApiSlice';

const RequireAuth = () => {
	const [showExpandedSideBar, setShowExpandedSideBar] = useState();
	const auth = useAuth();
	const location = useLocation();
	const activeTab = location?.pathname;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		(async () => {
			const hideSideBar = await localStorage.getItem('showExpandedSideBar');
			setShowExpandedSideBar(hideSideBar === 'true');
		})();
	}, []);

	useEffect(() => {
		auth && Object.keys(auth).length && authCheck(auth);
	}, [auth, location]);

	const authCheck = async (localUserData) => {
		const { accessToken, userId, tokenExpiryDate } = localUserData;
		const expirationDate = new Date(tokenExpiryDate);
		if (expirationDate <= new Date() || !accessToken || !userId) {
			await dispatch(logout());
			navigate('/login');
		}
	};

	return auth?.userName ? (
		<main>
			<NavBar
				activeTab={activeTab}
				showExpandedSideBar={showExpandedSideBar}
				setShowExpandedSideBar={setShowExpandedSideBar}
			/>
			<section className={`abcp-4 ${showExpandedSideBar ? 'ml-56' : 'ml-16'}`}>
				{(activeTab || '').includes('user-profile') ||
				(activeTab || '').includes('change-password') ? null : (
					<Filters
						hideFiltersForMap={(activeTab || '').includes('/summary')}
						hideDateFilters={(activeTab || '').includes('executives')}
					/>
				)}
				<div className="px-4">
					{/* p-4 pt-20 */}
					<Outlet />
				</div>
			</section>
		</main>
	) : (
		<Navigate to="/login" state={{ from: location }} />
	);
};

export default RequireAuth;
