import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import apiSlice from '../apiSlice';

const dashboardAdapter = createEntityAdapter({});
// eslint-disable-next-line no-unused-vars
const initialState = dashboardAdapter.getInitialState();

export const dashboardApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getIPMNonIPMStats: builder.query({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
				farmerCodes = [],
			}) => ({
				url: `dashboard/get-ipm-non-ipm-acres-count`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCropVarietiesChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-crop-varieties-in-terms-acres`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCropVarietiesExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-crop-varieties-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCropStagesWithDaysChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-crop-stages-with-days`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCropGradeWisePercentageChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-grade-wise-data`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getCropStagesWithDaysExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-crop-stages-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getSowingWindowChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-sowing-window-seasons-in-months`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getSowingWindowExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-sowingWindows-seasons-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getExpectedHarvestChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-expected-harvest-in-months`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getExpectedHarvestExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-expected-harvests-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getChemicalCompositionChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-farm-chemical-compositions`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getChemicalCompositionExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-farm-chemical-compositions-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getIpmAdherenceChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-company-ipm-adherents`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getIpmAdherenceExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-farm-ipmAdherence-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getGradeWiseExcelData: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-grade-wise-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getChemicalCompositionReportData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
				filterValue = '',
			}) => ({
				url: `dashboard/get-farm-chemical-compositions-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					filterValue,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getDiseaseReportData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
				filterValue = '',
			}) => ({
				url: `dashboard/get-diseases-wise-percentage-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					filterValue,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getIPMAdherenceReportData: builder.query({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-farm-ipmAdherence-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
				},
				method: 'POST',
			}),
		}),
		getStateAndDistrictData: builder.mutation({
			query: ({ companyId = '', fromDate, toDate }) => ({
				url: `dashboard/get-state-districts-maps-report`,
				method: 'POST',
				body: {
					companyId,
					fromDate,
					toDate,
				},
			}),
		}),
		getFarmerDataForMaps: builder.mutation({
			query: ({ farmDetailId = '', companyId = '' }) => ({
				url: `dashboard/get-farmdetails-maps-report?farmDetailId=${farmDetailId}&companyId=${companyId}`,
			}),
		}),
		getDiseasesPieChartData: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-diseases-wise-percentage-data`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getDiseasesPiechartExcelData: builder.mutation({
			query: ({
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `dashboard/get-diseases-wise-percentage-excel`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
				},
				method: 'POST',
			}),
		}),
		getApplicationSettings: builder.query({
			query: () => 'configuration/get-ft-config-details',
		}),
		getFarmLandDetailsActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-farmland-details-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getNurseryDetailsActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-nursery-details-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),

		getMainFieldOperationActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-mainfield-details-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),
		getNutrientActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-nutrient-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),

		getProductSamplingActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-product-sampling-activity-report`,
				body: {
					farmerCodes,
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
				},
				method: 'POST',
			}),
		}),

		getPostHarvestActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-post-harvest-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),

		getIrrigationActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-irrigation-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),

		getPestActivityReport: builder.query({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-pest-activity-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
			}),
		}),

		getTableHeaderMetajson: builder.query({
			query: ({ cropCompanyId = '', tablename = '', tableHeaders = '' }) => ({
				url: `reports/get-table-header-metajson`,
				body: {
					cropCompanyId,
					tablename,
					tableHeaders,
				},
				method: 'POST',
			}),
		}),

		getFarmerSummaryReport: builder.mutation({
			query: ({
				farmerCodes = [],
				companyCropId = [],
				cropVarietyId = [],
				states = [],
				districts = [],
				fromDate,
				toDate,
			}) => ({
				url: `reports/get-download-farmer-summary-report`,
				body: {
					companyCropId,
					cropVarietyId,
					states,
					districts,
					fromDate,
					toDate,
					farmerCodes,
				},
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				responseType: 'blob',
			}),
		}),
	}),
});

export const {
	useGetIPMNonIPMStatsQuery,
	useGetCropVarietiesChartDataQuery,
	useGetCropStagesWithDaysChartDataQuery,
	useGetSowingWindowChartDataQuery,
	useGetExpectedHarvestChartDataQuery,
	useGetChemicalCompositionChartDataQuery,
	useGetIpmAdherenceChartDataQuery,
	useGetChemicalCompositionReportDataQuery,
	useGetIPMAdherenceReportDataQuery,
	useGetCropVarietiesExcelDataMutation,
	useGetExpectedHarvestExcelDataMutation,
	useGetCropStagesWithDaysExcelDataMutation,
	useGetSowingWindowExcelDataMutation,
	useGetChemicalCompositionExcelDataMutation,
	useGetIpmAdherenceExcelDataMutation,
	useGetCropGradeWisePercentageChartDataQuery,
	useGetGradeWiseExcelDataMutation,
	useGetStateAndDistrictDataMutation,
	useGetFarmerDataForMapsMutation,
	useGetDiseasesPieChartDataQuery,
	useGetDiseasesPiechartExcelDataMutation,
	useGetDiseaseReportDataQuery,
	useGetApplicationSettingsQuery,
	useGetFarmLandDetailsActivityReportQuery,
	useGetNurseryDetailsActivityReportQuery,
	useGetMainFieldOperationActivityReportQuery,
	useGetNutrientActivityReportQuery,
	useGetProductSamplingActivityReportQuery,
	useGetPostHarvestActivityReportQuery,
	useGetIrrigationActivityReportQuery,
	useGetPestActivityReportQuery,
	useGetTableHeaderMetajsonQuery,
	useGetFarmerSummaryReportMutation,
} = dashboardApiSlice;

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: {
		configuration: {
			SASToken: '',
			mapNavLabel: '',
		},
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(
			apiSlice.endpoints.getApplicationSettings.matchFulfilled,
			(state, action) => {
				if (action.payload?.id) {
					state.configuration = action.payload?.configuration;
				} else {
					state.configuration = {
						configuration: {
							SASToken: '',
							mapNavLabel: '',
						},
					};
				}
			}
		);
		builder.addMatcher(
			apiSlice.endpoints.getApplicationSettings.matchRejected,
			(state) => {
				state.configuration = {
					configuration: {
						SASToken: '',
						mapNavLabel: '',
					},
				};
			}
		);
	},
});

export const {
	setCredentials,
	loadAuthFromLocalStorage,
	logout,
	updateFilters,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;

export const getSettingsFromState = (state) =>
	state.dashboard.configuration || {};
