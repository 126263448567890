const orderBy = require('lodash.orderby');

export const DEFAULT_GUID = '0000-0000-0000-0000';

export const DEFAULT_DATE_FORMAT = 'dd MMM, yyyy';
export const DEFAULT_DATETIME_FORMAT = 'dd MMM, yyyy hh:mm a';

export const API_DATE_FORMAT = 'yyyy-MM-dd';

export const defaultAllOption = {
	id: DEFAULT_GUID,
	label: 'All',
};

export const reportsObject = {
	FARM_LAND_DETAILS_ACTIVITY: 'Farm Land Details Activity Report',
	MAIN_FIELD_ACTIVITY: 'Main field operation Activity Report',
	NUTRIENT_ACTIVITY: 'Nutrient management Activity Report',
	PEST_ACTIVITY: 'Pest management Activity Report',
	IRRIGATION_ACTIVITY: 'Irrigation management Activity Report',
};

export const monthsMap = {
	1: 'January',
	2: 'February',
	3: 'March',
	4: 'April',
	5: 'May',
	6: 'June',
	7: 'July',
	8: 'August',
	9: 'September',
	10: 'October',
	11: 'November',
	12: 'December',
};

export const prepareExportData = async ({
	actualData = [],
	appliedFilters = [],
	appliedSortBy = [],
}) => {
	let formattedData = actualData;
	if (appliedSortBy?.length) {
		const cols = [];
		const desc = [];
		appliedSortBy.forEach((sortBy) => {
			cols.push(sortBy?.id);
			desc.push(sortBy?.desc ? 'desc' : 'asc');
		});
		formattedData = orderBy(formattedData, cols, desc);
	}
	if (appliedFilters?.length) {
		appliedFilters.forEach((filter) => {
			formattedData = formattedData.filter((o) =>
				`${o[filter?.id]}`
					.toLowerCase()
					.includes(`${filter?.value}`.toLowerCase())
			);
		});
	}
	return formattedData;
};

export const exportToExcel = async ({
	sheetJsonData,
	sheetName = 'data.xlsx',
}) =>
	new Promise((resolve, reject) => {
		try {
			import('xlsx').then((XLSX) => {
				try {
					const worksheet = XLSX.utils.json_to_sheet(sheetJsonData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
					XLSX.writeFile(workbook, sheetName);
					resolve('Success');
				} catch (e) {
					console.error('exportToExcel fn | inside error::', e);
					reject(new Error(e));
				}
			});
		} catch (error) {
			console.error('exportToExcel fn | error::', error);
			reject(new Error(error));
		}
	});

export const renderAcres = ({ val = 0 }) =>
	val % 1 !== 0 ? parseFloat(val).toFixed(1) : val || 0;

export const sortByAlphabetOrder = (a, b, order) => {
	if (order === 'asc') {
		if (a > b) {
			return 1;
		}
		if (b > a) {
			return -1;
		}
		return 0;
	}
	if (a > b) {
		return -1;
	}
	if (b > a) {
		return 1;
	}
	return 0;
};

// ToDo: Need to get this data from API/DB
export const getSeasonWiseCalender = () => {
	const options = [];
	options.push({
		id: 0,
		label: 'Red Chilli-Kharif',
		crop: 'Red Chilli',
		season: 'Kharif',
		value: { fromDate: '2024-06-01', toDate: '2025-05-31' },
	});
	options.push({
		id: 1,
		label: 'Turmeric',
		crop: 'Turmeric',
		season: 'Kharif',
		value: { fromDate: '2023-06-01', toDate: '2024-05-31' },
	});
	options.push({
		id: 2,
		label: 'Maize-Rabi',
		crop: 'Maize',
		season: 'Rabi',
		value: { fromDate: '2023-09-01', toDate: '2024-03-31' },
	});
	options.push({
		id: 3,
		label: 'Maize-Kharif',
		crop: 'Maize',
		season: 'Kharif',
		value: { fromDate: '2023-04-01', toDate: '2023-08-31' },
	});
	options.push({
		id: 4,
		label: 'Soybean-Rabi',
		crop: 'Soybean',
		season: 'Rabi',
		value: { fromDate: '2023-11-01', toDate: '2024-03-31' },
	});
	options.push({
		id: 5,
		label: 'Soybean-Kharif',
		crop: 'Soybean',
		season: 'Kharif',
		value: { fromDate: '2023-06-01', toDate: '2023-10-31' },
	});

	return options;
};
