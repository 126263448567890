/* eslint-disable react/button-has-type */
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Loading from '../components/Loading';
import { useAuth } from '../hooks';
import { BighaatSvgLogo } from '../icons';
import {
	setCredentials,
	useLoginMutation,
} from '../redux/api/slices/userApiSlice';

const Login = () => {
	const [login] = useLoginMutation();
	const [loadingAuth, setLoadingAuth] = useState(true);
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const dispatch = useDispatch();
	const fieldRef = useRef();
	const navigate = useNavigate();
	const auth = useAuth();
	const location = useLocation();
	const from =
		location?.state?.from?.pathname && location?.state?.from?.pathname !== '/'
			? location?.state?.from?.pathname
			: `/cid/${auth?.companyIds?.length && auth?.companyIds[0]}/dashboard`;

	useEffect(() => {
		if (auth?.userName && from) {
			navigate(
				from ||
					`/cid/${auth?.companyIds?.length && auth?.companyIds[0]}/dashboard`
			);
		} else {
			setLoadingAuth(false);
		}
	}, [auth]);

	useEffect(() => {
		if (!loadingAuth) {
			fieldRef.current.focus();
		}
	}, [loadingAuth]);

	const handleLoginFormSubmit = async (e) => {
		e.preventDefault();
		if (userName && password) {
			try {
				const result = await login({ userName, password }).unwrap();
				const userData = { ...result };
				userData.tokenExpiryDate = new Date(
					new Date().getTime() + userData.tokenExpiryDays * 86400 * 1000
				);
				dispatch(setCredentials({ user: userData }));
				setUserName('');
				setPassword('');
				navigate(
					`/cid/${
						result?.companyIds?.length && result?.companyIds[0]
					}/dashboard`
				);
			} catch (error) {
				setErrMsg(error?.data);
				console.error('Something went wrong!! error::', error);
			}
		}
	};

	if (loadingAuth) {
		return <Loading fullScreenMode color="green" />;
	}

	return (
		<section className="w-full min-h-screen overflow-hidden bg-gradient-to-tr from-primary via-primary to-gradientEndGreen flex flex-row px-10">
			<div className="w-1/2 flex flex-col m-auto items-end">
				<h3 className="text-3xl font-medium text-white absolute">Powered by</h3>
				<div className="flex flex-row">
					<BighaatSvgLogo />
					{/* <h3 className="text-3xl font-medium text-white pb-4">.com</h3> */}
				</div>
				{/* <h3 className="text-2xl font-medium text-white pl-44 pb-4 tracking-widest">
					ONE STOP AGRO STORE
				</h3> */}
			</div>
			<div className="w-1/2 flex justify-center items-center">
				<div className="w-80 px-6 py-8 bg-white border-t rounded-2xl shadow-lg lg:max-w-md">
					<h1 className="text-2xl font-semibold pb-6 text-green-500">Login</h1>
					<form className="mt-4" onSubmit={handleLoginFormSubmit}>
						{errMsg && <p className="errMsg">{errMsg}</p>}
						<div>
							<label htmlFor="username" className="block text-sm text-gray-800">
								Email
								<input
									type="email"
									id="username"
									className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
									ref={fieldRef}
									autoComplete="off"
									onChange={(e) => setUserName(e.target.value)}
									value={userName}
									required
								/>
							</label>
						</div>
						<div className="mt-6">
							<div>
								<label
									htmlFor="password"
									className="block text-sm text-gray-800"
								>
									Password
									<input
										id="password"
										type="password"
										className="block w-full px-4 py-2 mt-2 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300  focus:outline-none focus:ring focus:ring-opacity-40"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
										autoComplete="off"
										required
									/>
								</label>
							</div>
							<div className="mt-6">
								<Link
									to="/forgot-password"
									className="hover:text-secondary"
								>
									Forgot Password
								</Link>
							</div>
							<div className="mt-8">
								<button
									type="submit"
									className="w-full px-4 py-2 text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-secondary tracking-wider"
								>
									LOGIN
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Login;
