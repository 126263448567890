/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import { ExportIcon } from '../icons';
import Loading from './Loading';

const SidePopupMenu = React.memo(({ dropdownBtns = [] }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const popupRef = useRef(null);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (popupRef.current && !popupRef.current?.contains(event?.target)) {
			setShowMenu(false);
		}
	};

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const handleClick = async (btn) => {
		setIsLoading(true);
		(await btn?.action) && (await btn.action());
		setIsLoading(false);
		toggleMenu();
	};

	if (dropdownBtns.length) {
		return (
			<div className="text-left">
				<div className="relative">
					<button
						type="button"
						className="cursor-pointer flex flex-col justify-center items-center"
						onClick={() => toggleMenu()}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
						>
							<circle cx="12" cy="5" r="2" />
							<circle cx="12" cy="12" r="2" />
							<circle cx="12" cy="19" r="2" />
						</svg>
					</button>
					{showMenu && (
						<div
							ref={popupRef}
							className="absolute top-10 right-0 w-40  bg-white border border-gray-300 rounded-md shadow-md p-2 z-10"
						>
							{dropdownBtns.map((btn) => (
								<button
									type="button"
									className="w-auto p-2 relative flex flex-row min-w-full justify-start items-center rounded-md text-gray-800 hover:bg-gray-200 text-sm font-medium 
									cursor-pointer disabled:cursor-not-allowed disabled:bg-disableColor"
									onClick={() => {
										handleClick(btn);
									}}
									disabled={isLoading || btn?.isDisabled}
								>
									{isLoading ? (
										<div className="flex w-full h-6 items-center  justify-center">
											<Loading className="h-4 w-4" />
										</div>
									) : (
										<>
											{btn?.icon ? (
												btn.icon
											) : (
												<ExportIcon className="h-5 w-5 mr-1.5" color="black" />
											)}
											{btn.label}
										</>
									)}
								</button>
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
	return null;
});

export default SidePopupMenu;
