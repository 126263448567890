/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import MapPopUp from './MapPopup';
import Loading from '../Loading';
import NoDataFound from '../NoData';
import RedPinIcon from '../../icons/map/location_pin.png';
import GreenPinIcon from '../../icons/map/location_pin_green.png';


const DistrictMap = ({
	coordinates = [20.5934, 84.2223],
	districtDetails = {},
	totalData = {},
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const mapRef = useRef(null);
	const [farmerId, setFarmerId] = useState('');
	const [showSummeryPopup, setShowSummeryPopup] = useState(false);
	const [markers, setMarkers] = useState([0, 0]);

	const getIconByIpm=(isIpm)=>{
		if(isIpm==="true"){
			return new Icon({
				iconUrl: GreenPinIcon,
				iconSize: [38, 38],
			});
		}
		return new Icon({
				iconUrl: RedPinIcon,
				iconSize: [38, 38],
			});
		

	}

	useEffect(() => {
		if (!isLoading) {
			setTimeout(() => {
				mapRef.current?.flyTo(coordinates, 10, { duration: 1 });
				setTimeout(() => {
					const zoomRef = mapRef.current?.getZoom();
					mapRef.current?.setMinZoom(zoomRef);
				}, 1000);
			}, 750);
		}
	}, [mapRef, isLoading]);

	const getStateAndDistrictCoordinates = async (district) => {
		setIsLoading(true);
		const { id, stateId } = district;
		try {
			const response = totalData[stateId]?.mapDistricts[id];
			if (response && Object.keys(response)?.length) {
				const temp = [];
				const flattenData = response?.farmDetails?.flat();
				flattenData.forEach((farm) => {
					temp.push({
						geocode: farm?.location?.Coordinates?.length
							? farm?.location?.Coordinates
							: [0, 0],
						// eslint-disable-next-line no-underscore-dangle
						farmerDetailsId: farm._id,
						isIpm:farm.isIpm,
					});
				});
				setMarkers(temp);
				setIsLoading(false);
				return true;
			}
			setMarkers([]);
			setIsLoading(false);
		} catch (error) {
			setMarkers([]);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (
			Object.keys(districtDetails || {})?.length &&
			Object.keys(totalData || {})?.length
		) {
			getStateAndDistrictCoordinates(districtDetails);
		} else {
			setMarkers([]);
			setIsLoading(false);
		}
	}, [districtDetails]);

	return (
		<>
			{isLoading ? (
				<div className="flex justify-center items-center">
					<Loading />
				</div>
			) : markers?.length ? (
				<>
					{showSummeryPopup ? (
						<MapPopUp
							geoTagId={farmerId}
							districtDetails={districtDetails}
							onClose={() => {
								setFarmerId('');
								setShowSummeryPopup(false);
							}}
						/>
					) : null}
					<div className="w-full position">
						<MapContainer
							ref={mapRef}
							className="h-screen z-0"
							center={coordinates}
							zoom={5}
							zoomControl={false}
							dragging
							doubleClickZoom={false}
						>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>
							{markers.map((marker, index) => (
								<Marker
									key={index}
									position={marker.geocode}
									icon={getIconByIpm(marker.isIpm)}
									eventHandlers={{
										mousedown: () => {
											setFarmerId(marker?.farmerDetailsId);
											setShowSummeryPopup(!showSummeryPopup);
										},
									}}
								/>
							))}
						</MapContainer>
					</div>
				</>
			) : (
				<NoDataFound />
			)}
		</>
	);
};

export default DistrictMap;
