import React from 'react';

const ChevronDownIcon = (props) => {
	const {
		height = 24,
		width = 24,
		color = '#FA9527',
		fill = 'none',
		strokeWidth = 1.5,
		className = '',
	} = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill={fill}
			viewBox={`0 0 ${height} ${width}`}
			strokeWidth={strokeWidth}
			stroke={color}
			className={className}
		>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M19.5 8.25l-7.5 7.5-7.5-7.5"
			/>
		</svg>
	);
};

export default ChevronDownIcon;
