import React from 'react';

const CropsIcon = (props) => {
	const { fill = '#4eaa6f', strokeWidth = 1.5, className = '' } = props;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				d="m21.996 21.435 1.685.1c-.13 2.475.312 4.941-.357 7.347a8.324 8.324 0 0 1-7.842 6.092c-.609.019-1.218 0-1.928 0v5.024h-3.348V34.97H8.814A8.381 8.381 0 0 1 .04 26.647c-.081-1.67-.013-3.346-.013-5.116l1.728-.091C-.64 18.332.161 14.819.006 11.34h5.946a8.862 8.862 0 0 1 3.065-8.421c.964-.882 1.85-1.849 2.912-2.918 1.614 1.774 3.291 3.383 4.68 5.211a7.717 7.717 0 0 1 1.168 6.083h5.935c-.183 3.451.677 6.955-1.716 10.14ZM11.871 4.967c-3.6 2.7-3.585 6.9.028 9.351 3.569-2.955 3.558-6.557-.023-9.351Zm8.358 19.991c-4.861-.757-7.349 2.819-6.541 6.609 4.627.374 6.939-1.944 6.545-6.609Zm-10.163 6.6c.748-4.654-2.57-7.383-6.59-6.565-.6 4.217 2.035 7.12 6.594 6.567Zm.031-10.242c.683-4.947-3.054-7.264-6.617-6.482-.537 4.401 2.046 6.957 6.616 6.484Zm3.643.042a5.318 5.318 0 0 0 6.437-6.583c-4.249-.646-7.094 2.26-6.433 6.585Z"
				fill={fill}
				fillRule="evenodd"
				strokeWidth={strokeWidth}
			/>
		</svg>
	);
};

export default CropsIcon;
