/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import SidePopupMenu from '../SidePopupMenu';

export const DashboardChartHeader = ({
	text = '',
	subText = '',
	dropdownBtns = [],
}) => (
	<div className="flex items-center justify-between py-3 px-4 border-b border-gray-300 border-dashed">
		<div className="flex items-center">
			<h3 className="text-base font-semibold">{text}</h3>{' '}
			<p className="text-sm pl-2">{subText}</p>
		</div>
		<div>
			<SidePopupMenu dropdownBtns={dropdownBtns} />
		</div>
	</div>
);

export const getKeyFromText = ({ text = '', index = 0, appendText = '' }) =>
	text
		.replace(' ', '-')
		.toLowerCase()
		.concat(`${appendText && `-${appendText}`}-${index}`);

export const chartHeight = '80%';
export const chartWidth = '80%';

export const LegendCellRenderer = ({ row }) => (
	<div className="flex flex-row items-center mb-1.5">
		<div className={`!h-3 !w-3 ${row?.original?.color?.tailwindColorName}`} />
		<p className="w-full box-border pl-1.5">{row?.original?.name}</p>
	</div>
);

export const cropStagesWithDaysMap = {
	Between0to45: '0-45',
	Between46to90: '46-90',
	Between91t0135: '91-135',
	Between136to170: '136-170',
	Above170: 'Above 170',
};

export const CustomToolTip = ({
	active,
	payload,
	unit = '',
	customToolTip = [],
}) => {
	if (active && payload && payload.length) {
		return (
			<div className="bg-cardBg p-2 rounded-md ">
				<p className="text-sm font-semibold text-center">{`${payload[0].name}`}</p>
				<p className="text-sm">{`${payload[0].value} ${unit}`}</p>
				{customToolTip?.length
					? customToolTip.map((item) => {
							if (payload?.length && payload[0]?.name === item?.name) {
								return (
									<p
										key={item?.id}
										className="text-sm"
									>{`${item?.value} ${item?.unit}`}</p>
								);
							}
							return null;
					  })
					: null}
			</div>
		);
	}

	return null;
};

export const encodeChemical = (str) => {
	let replacedStr = '';
	if (str) {
		replacedStr = str.replace(/ /g, '_').replace(/%/g, '$');
	}
	return replacedStr;
};
export const decodeChemical = (str) => {
	let replacedStr = '';
	if (str) {
		replacedStr = str.replace(/_/g, ' ').replace(/\$/g, '%');
	}
	return replacedStr;
};
