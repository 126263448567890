import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { subDays } from 'date-fns/esm';
import { isArray } from 'lodash';
import SelectAsync from 'react-select';
import { getFormattedDate, getEndOfMonth } from '../util/dateUtil';
import {
	API_DATE_FORMAT,
	// renderAcres,
	DEFAULT_GUID,
	sortByAlphabetOrder,
	getSeasonWiseCalender,
} from '../util';
import {
	useGetCompanyByIDQuery,
	useGetCompanyCropsNVarietiesQuery,
	useGetCompanyDistrictsQuery,
	useGetCompanyStatesQuery,
	useGetFarmersListQuery,
} from '../redux/api/slices/companyApiSlice';

import DateRangePopover from '../components/DateRangePopover';
import ErrorOccured from '../components/ErrorOccured';
import Loading from '../components/Loading';
import Select from '../components/Select';
import { updateFilters } from '../redux/api/slices/userApiSlice';

import { useAuth, useGetFilters } from '../hooks';

const Filters = ({ hideDateFilter = true, hideFiltersForMap = true }) => {
	const defaultSelectedFarmer = useMemo(
		() => [
			{
				id: DEFAULT_GUID,
				label: '--All Farmers--',
			},
		],
		[]
	);
	const defaultSelectedDistrict = useMemo(
		() => [
			{
				id: DEFAULT_GUID,
				label: '--All Districts--',
			},
		],
		[]
	);
	const filters = useGetFilters();
	const [hideDateFilters, setHideDateFilters] = useState(true);
	const [selectSeason, setSelectedSeason] = useState({
		id: -1,
		label: 'Select Season',
	});
	const [cropsOptions, setCropsOptions] = useState([]);
	const [selectedCrop, setSelectedCrop] = useState({});
	const [cropVarietiesOptions, setCropVarietiesOptions] = useState([]);
	const [selectedCropVariety, setSelectedCropVariety] = useState([]);
	const [statesOptions, setStatesOptions] = useState([]);
	const [dateType, setDateType] = useState({
		id: 0,
		label: 'Select By Season',
	});
	const [companyCropIDsOptions, setCompanyCropIDsOptions] = useState([]);
	const [selectedFarmer, setSelectedFarmer] = useState(defaultSelectedFarmer);
	const [selectedFarmerOptions, setSelectedFarmerOptions] = useState(
		defaultSelectedFarmer
	);
	const [selectedState, setSelectedState] = useState([]);
	const [selectedCompanyCropID, setSelectedCompanyCropID] = useState([]);
	const [districtsOptions, setDistrictsOptions] = useState([]);
	const [selectedDistrict, setSelectedDistrict] = useState([]);
	const [ranges, setRanges] = useState();
	const { cId } = useParams();
	const dispatch = useDispatch();
	const auth = useAuth();
	const stateLabels = ['--Select State--', '--All States--'];
	const dateTypeOptions = [
		{ id: 0, label: 'Select By Season' },
		// { id: 1, label: 'Select By Custom Date' },
	];
	const [seasonWiseCalander, setSeasonWiseCalender] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();

	const { pathname } = location;

	const onDateTypeChange = (selectedDateType) => {
		if (selectedDateType.id === 0) {
			setHideDateFilters(true);
			setSelectedSeason(
				seasonWiseCalander[0] || {
					id: -1,
					label: 'Select Season',
				}
			);
		} else {
			const newDate = {
				startDate: new Date(ranges[0]?.startDate),
				endDate: new Date(ranges[0]?.endDate),
				key: 'selection',
			};
			setRanges([newDate]);
			setHideDateFilters(false);
			setSelectedSeason({});
		}
		setDateType(selectedDateType);
	};

	const onsetSelectedCrop = (cropSelected) => {
		// const preSelectedCrops = selectedCrop;
		// const newArray = cropSelected.filter(
		// 	(item) => !preSelectedCrops.includes(item)
		// );
		// console.log
		setSelectedCrop(cropSelected);
	};

	const onSeasonCalenderChange = (selectedSeason) => {
		setSelectedSeason(selectedSeason);
	};

	const {
		data: cropsNVarieties,
		isLoading: cropNVarietiesLoading,
		isError: cropNVarietiesIsError,
		error: cropNVarietiesError,
	} = useGetCompanyCropsNVarietiesQuery(
		(cId &&
			selectedCompanyCropID &&
			selectedCompanyCropID?.id &&
			selectedCompanyCropID?.id) ||
			skipToken
	);
	const {
		data: allStates,
		isLoading: allStatesLoading,
		isError: allStatesIsError,
		error: allStatesError,
	} = useGetCompanyStatesQuery(
		(cId && selectedCrop && selectedCrop?.id) || skipToken
	);

	useEffect(() => {
		if (selectedState?.id === DEFAULT_GUID) {
			setSelectedDistrict(defaultSelectedDistrict);
			// setSelectedFarmer({});
			setSelectedFarmer(defaultSelectedFarmer);
		}
	}, [selectedState]);

	const {
		data: allDistricts,
		isLoading: allDistrictsLoading,
		isError: allDistrictsIsError,
		error: allDistrictsError,
	} = useGetCompanyDistrictsQuery(
		cId && selectedCrop && ranges?.length
			? {
					companyId: cId,
					companyCropId: filters?.crops,
					cropVarietyId: filters?.cropVarieties,

					states: filters?.states,
					//	districts: filters?.districts,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
			  }
			: skipToken
	);
	const {
		data: userCompaniesList,
		isLoading: userCompaniesListLoading,
		isError: userCompaniesListIsError,
		error: userCompaniesListError,
	} = useGetCompanyByIDQuery(
		auth?.userId
			? {
					userId: auth.userId,
			  }
			: skipToken
	);

	const {
		data: farmersList,
		isLoading: farmersListIsLoading,
		// isFetching: farmersListIsFetching,
		isError: farmersListisError,
		error: farmersListError,
	} = useGetFarmersListQuery(
		cId && selectedCrop && ranges?.length
			? {
					companyId: cId,
					companyCropId: filters?.crops,
					cropVarietyId: filters?.cropVarieties,
					states: filters?.states,
					districts: filters?.districts,
					fromDate: getFormattedDate({
						date: new Date(ranges[0]?.startDate),
						reqFormat: API_DATE_FORMAT,
					}),
					toDate: getFormattedDate({
						date: new Date(ranges[0]?.endDate),
						reqFormat: API_DATE_FORMAT,
					}),
			  }
			: skipToken
	);

	useEffect(() => {
		const lastMonth = new Date();
		lastMonth.setMonth(lastMonth.getMonth() - 4);
		const dateRange = {
			startDate: lastMonth,
			endDate: getEndOfMonth({ date: subDays(new Date(), 15) }),
			key: 'selection',
		};
		setRanges([dateRange]);
	}, []);

	useEffect(() => {
		if (
			dateType.id !== 1 &&
			selectSeason &&
			Object.keys(selectSeason).length &&
			selectSeason?.id !== -1
		) {
			const dateRange = {
				startDate: new Date(selectSeason.value.fromDate),
				endDate: new Date(selectSeason.value.toDate),
				key: 'selection',
			};
			setRanges([dateRange]);
		}
	}, [selectSeason, dateType]);

	useEffect(() => {
		if (cropsNVarieties?.length) {
			const tmpCropsOptions = [];
			const tmpCropVarities = [];
			cropsNVarieties.forEach((crop) => {
				tmpCropsOptions.push({
					id: crop.companyCropId,
					label: crop.cropName,
				});
				if (crop?.varieties?.length) {
					crop.varieties.forEach((variety) => {
						tmpCropVarities.push({
							id: variety.cropVarietyId,
							label: variety.cropVarietyName,
							cropId: crop.cropId,
						});
					});
				}
			});
			setCropsOptions(tmpCropsOptions);
			setSelectedCrop(tmpCropsOptions?.[0]);
			// setSelectedCrop(tmpCropsOptions);

			setSelectedCropVariety([
				{
					id: DEFAULT_GUID,
					label: '--All Varieties--',
					varietyId: DEFAULT_GUID,
				},
			]);
			setCropVarietiesOptions([
				{
					id: DEFAULT_GUID,
					label: '--All Varieties--',
					varietyId: DEFAULT_GUID,
				},
				...tmpCropVarities,
			]);
		}
	}, [cropsNVarieties]);

	useEffect(() => {
		if (allStates?.length) {
			const tmpStatesOptions = [];
			allStates.forEach((state) => {
				tmpStatesOptions.push({
					id: state.id,
					label: state.name,
				});
			});

			setSelectedState([
				{
					id: DEFAULT_GUID,
					label: '--All States--',
					stateId: DEFAULT_GUID,
				},
			]);
			setStatesOptions([
				{
					id: DEFAULT_GUID,
					label: '--All States--',
					stateId: DEFAULT_GUID,
				},
				...tmpStatesOptions.sort((a, b) =>
					sortByAlphabetOrder(a.label, b.label, 'asc')
				),
			]);
		}
	}, [allStates, selectedCompanyCropID]);

	useEffect(() => {
		const tmpDistrictsOptions = [];
		if (allDistricts?.length) {
			allDistricts.forEach((district) => {
				tmpDistrictsOptions.push({
					id: district.id,
					label: district.name,
				});
			});
			setDistrictsOptions([
				...defaultSelectedDistrict,
				...tmpDistrictsOptions.sort((a, b) =>
					sortByAlphabetOrder(a.label, b.label, 'asc')
				),
			]);
		}
		setSelectedDistrict(defaultSelectedDistrict);
	}, [allDistricts, selectedCompanyCropID]);

	useEffect(() => {
		if (userCompaniesList?.length) {
			const temp = [];
			userCompaniesList.forEach((company) => {
				temp.push({
					...company,
					label: company.companyName,
				});
			});

			setSelectedCompanyCropID(temp[0]);
			setCompanyCropIDsOptions([...temp]);
		}
	}, [userCompaniesList]);

	useEffect(() => {
		if (Object.keys(selectedCrop || {}).length) {
			// const crop = selectedCrop?.map((v) => v.id);
			dispatch(
				updateFilters({
					crops: [selectedCrop.id],
				})
			);

			const seasonCal = [];
			const seasonalCalender = getSeasonWiseCalender();

			// ?.forEach((crp) => {
			//	const cropName = crp.label;
			const arr = seasonalCalender.filter(
				(cal) => cal.crop === selectedCrop.label
			);
			seasonCal.push(...arr);
			//	});

			setSeasonWiseCalender(seasonCal);
			setSelectedSeason(seasonCal[0]);
		}
	}, [selectedCrop]);

	useEffect(() => {
		const company = isArray(selectedCompanyCropID)
			? selectedCompanyCropID?.map((v) => v.id)
			: selectedCompanyCropID?.id;
		const allCompanyIds = company[0] === DEFAULT_GUID ? [''] : company;

		// if (Object.keys(selectedCompanyCropID || {}).length) {
		dispatch(
			updateFilters({
				companyCropID: allCompanyIds,
			})
		);

		if (
			pathname &&
			pathname.includes(cId) &&
			!pathname.includes(selectedCompanyCropID?.id)
		) {
			const newUrl = pathname.replace(cId, selectedCompanyCropID?.id);
			navigate(newUrl);
		}
		// }
	}, [selectedCompanyCropID]);

	useEffect(() => {
		const cropVarient = isArray(selectedCropVariety)
			? selectedCropVariety?.map((v) => v.id)
			: [''];
		const allcropVaries = cropVarient[0] === DEFAULT_GUID ? [''] : cropVarient;
		dispatch(
			updateFilters({
				cropVarieties: allcropVaries,
			})
		);
	}, [selectedCropVariety]);

	useEffect(() => {
		dispatch(
			updateFilters({
				ranges,
			})
		);
	}, [ranges]);

	useEffect(() => {
		const selectedStateId = isArray(selectedState)
			? selectedState?.map((v) => v.id)
			: [''];
		const allSelectedStates =
			selectedStateId[0] === DEFAULT_GUID ? [''] : selectedStateId;
		dispatch(
			updateFilters({
				states: allSelectedStates,
			})
		);
		// const selectedStateId = stateLabels.includes(selectedState?.id)
		// 	? ['']
		// 	: selectedState?.id?.split();

		// dispatch(
		// 	updateFilters({
		// 		states: selectedStateId?.id,
		// 	})
		// );
	}, [selectedState]);

	useEffect(() => {
		const selectedFarmers = isArray(selectedFarmer)
			? selectedFarmer.map((v) => v.value)
			: [''];
		const allFarmers = selectedFarmers[0] === '' ? [''] : selectedFarmers;
		dispatch(
			updateFilters({
				farmer: allFarmers,
			})
		);

		// if (selectedFarmer[0]?.label != '--ALL Farmers--') {
		// 	dispatch(
		// 		updateFilters({
		// 			farmer: selectedFarmer,
		// 		})
		// 	);
		// } else {
		// 	if (selectedFarmer[0]?.value === '0000-0000-0000-0000') {
		// 		dispatch(
		// 			updateFilters({
		// 				farmer: { label: '', id: '' },
		// 			})
		// 		);
		// 	}
		// }
	}, [selectedFarmer]);

	useEffect(() => {
		const disticts = selectedDistrict.map((v) => v.id);
		dispatch(
			updateFilters({
				districts: disticts[0] === DEFAULT_GUID ? [''] : disticts,
			})
		);
	}, [selectedDistrict]);

	const defaultSelectedCropVariety = useMemo(() => {
		if (cropsNVarieties?.length) {
			return [
				{
					id: DEFAULT_GUID,
					label: 'All',
					cropId: cropsNVarieties[0].id,
				},
			];
		}
		return [];
	}, [cropsNVarieties]);

	const defaultSelectedState = useMemo(
		() => [
			{
				id: DEFAULT_GUID,
				label: '--All States--',
				stateId: DEFAULT_GUID,
			},
		],
		[]
	);

	const onFarmerChange = (val) => {
		let modVal = val;
		const multiple = true;

		if (multiple && val.length > 1) {
			const len = modVal.length;
			const isExists = val[len - 1];
			if (isExists.id === DEFAULT_GUID) {
				modVal = defaultSelectedFarmer;
			} else {
				modVal = val.filter((i) => i.id !== DEFAULT_GUID);
			}
		} else if (multiple && val.length === 0) {
			modVal = defaultSelectedFarmer;
		}
		setSelectedFarmer(modVal);
	};

	useEffect(() => {
		if (farmersList?.length) {
			const tmpFarmersList = [];
			farmersList.forEach((farmer) => {
				tmpFarmersList.push({
					value: farmer.farmerCode,
					label: farmer.farmerName,
				});
			});

			setSelectedFarmer([...defaultSelectedFarmer]);
			setSelectedFarmerOptions([
				...defaultSelectedFarmer,
				...tmpFarmersList.sort((a, b) =>
					sortByAlphabetOrder(a.label, b.label, 'asc')
				),
			]);
		}
	}, [farmersList]);

	if (
		cropNVarietiesLoading ||
		allStatesLoading ||
		allDistrictsLoading ||
		userCompaniesListLoading ||
		farmersListIsLoading
	) {
		return <Loading fullScreenMode />;
	}

	if (
		cropNVarietiesIsError ||
		allStatesIsError ||
		allDistrictsIsError ||
		userCompaniesListIsError ||
		farmersListisError
	) {
		const errorMessageHandler = farmersListisError
			? farmersListError
			: cropNVarietiesIsError
			? cropNVarietiesError
			: allStatesIsError
			? allStatesError
			: allDistrictsIsError
			? allDistrictsError
			: userCompaniesListIsError && userCompaniesListError;
		return <ErrorOccured fullScreenMode msg={errorMessageHandler} />;
	}

	return (
		<div className="min-h-16 flex flex-col md:flex-row flex-wrap md:justify-between items-start mb-5 bg-white p-3 drop-shadow-sm relative z-[9]">
			<div className="flex flex-col md:flex-row">
				{!hideFiltersForMap ? (
					<>
						<Select
							className="md:w-40 mt-2 md:mt-0 z-20 md:mr-4 md:mb-2.5"
							optionsClassName="w-40"
							btnClassName="bg-white"
							selectOptions={cropsOptions}
							setSelectedValue={onsetSelectedCrop}
							selectedValue={selectedCrop}
							isLoading={false}
							// disabled={cropsOptions?.length < 2}
							// multiple
						/>
						<Select
							className="md:w-40 mt-2 md:mt-0 z-20 md:mr-4 md:mb-2.5"
							optionsClassName="w-40"
							btnClassName="bg-white"
							selectOptions={cropVarietiesOptions}
							setSelectedValue={setSelectedCropVariety}
							selectedValue={selectedCropVariety}
							isLoading={false}
							disabled={cropVarietiesOptions?.length < 2}
							defaultSelectedValue={defaultSelectedCropVariety}
							multiple
						/>
						<Select
							className="md:w-40 mt-2 md:mt-0 z-20 md:mr-4 md:mb-2.5"
							optionsClassName="w-40"
							btnClassName="bg-white"
							selectOptions={statesOptions}
							setSelectedValue={setSelectedState}
							defaultSelectedValue={defaultSelectedState}
							selectedValue={selectedState}
							isLoading={false}
							disabled={statesOptions?.length < 2}
							multiple
						/>
						<Select
							className="md:w-40 mt-2 md:mt-0 z-20 md:mr-4 md:mb-2.5"
							optionsClassName="w-40"
							btnClassName={`${
								districtsOptions.length < 2 ||
								stateLabels.includes(selectedState[0]?.label)
									? 'bg-disableColor'
									: 'bg-white'
							}`}
							selectOptions={districtsOptions}
							setSelectedValue={setSelectedDistrict}
							selectedValue={selectedDistrict}
							isLoading={false}
							disabled={
								districtsOptions.length < 2 ||
								stateLabels.includes(selectedState[0]?.label)
							}
							defaultSelectedValue={defaultSelectedDistrict}
							multiple
						/>
					</>
				) : null}
				{companyCropIDsOptions?.length > 1 ? (
					<Select
						className="md:w-40 mt-2 md:mt-0 z-20 md:mr-4 md:mb-2.5"
						optionsClassName="w-40"
						btnClassName={`${
							companyCropIDsOptions.length < 2 ? 'bg-disableColor' : 'bg-white'
						}`}
						selectOptions={companyCropIDsOptions}
						setSelectedValue={setSelectedCompanyCropID}
						selectedValue={selectedCompanyCropID}
						isLoading={false}
						disabled={companyCropIDsOptions?.length < 2}
					/>
				) : null}
				{selectedFarmerOptions?.length > 0 ? (
					<SelectAsync
						options={selectedFarmerOptions}
						className="md:w-60 mt-2 md:mt-0  "
						onChange={onFarmerChange}
						value={selectedFarmer}
						setSelectedValue={setSelectedFarmer}
						isLoading={false}
						disabled={
							districtsOptions.length < 2 ||
							stateLabels.includes(selectedFarmer.label)
						}
						isMulti
					/>
				) : null}
			</div>
			{!hideFiltersForMap && !hideDateFilter && (
				<Select
					className="md:w-40 mt-2 md:mt-0"
					optionsClassName="w-45"
					btnClassName="bg-white"
					selectOptions={dateTypeOptions}
					setSelectedValue={onDateTypeChange}
					selectedValue={dateType}
					isLoading={false}
				/>
			)}
			{!hideFiltersForMap && !hideDateFilters && (
				<DateRangePopover
					className="mt-2 md:mt-0"
					handleSubmit={(tRanges) => {
						setRanges(tRanges);
					}}
					ranges={ranges}
					btnClassName="bg-white"
				/>
			)}
			{!hideFiltersForMap && hideDateFilters && (
				<Select
					className="md:w-40 mt-2 md:mt-0 md:mr-4"
					optionsClassName="w-40"
					btnClassName="bg-white"
					selectOptions={seasonWiseCalander}
					setSelectedValue={onSeasonCalenderChange}
					selectedValue={selectSeason}
					isLoading={false}
				/>
			)}
		</div>
		// </div>
	);
};

export default Filters;
