import React from 'react';

const UserProfileIcon = (props) => {
	const { height = 24, width = 24, fill = '#e0e1e8', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 ${height} ${width}`}
			className={className}
			width={width}
			height={height}
			fill={fill}
			className={className}
		>
			{/* <defs>
				<clipPath id="clip-path">
					<path
						d="M5.6,6.4A3.2,3.2,0,1,0,2.4,3.2,3.2,3.2,0,0,0,5.6,6.4Zm2.238.8H7.417a4.349,4.349,0,0,1-3.642,0H3.358A3.358,3.358,0,0,0,0,10.552v1.039a1.2,1.2,0,0,0,1.2,1.2H9.993a1.2,1.2,0,0,0,1.2-1.2V10.552A3.358,3.358,0,0,0,7.834,7.195Z"
						fill="#e0e1e8"
					/>
				</clipPath>
				<clipPath>
					<rect width={20} height={20} />
				</clipPath>
			</defs>
			<g>
				<rect width={20} height={20} fill="none" />
				<g transform="translate(49.494 -40.981)">
					<g
						transform="translate(-47.494 42.981)"
						fill="none"
						stroke={stroke}
						strokeWidth={1}
					>
						<circle cx={8} cy={8} r={8} stroke="none" />
						<circle cx={8} cy={8} r={7.5} fill="none" />
					</g>
					<g transform="translate(-44.851 45.578)" clipPath="url(#clip-path)">
						<path
							d="M7.745,0A7.745,7.745,0,1,1,0,7.745,7.745,7.745,0,0,1,7.745,0Z"
							transform="translate(-2.149 -2.7)"
							fill={fill}
						/>
					</g>
				</g>
			</g> */}
			<path d="M20.8,22c-0.3,0-0.6,0-0.9,0c-0.2,0-0.2,0-0.2-0.2c-0.1-3-2.2-5.4-5.1-5.9c-0.6-0.1-1.1-0.1-1.6,0c-0.8,0.1-1.5,0.1-2.3-0.1 c-0.4-0.1-0.9,0-1.3,0.1c-2.6,0.6-4.4,2.6-4.8,5.2c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.2-0.2,0.2c-0.6,0-1.3,0-1.9,0 c-0.2,0-0.2-0.1-0.2-0.2c0-1.2,0.3-2.3,0.8-3.3c0.9-1.9,2.3-3.3,4.3-4.2c0.2-0.1,0.1-0.1,0-0.2c-1.1-1.1-1.8-2.3-2-3.8 C4.7,7.9,5.3,6,6.8,4.3C8,3,9.4,2.3,11.1,2.1c2.1-0.3,4,0.3,5.6,1.7C18,5,18.8,6.5,18.9,8.4c0.2,1.9-0.4,3.6-1.6,5.1 c-0.1,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.1,0.2,0,0.2c1,0.4,1.9,1.1,2.7,1.9c1.4,1.4,2.1,3.1,2.3,5.1c0,0.2,0,0.4,0,0.6 c0,0.1-0.1,0.2-0.2,0.2C21.4,22,21.1,22,20.8,22C20.8,22,20.8,22,20.8,22z M7.3,9c0,2.5,2,4.7,4.7,4.7c2.7,0,4.7-2.2,4.7-4.7 c0-2.7-2.2-4.7-4.7-4.7C9.4,4.3,7.3,6.4,7.3,9z" />
		</svg>
	);
};

export default UserProfileIcon;
