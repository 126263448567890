import React from 'react';

const ReportsIcon = (props) => {
	const { height = 24, width = 22, fill = '#e0e1e8', className = '' } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${height} ${width}`}
			className={className}
		>
			<defs>
				<clipPath>
					<rect width={20} height={20} />
				</clipPath>
			</defs>
			<g>
				<rect width={20} height={20} fill="none" />
				<path
					d="M15.523,8.471H8.544l4.648,4.648a.472.472,0,0,0,.653.02A7.044,7.044,0,0,0,16,9,.471.471,0,0,0,15.523,8.471Zm-.466-1.906A7.076,7.076,0,0,0,8.494,0,.473.473,0,0,0,8,.478V7.059h6.581a.473.473,0,0,0,.476-.494ZM6.588,8.471V1.492a.471.471,0,0,0-.525-.472A7.054,7.054,0,0,0,0,8.246a7.146,7.146,0,0,0,7.144,6.812,7,7,0,0,0,3.978-1.295.467.467,0,0,0,.046-.708Z"
					transform="translate(2 2)"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default ReportsIcon;
